import React, { useCallback, useEffect, useState } from 'react'
import AxiosHelper from '../../../helper/AxiosHelper'
import ReactTable from '../../shared/ReactTable'
import { useHistory } from 'react-router-dom'
import { createColumnHelper } from '@tanstack/react-table'
import { SortingImg } from '../../shared/ReactTable'
import { Checkbox, MenuItem, Select } from '@mui/material'
import { radioStyles } from '../../abha/components/DonorDetail'
import PaginationLink from '../../pagination/PaginationLink'
import moment from 'moment'
import { Add } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { components } from 'react-select'
import SearchBox from '../../shared/SearchBox'
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'
import { useAppContext } from '../../../AppContext'

const searchSelectBox = {
  borderRadius: '8px 0px 0px 8px',
  background: 'linear-gradient(90deg, #204E89 0.24%, #12B9AA 99.78%)',
  height: 'inherit',
  marginRight: '8px',
  color: '#ffffff',
  width: '238px'
}

const AllotementComponent = ({ getCompData }) => {
  const [searchSelect, setSearchSelect] = useState('')
  const [searchbox, setsearchbox] = useState('')
  const [allotData, setAllotedData] = useState([])
  const [pageData, setPageData] = useState([])
  const history = useHistory()
  let data = {}
  const [Pagenum, setPagenum] = useState(1)
  const { componentLables } = useAppContext()

  //  console.log('pageData', pageData)
  // console.log('searchbox', searchbox)

  const filterFunction = () => {
    if (searchSelect) {
      data = {
        component: searchSelect
      }
    }

    if (searchbox) {
      data = {
        bag_number: searchbox
      }
    }

    if (searchbox && searchSelect) {
      data = {
        component: searchSelect,
        bag_number: searchbox
      }
    }
    return data
  }

  const getAllotmentData = async (data, Pagenum) => {
    console.log(Pagenum)
    let bodyData = {
      ...data
    }
    if (Pagenum) {
      bodyData = { ...bodyData, page: Pagenum }
    }
    try {
      const response = await AxiosHelper.post(`qc/find-component`, bodyData)
      const data = response?.data?.data?.response
      setAllotedData(data)
      setPageData(response.data.data.pagination)
    } catch (error) {
      console.error(error)
    }
  }

  // console.log('allotData', allotData)

  // const handleCheckboxChange = id => {
  //   setblood_log_id(id)

  // }

  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper()

    const columns = [
      tableColumnHelper.accessor('orderByBagNumber', {
        cell: ({ cell }) => <div>{cell?.row.original?.BloodBagIssueEntity?.bag_number || '-'}</div>,
        header: info => <div onClick={info.header.column.getToggleSortingHandler()}>Blood Bag Number</div>
      }),
      tableColumnHelper.accessor('orderByCompName', {
        cell: ({ cell }) => <div>{cell?.row.original?.component || '-'}</div>,
        header: info => <div>Blood Component</div>
      }),
      tableColumnHelper.accessor('orderByDonorName', {
        cell: ({ cell }) => <div>{cell?.row.original?.type || '-'}</div>,
        header: info => <div>Blood Group</div>
      }),
      tableColumnHelper.accessor('orderByAssignDate', {
        cell: ({ cell }) => (
          <div>
            {cell?.row.original?.expiry_date ? moment(cell?.row.original?.expiry_date).format('DD-MMM-YYYY') : '-'}
          </div>
        ),
        header: info => <div>Expiry Date</div>
      }),
      tableColumnHelper.accessor('type', {
        cell: ({ cell }) => (
          <div
            className='chip-button'
            style={{ maxWidth: '60px', cursor: 'pointer', borderRadius: '20px', textAlign: 'center' }}
            onClick={() => AllotmentData(cell?.row.original?.id)}
          >
            Allot
          </div>
        ),
        header: info => <div>Allotment</div>
      })
    ]

    return columns
  }, [allotData])

  const handleCustomRequests = page => {
    console.log(page)
    setPagenum(page)
  }

  const searchQueryFunction = e => {
    setsearchbox(e.target.value)
  }

  const AllotmentData = async blood_log_id => {
    const bodyData = { blood_log_id: blood_log_id }
    try {
      const res = await AxiosHelper.post(`qc/allotment`, bodyData)
      toast.success('Allotment Successfully!')
      await getCompData(blood_log_id)
      history.push(`/serology/qc/validation-form/${blood_log_id}`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    filterFunction()
  }, [searchSelect, searchbox])
  useEffect(() => {
    getAllotmentData(data, Pagenum)
  }, [searchSelect, searchbox, Pagenum])
  return (
    <>
      <div  style={{
        maxWidth:"350px" ,alignItems:'flex-start',display:'flex',justifyContent:'flex-start'
      }}>
        
        <TextField
          value={searchbox}
          onChange={searchQueryFunction}
          placeholder='Search by Blood Bag No.'
          variant = 'standard'
          className='search-input'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Select
                  value={searchSelect}
                  onChange={e => setSearchSelect(e.target.value)}
                  displayEmpty
                  variant='standard'
                  sx={{ minWidth: 120 }} // Adjust width as needed
                >
                  <MenuItem value=''>Component</MenuItem>
                  {componentLables &&
                    componentLables.map(ele => (
                      <MenuItem key={ele.label} value={ele.value}>
                        {ele.label}
                      </MenuItem>
                    ))}
                </Select>
              </InputAdornment>
            ),endAdornment: (
              <InputAdornment position="end">
                  <img
                      src='/assets/images/search-icon.svg'
                      alt='icon'
                      style={{ cursor: 'pointer',  height: '24px' }}
                  />
              </InputAdornment>
          )
          }}
        />
      </div>
      {/* <div className='donor-inventory-search mt-2'>
        <div className='search-and-filter-container'>
          <div className=' align-items-center'>
            <div className='search-container add-dnr-search paddingnone' style={{ paddingLeft: '0 !important' }}>
              

            </div>
          </div>
        </div>
      </div> */}
      <ReactTable
        data={allotData?.length ? allotData : []}
        columns={tableColumns}
        manualSorting={true}
        heightupper={-20}
      />
      {pageData && pageData.totalPage > 1 && (
        <div className='float-right mt-1'>
          <PaginationLink pagination={pageData} getNext={page => handleCustomRequests(page)} />
        </div>
      )}
    </>
  )
}

export default AllotementComponent

import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import PaginationLink from '../pagination/PaginationLink'
import ScrollBar from 'react-perfect-scrollbar'
import NoDataFound from '../common/NoDataFound'
import {
  Box,
  Card,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import AxiosHelper from '../../helper/AxiosHelper'
import { bloodGroupApiToUser, checkTenantId, debouncing, getBagType } from '../../helper/CommonAPI'
import Loader from '../shared/Loader'
import { inputStylesSmall } from '../donors/profile/EditProfile'
import LoadingButton from '../shared/LoadingButton'
import { bloodComponents } from '../donor-inventory/AddInventory'
import { toast } from 'react-toastify'
import ModalCustom, { ModalActions } from '../shared/ModalCustom'
import { radioStyles } from '../abha/components/DonorDetail'
import dayjs from 'dayjs'
import BloogGroupPrintRH from '../shared/BloogGroupPrintRH'
import SearchBox from '../shared/SearchBox'

const columns = [
  { label: 'PRBC', name: 'prbc', volume: '250 - 350' },
  { label: 'FFP', name: 'ffp', volume: '200 - 250' },
  { label: 'RDP', name: 'rdp', volume: '200 - 300' },
  { label: 'SDP', name: 'sdp', volume: '80' },
  { label: 'Cryoprecipitate', name: 'cryoprecipitate', volume: '25' },
  { label: 'Plasma', name: 'plasma', volume: '170 - 220' },
  { label: 'Platelet Concentrate', name: 'platelet', volume: '50 - 70' },
  { label: 'Cryo Poor Plasma', name: 'cpp', volume: '20' },
  { label: 'Platelets additive solutions', name: 'pas', volume: '' },
  { label: 'Whole Blood (CPD)', name: 'wbc', volume: '450' },
  { label: 'Whole Blood (CPDA-1)', name: 'wbca', volume: '450' }
]

const initalCol = [
  { label: 'PRBC', name: 'prbc', volume: '250 - 350' },
  { label: 'FFP', name: 'ffp', volume: '200 - 250' },
  { label: 'RDP', name: 'rdp', volume: '200 - 300' },
  { label: 'SDP', name: 'sdp', volume: '80' }
]

export function getObj(value) {
  let findObj = bloodComponents?.find(item => item?.component === value)

  if (findObj) return findObj
  return null
}

const AddInventory = () => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm({
    // resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const [page, setPage] = useState(1)
  const [visibleColumns, setVisibleColumns] = useState(initalCol)
  const [visibleConfirmColumns, setVisibleConfirmColumns] = useState(initalCol)
  const [isLoading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [allItems, setAllItems] = useState([])
  const [pageData, setPageData] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  const getPendingInventoryList = inputValue => {
    setLoading(true)
    let url = `blood-stock/bulk-bags-inventory?search=${search || inputValue || ''}&page=${page || 1}`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        let res = response.data.data
        setAllItems(res?.response)
        setPageData(res?.pagination)
        res?.response.forEach((item, index) => {
          setValue(`items[${index}].blood_bag_id`, item.id || '')
          setValue(`items[${index}].bag_type`, item.bag_type || '')
          columns.forEach(items => {
            setValue(`items[${index}][${items?.name}]`, '')
          })
        })
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getPendingInventoryList()
  }, [page])

  const onSubmit = data => {
    const filledData = data?.items?.filter(
      item =>
        item.prbc ||
        item.ffp ||
        item.rdp ||
        item.sdp ||
        item?.cryoprecipitate ||
        item?.plasma ||
        item?.platelet ||
        item?.cpp ||
        item?.pas ||
        item?.wbc ||
        item?.wbca
    )
    // Submit the filledData to your API

    if (filledData?.length === 0) {
      toast.error('Please fill some details to submit')

      return
    }

    let array = []

    for (let obj of filledData) {
      let newArray = {
        blood_bag_id: obj?.blood_bag_id,
        blood: []
      }
      if (obj?.prbc) {
        let item = getObj('Packed Red Blood Cells')
        item.volume = obj?.prbc
        newArray.blood.push(item)
      }
      if (obj?.ffp) {
        let item = getObj('Fresh Frozen Plasma')
        item.volume = obj?.ffp
        newArray.blood.push(item)
      }
      if (obj?.rdp) {
        let item = getObj('Random Donor Platelets')
        item.volume = obj?.rdp
        newArray.blood.push(item)
      }
      if (obj?.sdp) {
        let item = getObj('Single Donor Platelet')
        item.volume = obj?.sdp
        newArray.blood.push(item)
      }
      if (obj?.cryoprecipitate) {
        let item = getObj('Cryoprecipitate')
        item.volume = obj?.cryoprecipitate
        newArray.blood.push(item)
      }
      if (obj?.plasma) {
        let item = getObj('Plasma')
        item.volume = obj?.plasma
        newArray.blood.push(item)
      }
      if (obj?.platelet) {
        let item = getObj('Platelet Concentrate')
        item.volume = obj?.platelet
        newArray.blood.push(item)
      }
      if (obj?.cpp) {
        let item = getObj('Cryo Poor Plasma')
        item.volume = obj?.cpp
        newArray.blood.push(item)
      }
      if (obj?.pas) {
        let item = getObj('Platelets additive solutions')
        item.volume = obj?.pas
        newArray.blood.push(item)
      }
      if (obj?.wbc) {
        let item = getObj('Whole Blood (CPD)')
        item.volume = obj?.wbc
        newArray.blood.push(item)
      }
      if (obj?.wbca) {
        let item = getObj('Whole Blood (CPDA-1)')
        item.volume = obj?.wbca
        newArray.blood.push(item)
      }

      array.push(newArray)
    }
    setBtnLoading(true)
    let bodyData = {
      bags: array
    }

    let url = `blood-stock/bulk-bags-inventory`
    AxiosHelper.post(url, bodyData)
      .then(response => {
        let res = response.data.data
        setBtnLoading(false)
        getPendingInventoryList()
      })
      .catch(error => {
        setBtnLoading(false)
        console.log(error)
        toast.error(error || 'Something went wrong on our side')
      })
  }

  const updateAll = useCallback(
    debouncing(searchValue => {
      getPendingInventoryList(searchValue)
    }, 500),
    []
  )

  function handleModalClose() {
    setOpenModal(false)
  }

  function checkFilledInputs(row, requiredCount) {
    // Get all keys except for 'blood_bag_id' and 'bag_type'
    const relevantKeys = Object.keys(row).filter(key => key !== 'blood_bag_id' && key !== 'bag_type')

    // Count the number of filled values
    const filledCount = relevantKeys.reduce((count, key) => {
      if (row[key]) {
        // Check if the value is non-empty
        return count + 1
      }
      return count
    }, 0)

    return filledCount >= requiredCount
  }

  function checkDisabled(bagtype, name, rowValues) {
    if (bagtype === 'SDP' && name !== 'sdp') return true
    if (bagtype !== 'SDP' && name === 'sdp') return true

    if (rowValues[name]) {
      return false // Don't disable already filled inputs
    }
    if (checkFilledInputs(rowValues, getBagType(bagtype))) {
      return true // Disable if the required number of inputs are filled
    }
    return false
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loader isLoading={isLoading} />
      <div className='search-and-filter-container justify-between align-items-center'>
      <div className='searchWidth custom-height'>
          <SearchBox
            className='searchWidth custom-height'
            type='text'
            placeholder='Search...'
            value={search}
            onChange={e => {
              setSearch(e.target.value)
              updateAll(e.target.value)
            }}
          />
          {/* <img src='/assets/images/search-icon.svg' alt='search-icon' /> */}
          {/* <img src='/assets/images/search-icon.svg' alt='search-icon' /> */}
        </div>
        <div className='d-flex align-items-center'>
          {/* <Typography sx={{ fontSize: '12px', color: '#000000' }}>View data in row</Typography> */}
          {/* <IconButton>
            <img src='/assets/images/row.svg' alt='search-icon' />
          </IconButton> */}
          <IconButton onClick={() => setOpenModal(true)} sx={{ background: '#E8EDF3', borderRadius: '8px', mx: 1 }}>
            <img src='/assets/images/col.svg' alt='search-icon' />
          </IconButton>
          <LoadingButton isLoading={btnLoading} title='Save' type='submit' />
        </div>
      </div>
      <Card sx={{ mt: 2 }}>
        <div className='table-responsive mt-2'>
          <ScrollBar>
            <TableContainer className='table table-hover table-element-center'>
              <Table aria-label='collapsible table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Blood Bag Info</TableCell>
                    <TableCell sx={{ maxWidth: '90px' }}>Segment No.</TableCell>
                    <TableCell sx={{ maxWidth: '90px' }}>Blood Group</TableCell>
                    <TableCell>Collection Date</TableCell>
                    {visibleConfirmColumns?.map((item, i) => (
                      <TableCell key={i}>
                        {item?.label}
                        <br />
                        <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                          {item?.volume ? `(${item?.volume} ml)` : ''}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allItems &&
                    allItems.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          <div className='primary-request-text'>
                            {row?.bag_type || '-'}
                            <br />
                          </div>
                          {row?.bag_number || '-'}
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          {row?.tube_number || '-'}
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          {row?.temp_blood_group ? (
                            <BloogGroupPrintRH bloodGroup={row?.temp_blood_group} />
                          ) : (
                            // bloodGroupApiToUser[row?.temp_blood_group]
                            '-'
                          )}
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          {row?.assigned_date ? dayjs(row?.assigned_date).format("DD-MM-YYYY") : '-'}
                        </TableCell>
                        {visibleConfirmColumns?.map((item, index) => (
                          <TableCell key={index} sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                            <Controller
                              name={`items[${i}][${item?.name}]`}
                              control={control}
                              render={({ field }) => {
                                const rowValues = watch(`items[${i}]`)

                                return (
                                  <TextField
                                    disabled={checkDisabled(row.bag_type, item.name, rowValues)}
                                    type='number'
                                    onKeyDown={e =>
                                      ['ArrowUp', 'ArrowDown', 'e', 'E'].includes(e.key) && e.preventDefault()
                                    }
                                    placeholder='-'
                                    size='small'
                                    sx={{ ...inputStylesSmall, minWidth: 90 }}
                                    {...field}
                                    error={!!errors?.items?.[i]?.prbc}
                                  />
                                )
                              }}
                            />
                          </TableCell>
                        ))}

                        {/* <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          <Controller
                            name={`items[${i}].ffp1`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                sx={{ ...inputStylesSmall, minWidth: 90 }}
                                {...field}
                                error={!!errors?.items?.[i]?.ffp1}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          <Controller
                            name={`items[${i}].ffp2`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                sx={{ ...inputStylesSmall, minWidth: 90 }}
                                {...field}
                                error={!!errors?.items?.[i]?.ffp2}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          <Controller
                            name={`items[${i}].ffp3`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                sx={{ ...inputStylesSmall, minWidth: 90 }}
                                {...field}
                                error={!!errors?.items?.[i]?.ffp3}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          <Controller
                            name={`items[${i}].ffp3`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                sx={{ ...inputStylesSmall, minWidth: 90 }}
                                {...field}
                                error={!!errors?.items?.[i]?.ffp3}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          <Controller
                            name={`items[${i}].ffp3`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                sx={{ ...inputStylesSmall, minWidth: 90 }}
                                {...field}
                                error={!!errors?.items?.[i]?.ffp3}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          <Controller
                            name={`items[${i}].ffp3`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                sx={{ ...inputStylesSmall, minWidth: 90 }}
                                {...field}
                                error={!!errors?.items?.[i]?.ffp3}
                              />
                            )}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ScrollBar>
        </div>

        {allItems?.length === 0 && (
          <div className='dnr-no-data-found'>
            <NoDataFound title='No Record found' />
          </div>
        )}
        <Divider />

        <ModalCustom
          title='Select columns that you want to add in table.'
          open={openModal}
          size='sm'
          dialogStyle={{
            px: 2,
            pt: 0,
            pb: 0
          }}
          closeDialog={handleModalClose}
          actionComponent={
            <ModalActions>
              <div className='d-flex justify-content-end align-items-end p-3 '>
                <button type='button' className='btn backBtn mr-2 ' onClick={handleModalClose}>
                  Cancel
                </button>
                <LoadingButton
                  onClick={() => {
                    setVisibleConfirmColumns(visibleColumns)
                    handleModalClose()
                  }}
                  title='Confirm'
                />
              </div>
            </ModalActions>
          }
        >
          <List>
          <ListItem disablePadding>
                    <Checkbox
                      sx={radioStyles}
                      size='small'
                      onChange={e => {
                        if (e.target.checked) {
                          setVisibleColumns(columns)
                        } else {
                          setVisibleColumns([])
                        }
                      }}
                      checked={visibleColumns?.length === columns?.length}
                    />

                    Select All
                  </ListItem>
            {columns?.length > 0
              ? columns.map((user, i) => (
                  <ListItem disablePadding key={i}>
                    <Checkbox
                      sx={radioStyles}
                      size='small'
                      onChange={e => {
                        if (e.target.checked) {
                          setVisibleColumns(prev => [...prev, user])
                        } else {
                          setVisibleColumns(prev => prev.filter(item => item?.name !== user?.name))
                        }
                      }}
                      checked={visibleColumns?.some(obj => obj?.name === user?.name)}
                    />

                    {user?.label}
                  </ListItem>
                ))
              : null}
          </List>
        </ModalCustom>
        <div className='d-flex justify-content-end mt-2'>
          <PaginationLink pagination={pageData} getNext={page => setPage(page)} />
        </div>
      </Card>
    </form>
  )
}

export default AddInventory

import { useState } from 'react'
import AxiosHelper from '../../../helper/AxiosHelper'
import BreadCrumb from '../../common/BreadCrumb'
import ScrollCard from '../../shared/ScrollCard'
import ProgressBloodComponent from '../BloodComponent/ProgressBloodComponent'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useAppContext } from '../../../AppContext'
import AddBloodComponentForm from '../BloodComponent/AddBloodComponentForm'
import BloodComponentValidated from '../BloodComponent/BloodComponentValidated'
import BloodComponentQCReport from '../BloodComponent/BloodComponentQCReport'
import AllotementComponent from '../BloodComponent/AllotementComponent'

export const ValidationBloodComponent = () => {
  const [compData, setCompData] = useState()
  const { status, setStatus } = useAppContext()
  const [showEditForm, setShowEditForm] = useState(false)
  const [showValidated, setShowValidated] = useState(false)
  const [qcStatus, setQcStatus] = useState(false)
  const [valStatus, setValStatus] = useState(false)
  const [reportStatus, setReportStatus] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [editForm, setEditForm] = useState({})
  const [statusStap, setStatusStap] = useState(1)
  const { id } = useParams()
console.log(id);
  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    },
    {
      path: '/serology/qc',
      label: 'QC'
    },
    {
      path: '',
      label: 'Blood Component validation'
    }
  ]

  const getCompData = async (bag_id = id) => {
    try {
      const response = await AxiosHelper.get(`qc/${bag_id}`)
      const data = response?.data?.data
      setCompData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const StatusFunction = (compData, id) => {
    let changeStatus
    if (!id) {
      changeStatus = 1
    } else {
      if (!compData?.BloodCompQualityEntity?.verified_by) {
        changeStatus = 2
        setQcStatus(true)
      }
      if (compData?.BloodCompQualityEntity?.verified_by) {
        changeStatus = 3
        setValStatus(true)
      }
      if (compData?.BloodCompQualityEntity?.is_validate) {
        changeStatus = 4
        setReportStatus(true)
      }
    }
    setStatusStap(changeStatus)
  }
  console.log('changeStatus', statusStap)

  const EditQCForm = () => {
    setStatusStap(2)
  }
  const QCValidation = () => {
    setStatusStap(3)
  }

  const QcReport = () => {
    setStatusStap(4)
  }

  useEffect(() => {
    if (id) {
      getCompData()
    }
  }, [])

  useEffect(() => {
    StatusFunction(compData, id)
  }, [compData])

  return (
    <>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />
      <ScrollCard normal>
        <div className='upper_div_filter'>
          <div className='hide_scroll'>
            <ProgressBloodComponent
              compData={compData}
              EditQCForm={EditQCForm}
              QCValidation={QCValidation}
              QcReport={QcReport}
              setStatusStap={setStatusStap}
              statusStap={statusStap}
              qcStatus={qcStatus}
              valStatus={valStatus}
              reportStatus={reportStatus}
            />
          </div>
          {statusStap === 1 ? <AllotementComponent  getCompData={getCompData}/> : null}
          {statusStap === 2 ? <AddBloodComponentForm compData={compData} id={id} getCompData={getCompData} /> : null}
          {statusStap === 3 ? <BloodComponentValidated getCompData={getCompData} compData={compData} /> : null}
          {statusStap === 4 ? <BloodComponentQCReport compData={compData} /> : null}
        </div>
      </ScrollCard>
    </>
  )
}

import React, { forwardRef } from 'react'
import { useReactToPrint } from 'react-to-print'

const PrintWrapperWithoutButton = forwardRef(({ fileName, children,styles }, ref) => {
  const componentRef = React.useRef(null)

  const onBeforeGetContentResolve = React.useRef(null)

  const handleAfterPrint = React.useCallback(() => {
    console.log('`onAfterPrint` called') // tslint:disable-line no-console
  }, [])

  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called') // tslint:disable-line no-console
  }, [])

  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve
      resolve()
    })
  }, [])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: fileName || 'document',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  })

  return (
    <>
      <button
        ref={ref}
        type='button'
        className='d-none'
        onClick={handlePrint}
      >
        Print
      </button>
      <div ref={componentRef} style={styles} >{children}</div>
    </>
  )
})

export default PrintWrapperWithoutButton

import React, { useState }  from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { activeProgress } from '../../blood-camp-management/viewcamp/ViewCamp'
import { normalProgress } from '../../blood-camp-management/viewcamp/ViewCamp'


export const progressdone = () => {
    return <img src={'/assets/images/status/Done.svg'} alt='' />
  }
  
  export const progresspending = () => {
    return <img src={'/assets/images/status/Pending.svg'} alt='' />
  }

const ProgressBloodComponent = ({compData,QCValidation,EditQCForm,QcReport,statusStap}) => {
    const [bgstatus , setBgstatus] = useState('')

  return (
    <div className='my-3' style={{minWidth:'500px'}}>
            <Stepper activeStep={1} alternativeLabel>
              <Step>
                <StepLabel StepIconComponent={statusStap > 1 ?  progressdone : progresspending}
                  sx={statusStap === 1 ? activeProgress:normalProgress}>
                  <div className='progress-label'>Allotment</div>
                  <div>
                    {statusStap > 1 ? (
                      <div className='progress-status-done'>Done</div>
                    ):
                    (
                    <div className='progress-status-pending'>Pending</div>
                    )} 
                      
                  </div>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={compData?.BloodCompQualityEntity?.verified_by  ? progressdone : progresspending}
                 sx={statusStap === 2 ? activeProgress:normalProgress}
                 onClick={()=>{EditQCForm()
                  setBgstatus('qc')
                }}>
                  <div className='progress-label'>QC </div>
                  <div>
                    {compData?.BloodCompQualityEntity?.id  ? (
                      <div className='progress-status-done' >Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel   StepIconComponent={compData?.BloodCompQualityEntity?.is_validate ? progressdone : progresspending} 
                sx={statusStap === 3 ? activeProgress:normalProgress}
                onClick={()=>{QCValidation()
                  setBgstatus('validation')
                }}>
                  <div className='progress-label'>Validation</div>
                  <div>
                    {compData?.BloodCompQualityEntity?.is_validate ? (
                      <div className='progress-status-done'>Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={compData?.BloodCompQualityEntity?.is_validate ? progressdone : progresspending} 
                sx={statusStap === 4 ? activeProgress:normalProgress}
                onClick={()=>{QcReport()
                  setBgstatus('report')
                }}>
                  <div className='progress-label'>QC Report</div>
                  <div>
                    {compData?.BloodCompQualityEntity?.is_validate ? (
                      <div className='progress-status-done'>Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>
            </Stepper>
          </div>
  )
}

export default ProgressBloodComponent
import { Box, Button, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getTenatLicense, getTenatName } from '../middleware/auth'
import moment from 'moment'
import AxiosHelper from '../../helper/AxiosHelper'
import Loader from '../shared/Loader'
import { bloodComponents } from '../donor-inventory/AddInventory'
import { checkTenantId } from '../../helper/CommonAPI'
import { ComponentLabelsCompFn } from '../shared/ComponentLabelsComp'

const tdStyle = {
  borderRight: '1px solid #e9edf3',
  borderBottom: '1px solid #e9edf3',
  height: '40px',
  fontFamily: 'Inter',
  fontSize: '0.8rem',
  lineHeight: '15px',
  letterSpacing: '0px',
  textAlign: 'left',
  fonWeight: '500',
  padding: '5px'
}

const tdBottomStyle = {
  // height: "40px",
  fontFamily: 'Inter',
  fontSize: '0.8rem',
  lineHeight: '15px',
  letterSpacing: '0px',
  textAlign: 'left',
  fonWeight: '500',
  padding: '5px'
}

const tabHead = {
  backgroundColor: '#e9edf3'
}
const tabHeadth = {
  backgroundColor: '#e9edf3',
  borderRight: '1px solid #e9edf3',
  borderBottom: '1px solid #e9edf3',
  verticalAlign: 'middle',
  height: '40px',
  fontFamily: 'Inter',
  fontSize: '0.8rem',
  lineHeight: '15px',
  letterSpacing: '0px',
  textAlign: 'left',
  fonWeight: '500',
  padding: '5px'
}
const tableTd = {
  borderRight: '1px solid #e9edf3',
  borderBottom: '1px solid #e9edf3',
  height: '40px',
  fontFamily: 'Inter',
  fontSize: '0.8rem',
  lineHeight: '15px',
  letterSpacing: '0px',
  textAlign: 'left',
  fonWeight: '500',
  padding: '5px'
}

const BloodCampReport = ({ id, print = true, data, ref }) => {
  const [pageLoading, setPageLoading] = useState(false)
  const [settingData, setSettingsData] = useState({})
  // const { toPDF, targetRef } = usePDF({filename: 'camp_report.pdf'});

  let doctors =
    data?.CampStaffEntities?.length > 0
      ? data?.CampStaffEntities?.filter(item => {
          let roles = JSON.parse(item?.role)
          return roles?.includes('MO') || roles?.includes('MO IC')
        })
      : []
  let nurses =
    data?.CampStaffEntities?.length > 0
      ? data?.CampStaffEntities?.filter(item => {
          let roles = JSON.parse(item?.role)
          return roles?.includes('Nurse')
        })
      : []

  let technicians =
    data?.CampStaffEntities?.length > 0
      ? data?.CampStaffEntities?.filter(item => {
          let roles = JSON.parse(item?.role)

          return roles?.includes('Technician')
        })
      : []

  let drivers =
    data?.CampStaffEntities?.length > 0
      ? data?.CampStaffEntities?.filter(item => {
          let roles = JSON.parse(item?.role)

          return roles?.includes('Driver')
        })
      : []

  let Counsellor =
    data?.CampStaffEntities?.length > 0
      ? data?.CampStaffEntities?.filter(item => {
          let roles = JSON.parse(item?.role)

          return roles?.includes('Counsellor')
        })
      : []

  let tenant = JSON.parse(localStorage.getItem('authInfo'))?.tenantInfo

  const getSettingsData = () => {
    setPageLoading(true)
    const URL = checkTenantId(`tenants/setting`)
    AxiosHelper.get(URL)
    AxiosHelper.get(`tenants/setting`)
      .then(resp => {
        setSettingsData(resp?.data?.data)
        setPageLoading(false)
      })
      .catch(err => {
        console.log(err)
        setPageLoading(false)
      })
  }

  useEffect(() => {
    getSettingsData()
  }, [])

  let allFacility = data?.CampFacilityEntity?.facility ? JSON.parse(data?.CampFacilityEntity?.facility) : []

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div
      ref={ref}
      id={id || 'report_pdf'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        // width: "800px",
        width: '100%',
        minHeight: '842px',
        flexShrink: '0',
        background: '#FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: '400'
      }}
    >
      {print === false ? null : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            // padding: "4px 16px",
            height: '78px',
            alignItems: 'center',
            background: 'var(--Ehr, linear-gradient(180deg, #21538B 0%, #13B9AA 100%))'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              padding: '6px 4px'
            }}
          >
            <img
              alt=''
              src={settingData?.logo || '/assets/images/reports/logo.svg'}
              style={{
                width: '120px',
                maxHeight: '70px',
                objectFit: 'contain'
              }}
            />

            <div
              style={{
                minHeight: '60px',
                display: 'flex',
                fontWeight: '500',
                borderLeft: '1px solid #FFFFFF',
                marginLeft: '2rem',
                paddingLeft: '1rem'
              }}
            >
              <div
                style={{
                  color: '#FFFFFF',
                  fontSize: '1.2rem',
                  alignSelf: 'center'
                }}
              >
                {settingData?.name || ''}
              </div>
            </div>
          </div>

          <div
            style={{
              width: '59%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '12px',
                marginTop: '4px',
                color: '#ffffff',
                fontSize: '14px'
              }}
            >
              <span
                style={{
                  display: 'flex',
                  fontSize: '14px'
                }}
              >
                Call : {settingData?.contact_number || ''}
              </span>

              <span
                style={{
                  display: 'flex',
                  borderLeft: '1px solid #FFFFFF',
                  paddingLeft: '0.8rem'
                }}
              >
                Email : {settingData?.email || ''}
              </span>
            </div>
            <div
              style={{
                color: '#ffffff',
                fontSize: '14px',
                fontWeight: 500
              }}
            >
              Address :
              <span style={{ fontWeight: 400 }}>
                {' '}
                {tenant?.TenantAddressEntity?.street},{tenant?.TenantAddressEntity?.city},
                {tenant?.TenantAddressEntity?.area},{tenant?.TenantAddressEntity?.state},
                {tenant?.TenantAddressEntity?.pincode}{' '}
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          maxWidth: '100%',
          height: '37px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: '#000000',
          fontSize: '16px',
          fontWeight: '600',
          padding: '0 16px',
          background: 'var(--ehr-secondary-l-9, #E9EDF3)'
        }}
      >
        Check list for blood donation camp
      </div>
      <table style={{ borderLeft: '1px solid #e9edf3' }}>
        <tbody>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>1.</td>
            <td style={tdStyle}>Name of Blood Center Organizing VBD Camp & Address</td>
            <td style={{ ...tdStyle, width: '55%' }}>{settingData?.name}</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>2.</td>
            <td style={tdStyle}>Request/Consent of VBD Camp Organizing Agency.</td>
            <td style={tdStyle}>{data?.OrganizationEntity?.name}</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>3.</td>
            <td style={tdStyle}>Venue of Blood Camp</td>
            <td style={tdStyle}>
              {data?.address},{data?.city},{data?.district},{data?.state}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>4.</td>
            <td style={tdStyle}>Distance from Blood Center</td>
            <td style={tdStyle}>{data?.distance || 0} Km</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>5.</td>
            <td style={tdStyle}>Date of VDB Camp</td>
            <td style={tdStyle}>{moment(data?.camp_schedule_date).format('DD-MMM-YYYY') || 'NA'}</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>6.</td>
            <td style={tdStyle}>License No. & Validity Blood Center</td>
            <td style={tdStyle}>
              {settingData?.licence_number || ''}, Dated{' '}
              {moment(settingData?.licence_valid_start).format('DD-MMM-YYYY') || 'NA'}, Valid till{' '}
              {moment(settingData?.licence_valid_end).format('DD-MMM-YYYY') || 'NA'}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>7.</td>
            <td style={tdStyle}>Type of Blood Center</td>
            <td style={tdStyle}>Pvt</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>8.</td>
            <td style={tdStyle}>Date of Application</td>
            <td style={tdStyle}>{moment(data?.createdAt).format('DD-MMM-YYYY') || 'NA'}</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>9.</td>
            <td style={tdStyle}>
              List of technical staff participating in VBD camp (M.O., Regd. Nurse, Technician etc.)
            </td>
            <td style={{ padding: 0, ...tdStyle }}>
              {/* {data?.CampStaffEntities?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-hover report_tablee">
                    <thead style={tabHead}>
                      <tr>
                        <th style={tabHeadth}>S. No.</th>
                        <th colSpan={2} style={tabHeadth}>
                          Name
                        </th>
                        <th style={tabHeadth}>Yes/No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.CampStaffEntities?.map((item, i) => (
                        <tr key={i}>
                          <td style={tableTd}>{i + 1}</td>
                          <td style={tableTd} colSpan={2}>
                            {item?.name}
                          </td>
                          <td style={tableTd}>Yes</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null} */}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>10.</td>
            <td style={tdStyle}>Name of MOIC & M.O of Blood Center (Approved)</td>
            <td style={{ padding: 0, ...tdStyle }}>
              {doctors?.length ? (
                <table style={{ width: '100%' }}>
                  <thead style={tabHead}>
                    <tr>
                      <th style={tabHeadth}>S. No.</th>
                      <th colSpan={2} style={tabHeadth}>
                        Name of Doctor
                      </th>
                      <th style={tabHeadth}>MOIC/M.O</th>
                      <th style={tabHeadth}>Yes/No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctors.map((item, i) => (
                      <tr key={i}>
                        <td style={tableTd}>{i + 1}</td>
                        <td style={tableTd} colSpan={2}>
                          {item?.name}
                        </td>
                        <td style={tableTd}>
                          {JSON.parse(item?.role).map((items, i) => (
                            <span key={i}>{items}</span>
                          ))}
                        </td>
                        <td style={tableTd}>Yes</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>11.</td>
            <td style={tdStyle}>Name of Approved Regd. Nurse</td>
            <td style={{ padding: 0, ...tdStyle }}>
              {nurses?.length ? (
                <table style={{ width: '100%' }}>
                  <thead style={tabHead}>
                    <tr>
                      <th style={tabHeadth}>S. No.</th>
                      <th colSpan={2} style={tabHeadth}>
                        Name of Nurse
                      </th>
                      <th style={tabHeadth}>Yes/No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nurses?.map((item, i) => (
                      <tr key={i}>
                        <td style={tableTd}>{i + 1}</td>
                        <td style={tableTd} colSpan={2}>
                          {item?.name}
                        </td>
                        <td style={tableTd}>Yes</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>12.</td>
            <td style={tdStyle}>Name of Approved Technician</td>
            <td style={{ padding: 0, ...tdStyle }}>
              {technicians?.length ? (
                <table style={{ width: '100%' }}>
                  <thead style={tabHead}>
                    <tr>
                      <th style={tabHeadth}>S. No.</th>
                      <th colSpan={2} style={tabHeadth}>
                        Name of Tech.
                      </th>
                      <th style={tabHeadth}>Yes/No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {technicians?.map((item, i) => (
                      <tr key={i}>
                        <td style={tableTd}>{i + 1}</td>
                        <td style={tableTd} colSpan={2}>
                          {item?.name}
                        </td>
                        <td style={tableTd}>Yes</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>13.</td>
            <td style={tdStyle}>Name Of Counsellor</td>
            <td style={tdStyle}>
              {Counsellor?.map((item, i) => (
                <p
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '0.8rem',
                    lineHeight: '15px',
                    letterSpacing: '0px',
                    fonWeight: '500'
                  }}
                  key={i}
                >
                  {item?.name}
                </p>
              ))}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>14.</td>
            <td style={tdStyle}>Purpose of VBO Camp</td>
            <td style={tdStyle}>{data?.camp_purpose}</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>15.</td>
            <td style={tdStyle}>Vehicle/Ambulance No.</td>
            <td style={tdStyle}>
              {drivers?.map((item, i) => (
                <p
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '0.8rem',
                    lineHeight: '15px',
                    letterSpacing: '0px',
                    fonWeight: '500'
                  }}
                  key={i}
                >
                  {item?.vehicle_no}
                </p>
              ))}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>16.</td>
            <td style={tdStyle}>Regd. Certificate Number of Vehicle & Validity.</td>
            <td style={tdStyle}>
              {drivers?.map((item, i) => (
                <p
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '0.8rem',
                    lineHeight: '15px',
                    letterSpacing: '0px',
                    fonWeight: '500'
                  }}
                  key={i}
                >
                  {item?.vehicle_no} - {moment(item?.validity).format('DD-MMM-YYYY') || 'NA'}{' '}
                </p>
              ))}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>17.</td>
            <td style={tdStyle}>Equipment List of VBO Camp</td>
            <td style={{ padding: 0, ...tdStyle }}>
              {data?.ItemCampEntities?.length ? (
                <table style={{ width: '100%' }}>
                  <thead style={tabHead}>
                    <tr>
                      <th style={tabHeadth}>S. No.</th>
                      <th colSpan={2} style={tabHeadth}>
                        Name
                      </th>
                      {/* <th style={tabHeadth}>Yes/No</th> */}
                      <th style={tabHeadth}>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.ItemCampEntities?.map((item, i) => (
                      <tr key={i}>
                        <td style={tableTd}>{i + 1}</td>
                        <td style={tableTd} colSpan={2}>
                          {item?.ItemStockEntity?.name}
                        </td>
                        {/* <td style={tableTd}>Yes</td> */}
                        {/* <td style={tableTd}>{item?.selected ? "Yes":"No"}</td> */}
                        <td style={tableTd}>{item?.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>18.</td>
            <td style={tdStyle}>Facilities Provided at VBO Camp Site.</td>
            <td style={{ padding: 0, ...tdStyle }}>
              {allFacility?.length ? (
                <table style={{ width: '100%' }}>
                  <thead style={tabHead}>
                    <tr>
                      <th style={tabHeadth}>S. No.</th>
                      <th colSpan={2} style={tabHeadth}>
                        Name
                      </th>
                      <th style={tabHeadth}>Yes/No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allFacility?.map((item, i) => (
                      <tr key={i}>
                        <td style={tableTd}>{i + 1}</td>
                        <td style={tableTd} colSpan={2}>
                          {item?.substring(0, item?.indexOf(':'))}
                        </td>
                        <td style={tableTd}>Yes</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </td>
          </tr>

          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>19.</td>
            <td style={tdStyle}>Storage facility of Blood Units Provided for VBO Camp.</td>
            <td style={tdStyle}>Yes</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>20.</td>
            <td style={tdStyle}>Tentative Blood Unit Collection Quantity (Expected).</td>
            <td style={tdStyle}>{data?.expected_unit_collection}</td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>21.</td>
            <td style={tdStyle}>Duration time of Blood Donation Camp (Expected).</td>
            <td style={tdStyle}>
              {moment(data?.camp_start_timing, ['hh:mm']).format('hh:mm A') || 'NA'} -{' '}
              {moment(data?.camp_ending_time, ['hh:mm']).format('hh:mm A') || 'NA'}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>22.</td>
            <td style={tdStyle}>Blood Centre having permission to Blood Components.</td>
            <td style={{ padding: 0, ...tdStyle }}>
              {bloodComponents?.length ? (
                <table style={{ width: '100%' }}>
                  <thead style={tabHead}>
                    <tr>
                      <th style={tabHeadth}>S. No.</th>
                      <th colSpan={2} style={tabHeadth}>
                        Component Name .
                      </th>
                      <th style={tabHeadth}>Yes/No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bloodComponents?.map((item, i) => (
                      <tr key={i}>
                        <td style={tableTd}>{i + 1}</td>
                        <td style={tableTd} colSpan={2}>
                          {ComponentLabelsCompFn(item?.component)}
                        </td>
                        <td style={tableTd}>Yes</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>23.</td>
            <td
              style={{
                ...tdStyle,
                wordBreak: 'break-word',
                whiteSpace: 'initial'
              }}
            >
              Explanation regarding the purpose of VBD CAMP and preparation of Blood Components (Specially FFP and
              Platelet Concentrate) in stipulated time of collection of Blood units as per rules.
            </td>
            <td
              style={{
                ...tdStyle,
                wordBreak: 'break-word',
                whiteSpace: 'initial'
              }}
            >
              Purpose of VBD CAMP - To Fulfill the Demand of Blood & Blood Component. Preparation of Blood Component -
              Transportation of Blood Will Be Done in Insulated Boxes (Temperature Maintained) From Camp Site to Blood
              Bank & All Components will be prepared within 6 Hours from collection as per rules.
            </td>
          </tr>
          <tr>
            <td style={{ ...tdStyle, textAlign: 'center', width: '3rem' }}>24.</td>
            <td
              style={{
                ...tdStyle,
                wordBreak: 'break-word',
                whiteSpace: 'initial'
              }}
            >
              In case of inter-district VBD CAMP the intimate has been submitted to both corresponding ADC/DCO with
              checklist to both district .
            </td>
            <td style={tdStyle}>NOT Applicable</td>
          </tr>
        </tbody>
      </table>

      <table style={{ padding: '1rem', width: '100%', border: '1px solid #e9edf3' }}>
        <tbody>
          <tr>
            <td style={{ width: '100%', fontWeight: 500, fontSize: '14px' }}>
              Attach following documents (mandatory):
            </td>
          </tr>
          <tr>
            <td style={tdBottomStyle}>1. License copy & Validity</td>
          </tr>
          <tr>
            <td style={tdBottomStyle}>2. Request from VBD CAMP organizing Agency.</td>
          </tr>
          <tr>
            <td style={tdBottomStyle}>3. Vehicle Regd. Documents</td>
          </tr>
          <tr>
            <td style={tdBottomStyle}>4. SOP of VBD CAMP</td>
          </tr>
          <tr>
            <td style={tdBottomStyle}>
              5. List of Technical Staff participating in VBD CAMP (M.O., Regd. Nurse, Technician etc.)
            </td>
          </tr>
          <tr>
            <td style={tdBottomStyle}>6. List of approved Technical Staff with approval documents/Order.</td>
          </tr>
          <tr>
            <td style={{ paddingLeft: '2rem' }}>
              <ul>
                <li style={tdBottomStyle}>Medical Officer</li>
                <li style={tdBottomStyle}>Regd. Nurse</li>
                <li style={tdBottomStyle}>Technician</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default BloodCampReport

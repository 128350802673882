import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

export const searchStyles = {
    maxWidth: '400px',  // Maximum width of the input
    width: '100%',
    '& .MuiInput-underline:before': {
        borderBottom: '1px solid #CFD8DC', // Default bottom border color
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid #CFD8DC', // Same color on hover
    },
    '& .MuiInput-underline:after': {
        borderBottom: '2px solid #1976d2', // Use the default HTML input active color (usually a shade of blue)
    },
    '& .MuiInputBase-input': {
        color: 'black', // Default input text color
    },
}


const SearchBox = ({
    value,
    onChange,
    placeholder = 'Search...',
    icon = '/assets/images/setting-5.svg', // Default icon
    iconAlt = 'search-icon',
    onIconClick,
    variant = 'standard',
    fullWidth = true,
    sx = {},
    inputProps = {},
    className = '',
    type = 'text',
}) => {
    return (
        <TextField
            variant={variant}
            value={value}
            fullWidth={fullWidth}
            onChange={onChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <img
                            src={icon}
                            alt={iconAlt}
                            onClick={onIconClick}
                            style={{ cursor: 'pointer',  height: '24px' }}
                        />
                    </InputAdornment>
                ),
                ...inputProps, // To allow additional customization for InputProps
            }}
            sx={{ ...searchStyles, ...sx }}
            className={className}
            type={type}
            placeholder={placeholder}
        />
    );
};

export default SearchBox;

import React, { useEffect, useState } from 'react'
import ReactApexcharts from 'react-apexcharts'
import { Line } from 'react-chartjs-2'
import AxiosHelper from '../../../helper/AxiosHelper'
import CardHeading from './cardHeading'
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import NoDataFound from '../../common/NoDataFound'
import { bloodGroup, bloodGroupToFilter } from '../DashboardNew'
import Loader from '../../shared/Loader'
import ModalCustom from '../../shared/ModalCustom'
import { DashboardNoDat } from './WasteChart'
import { typeAbbreviationsReverse } from './BloodComponents'
import { checkTenantId } from '../../../helper/CommonAPI'
import BloogGroupPrintRH from '../../shared/BloogGroupPrintRH'

export const typeAbbreviations = {
  'Whole Blood (CPDA-1)': 'WB (CPDA-1)',
  'Whole Blood (CPDA-1)': 'WB',
  'Whole Blood (CPD)': 'WB (CPD)',
  'Fresh Frozen Plasma': 'FFP',
  Plasma: 'Plasma',
  'Packed Red Blood Cells': 'PRBC',
  'Single Donor Platelet': 'SDP',
  'Random Donor Platelets': 'RDP',
  'Platelets additive solutions': 'PAS',
  'Platelet Concentrate': 'PC',
  Cryoprecipitate: 'Cryo',
  'Cryo Poor Plasma': 'CPP',
  '': 'Total'
}

const AvailableBlood = () => {
  const [loading, setLoading] = useState(true)
  const [BloodData, setBloodData] = useState(null)
  const [type, setType] = React.useState('')
  const [bloodtypeLabels, setBloodtypeLabels] = useState([])
  const [bloodtypeValues, setBloodtypeValues] = useState([])
  const [bloodGrp, setBloodGrp] = useState(Object.keys(bloodGroup)[0])
  const [bloodCompData, setBloodCompData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [openModal, setOpenModal] = React.useState(false)
  const [endDate, setEndDate] = useState(null)
  const [updatedDate, setUpdatedDate] = useState(null)
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true, // This makes each bar have a different color
          barHeight: 40,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: ['#13BCAB', '#376196', '#881A1A', '#9B8126', '#783611'],
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333']
        },
        offsetX: 6,
        formatter: function (val) {
          return val.toFixed(0)
        }
      },
      xaxis: {
        categories: [],
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '12px',
            textAlign: 'left'
          },
          align: 'left'
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          width: 80,
          offsetX: 0,
          offsetY: 0
        }
      },
      tooltip: {
        enabled: false
      },
      colors: ['#13BCAB', '#376196', '#881A1A', '#9B8126', '#783611']
    }
  })

  function changeBlood(blood) {
    setBloodGrp(blood)
  }

  const getDashboardBloodComp = () => {
    setLoading(true)
    const URL=checkTenantId(`dashboard/blood-stock?isAvailableStock=true&inventorytype=${type || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}`)
    AxiosHelper.get(
      URL
    )
      .then(resp => {
        let bloodAll = resp?.data?.data?.bloodGroupWiseCount
        if (bloodAll?.length) {
          setBloodCompData(resp?.data?.data)
          setBloodData(resp?.data?.data?.bloodGroupWiseCount)
          const typeLabels = bloodAll?.map(item => typeAbbreviations[item.type] || 'Total')
          const totalValues = bloodAll?.map(item => item?.total || 0)
          setBloodtypeLabels(typeLabels)
          setBloodtypeValues(totalValues)
          // Update chart data
          setChartData({
            series: [
              {
                name: 'Quantity',
                data: totalValues
              }
            ],
            options: {
              ...chartData.options,
              xaxis: {
                categories: typeLabels
              }
            }
          })
        } else {
          setBloodCompData(null)
          setBloodData(null)
        }
        setUpdatedDate(new Date())
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (bloodGrp) {
      let apiToUser = bloodGroupToFilter[bloodGrp]
      const totalValues = BloodData?.map(item => {
        return item[apiToUser] || 0
      })
      if (totalValues?.length) {
        setChartData({
          series: [
            {
              name: 'Quantity',
              data: totalValues
            }
          ]
        })
        setBloodtypeValues(totalValues)
      }
    }
  }, [bloodGrp])

  useEffect(() => {
    getDashboardBloodComp()
  }, [type, startDate, endDate])

  return (
    <>
      <CardHeading
        path='/blood-stock'
        title='Available Blood Stock'
        count={bloodCompData?.totalBloodCount}
        refreshDataApi={getDashboardBloodComp}
        loading={loading}
        setType={setType}
        setStartDateCustom={setStartDate}
        setEndDateCustom={setEndDate}
        updatedDate={updatedDate}
      />
      {BloodData ? (
        <div style={{ padding: '0px' }} className='dash-card-100 cleartop-m-p'>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <section className='dash-table-60  blood-section'>
                <div className='table-container_stock'>
                  <table className='blood-type-table'>
                    <thead>
                      <tr>
                        <th>
                          {' '}
                          <Typography sx={{ fontWeight: 600, fontSize: '12px', mr: '3px' }}>Blood Type</Typography>
                        </th>
                        {Object.keys(bloodGroup)?.map((item, index) => (
                          <th className='blood-grp-btn'>
                            <button
                              key={index}
                              type='button'
                              onClick={() => changeBlood(item)}
                              className={item === bloodGrp ? 'active' : ''}
                            >
                              <BloogGroupPrintRH bloodGroup={bloodGroup[item]} />
                              {/* {bloodGroup[item]}{' '} */}
                            </button>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {BloodData?.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <Typography sx={{ fontWeight: 600, fontSize: '10px', whiteSpace: 'normal' }}>
                              {row?.type ? typeAbbreviations[row?.type] : 'Total'}
                            </Typography>
                          </td>

                          <td>{row?.total}</td>
                          <td>{row['AB-Ve'] || '-'}</td>
                          <td>{row['AB+Ve'] || '-'}</td>
                          <td>{row['A+Ve'] || '-'}</td>
                          <td>{row['A-Ve'] || '-'}</td>
                          <td>{row['B+Ve'] || '-'}</td>
                          <td>{row['B-Ve'] || '-'}</td>
                          <td>{row['O+Ve'] || '-'}</td>
                          <td>{row['O-Ve'] || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </Grid>
            <Grid item xs={12} lg={4}>
              <section className='dash-table-40 blood-section py-3'>
                <div className='card_das_title d-flex justify-content-between align-items-start'>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h3 className=' page-title-dash pb-1'>Available blood components for</h3>
                    <div className='blood-grp-btn ml-1'>
                      <button type='button' className={'active'}>
                        {bloodGroup[bloodGrp]}{' '}
                      </button>
                    </div>
                  </Box>

                  <IconButton
                    onClick={() => setOpenModal(true)}
                    sx={{
                      // background: 'linear-gradient(90deg,#214f89,#12bcab)',
                      borderRadius: '4px',
                      // color: '#fff',
                      height: '14px',
                      width: '14px'
                    }}
                  >
                    <img style={{ maxHeight: '20px' }} alt='' src='/assets/images/dashboard/file.svg' />
                  </IconButton>
                </div>

                <div style={{ position: 'relative' }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 10,
                      left: 4,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      pl: 3
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        width: '75px',
                        borderRight: '1px solid gray'
                      }}
                    >
                      Type
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        pl: 1
                      }}
                    >
                      Quantity
                    </Typography>
                  </Box>
                  <ReactApexcharts
                    options={chartData?.options}
                    series={chartData?.series}
                    type='bar'
                    height={bloodtypeLabels?.length > 6 ? 430 : 300}
                  />
                </div>
              </section>
            </Grid>
          </Grid>
        </div>
      ) : (
        <NoDataFound />
      )}

      <ModalCustom
        open={openModal}
        closeDialog={() => setOpenModal(false)}
        scroll='paper'
        //size="md"
        title={'Available blood components'}
        dialogStyle={{ px: 1.5, pb: 1, pt: 0 }}
      >
        <Grid spacing={3} container>
          {bloodtypeLabels?.length ? (
            bloodtypeLabels?.map((item, i) => (
              <Grid item xs={12} sm={6} key={i}>
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                  {typeAbbreviationsReverse[item] || ''}
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{bloodtypeValues[i] || ''} Unit</Typography>
              </Grid>
            ))
          ) : (
            <DashboardNoDat />
          )}
        </Grid>
      </ModalCustom>
    </>
  )
}

export default AvailableBlood

import React, { useEffect, useState } from 'react'
import PageHeader from '../common/PageHeader'
import { Button } from 'react-bootstrap'
import '../modal/ModalTemplate.css'
import { useHistory, useParams } from 'react-router-dom'
import AxiosHelper from '../../helper/AxiosHelper'
import Loader from '../shared/Loader'
import TableRow from './TableRow'
import BreadCrumb from '../common/BreadCrumb'
import { LabelDiv } from '../screening/ScreeningDonorDetails'
import NoDataFound from '../common/NoDataFound'
import { checkPermission } from '../middleware/auth'
import { Alert } from '@mui/material'
import ScrollCard from '../shared/ScrollCard'
import ScrollBar from 'react-perfect-scrollbar'
import DonorTop from '../common/DonorTop'
import moment from 'moment'
import Add from '@mui/icons-material/Add'
import DeleteConfirm from '../shared/DeleteConfirm'
import { toast } from 'react-toastify'
import { BloogGroupPrintRHFn } from '../shared/BloogGroupPrintRH'

export const ColumnDetail = ({ title, label, isEdit = false }) => {
  return (
    <div className='col align-items-center '>
      <label>
        {label}
        {isEdit ? <span className='text-danger'>*</span> : null}
      </label>
      {label === 'Attachments' ? (
        <h6 className='chip_div'>
          {title?.map((fileName, fileIdx) => {
            return (
              <span className='chip_custom' key={fileIdx}>
                {fileName}
              </span>
            )
          })}
        </h6>
      ) : (
        <h6>{title}</h6>
      )}
    </div>
  )
}

const DonorInventory = () => {
  const [isLoading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [donorHistory, setDonorHistory] = useState([])
  const [bloodBagIssueEntity, setBloodBagIssueEntity] = useState([])
  const [todayBag, setTodayBag] = useState({})
  const [ttiError, setTtiError] = useState(false)
  const [step, setStep] = useState('')
  const [sectionInfo, setSectionInfo] = useState([])
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [delteId, setDeleteId] = useState('')

  const history = useHistory()
  const { id } = useParams()

  const getHistory = async () => {
    setPageLoading(true)
    await AxiosHelper.get(`donor/history/${id}`)
      .then(response => {
        let res = response?.data?.data
        setStep(response.data.data.steps)

        setDonorHistory(res)
        let arr = []
        if (res?.DonorScreenings) {
          for (let i of res?.DonorScreenings) {
            if (i?.BloodBagIssueEntity) {
              arr.push({ ...i?.BloodBagIssueEntity, screening_date: i?.screening_date })
            }
          }
        }
        setBloodBagIssueEntity(arr)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
      })
  }

  const getTodayBag = () => {
    setLoading(true)
    AxiosHelper.get(`donor/today-blood-bag/${id}`)
      .then(response => {
        let daata = response?.data?.data
        setTodayBag(response.data.data)

        setLoading(false)
        console.log(daata)

        if (daata?.BloodTestingEntity?.is_declined) {
          setTtiError(true)
        } else {
          setTtiError(false)
        }
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getHistory()
    getTodayBag()
  }, [])

  useEffect(() => {
    let topinfo = []
    if (donorHistory) {
      topinfo = [
        { label: 'Donor-ID', value: donorHistory?.reg_id || 'N/A' },
        { label: 'Mobile', value: donorHistory?.mobile ? <>+91 {donorHistory?.mobile}</> : 'N/A' },
        {
          label: 'Donation Date',
          value: donorHistory?.donation_date ? moment(donorHistory?.donation_date).format('DD-MMM-YYYY') : 'N/A'
        },
        { label: 'Blood Group', value: BloogGroupPrintRHFn(donorHistory?.blood_group || 'N/A') }
      ]
    }
    setSectionInfo(topinfo)
  }, [donorHistory])

  const breadcrumbRoute = [
    {
      path: '/donors',
      label: 'Donors'
    },
    {
      path: '',
      label: 'Donor Inventory'
    }
  ]

  function closeDelete() {
    setOpenDelete(false)
    setDeleteId('')
    setDeleteLoading(false)
  }
  function openDeleteBox(id) {
    setOpenDelete(true)
    setDeleteId(id)
  }

  async function handleDelete() {
    setDeleteLoading(true)
    AxiosHelper.delete(`blood-search/${delteId?.id}/component`)
      .then(response => {
        closeDelete()
        getHistory()
        toast.success('Component Deleted Successfully')
      })
      .catch(error => {
        setDeleteLoading(false)
        console.log(error)
        toast.error(error || 'Something went wrong on our side')
      })
  }

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute}>
        <PageHeader title='Donor Inventory' />
      </BreadCrumb>

      <ScrollCard>
        {/* <ProfileTop info={donorHistory} /> */}
        <DonorTop info={sectionInfo} basicInfo={donorHistory} />
        <Loader isLoading={isLoading} />
        {/* {step === '5' && donorHistory?.is_defered !== true ? (
          <Alert severity='error' show={ttiError} onClose={() => setTtiError(false)} dismissible sx={{ my: 2 }}>
            TTI Testing Not Completed!
          </Alert>
        ) : null} */}
        {ttiError ? (
          <Alert severity='error' show={ttiError} onClose={() => setTtiError(false)} dismissible sx={{ my: 2 }}>
            Failed in TTI testing
          </Alert>
        ) : null}

        {todayBag && Object.keys(todayBag).length > 0 ? (
          <LabelDiv title='Add Blood Components'>
            <>
              <div className='row add_blood'>
                <ColumnDetail label='Blood Bag Number' title={todayBag?.bag_id || 'N/A'} />
                <ColumnDetail label='Segment Number' title={todayBag?.tube_number || ''} />
                <ColumnDetail label='Bag Type' title={todayBag?.bag_type || 'N/A'} />
                {/* <ColumnDetail
                        label="Blood Volume(ml)"
                        title={todayBag?.volume || "N/A"}
                      /> */}
              </div>
              {checkPermission('CREATE_BLOODS') && (
                <div className=''>
                  {/* {step === '5' ? (
                      <button
                        disabled
                        style={{
                          cursor: 'not-allowed'
                        }}
                        className='btn btn-gradient-primary mr-2 rounded-lg '
                      >
                        <Add />Inventory
                      </button>
                    ) : ( */}
                  <Button
                    disabled={ttiError}
                    style={{
                      cursor: ttiError ? 'not-allowed' : 'pointer'
                    }}
                    onClick={() => history.push('/donors-detail/donor-inventory/add/' + id)}
                    className='btn btn-gradient-primary mr-2 rounded-lg '
                  >
                    <Add /> Inventory
                  </Button>
                  {/* )} */}
                </div>
              )}
            </>
          </LabelDiv>
        ) : (
          <LabelDiv title='Blood Bag Assigned'>
            <NoDataFound title='No Blood Bag Assigned' />
          </LabelDiv>
        )}

        <div className=''>
          <div className='table-responsive'>
            <ScrollBar>
              <table className='table  table-hover'>
                <thead>
                  <tr className='table_header'>
                    <th>Date & Time</th>
                    <th>Donor-ID</th>
                    <th>
                      Bag Type <div className='mt-1'>Bag Number</div>
                    </th>
                    {/* <th>Bag Number</th> */}
                    <th>Segment Number</th>
                    <th>Blood Component </th>
                    <th>
                      Extracted<div className='mt-1'>Volume (ML)</div>{' '}
                    </th>
                    <th>Unit</th>
                    <th>Expiry Days</th>
                    <th>Date of Expiry</th>
                  </tr>
                </thead>
                <tbody className='inventory_tab'>
                  {bloodBagIssueEntity?.length > 0
                    ? bloodBagIssueEntity.map((item, idx) => (
                        <TableRow bag={item} key={idx} donorHistory={donorHistory} openDeleteBox={openDeleteBox} />
                      ))
                    : null}
                </tbody>
              </table>
            </ScrollBar>
          </div>
          {bloodBagIssueEntity?.length === 0 ? (
            <div className='emty_cell'>
              <img src={'/assets/images/donor_inventory/addTableData.svg'} alt='search-icon' />

              <h6 className='w-100 div_label_text text-center mt-2'>No Data Found</h6>
            </div>
          ) : null}
        </div>
      </ScrollCard>

      <DeleteConfirm
        open={openDelete}
        loading={deleteLoading}
        closeDialog={closeDelete}
        confirmDialog={handleDelete}
        title='Are You Sure To Delete?'
      >
        <div>
          <p>You are about to delete Component details</p>
        </div>
        <div className='table-responsive' style={{ boxShadow: '5px 5px 24px 0px rgba(63, 81, 181, 0.10)' }}>
          <table className='table  table-hover'>
            <tbody>
              <tr>
                <td>Component Name</td>
                <td>{delteId?.component || '-'}</td>
              </tr>
              <tr>
                <td>Volume</td>
                <td className='text-capitalize'>{delteId?.volume || '-'}</td>
              </tr>
              <tr>
                <td>Unit</td>
                <td>1</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DeleteConfirm>
    </div>
  )
}

export default DonorInventory

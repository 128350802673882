import React from 'react'
import { useAppContext } from '../../AppContext'
const PrintHeader = () => {
    const { user } = useAppContext()
    console.log(user)
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px 16px",
                minHeight: "78px",
                alignItems: "center",
            }}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                {user?.logo ?
                    <div>
                        <img
                            src={user?.logo}
                            style={{ height: "50px", fontSize: "6px", paddingRight: "4px" }}
                            alt="Bank Logo" />
                    </div>
                    :
                    null
                }
                <div style={{ color: "#000", fontFamily: "Arial", fontSize: "14px", fontWeight: "700", }}>
                    {user?.name || "Blood Bank Name : N/A"}
                    <div style={{ fontSize: "10px", marginTop: "4px" }}>
                        License No:{" "}{user?.licence_number || "NA"}
                    </div>
                </div>
            </div>

            <div
                style={{
                    // width: "59%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        gap: "12px",
                        marginTop: "4px",
                        color: "#000",
                        fontSize: "10px",
                        fontWeight: "600"
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            fontSize: "10px",
                        }}
                    >
                        Call :<span style={{ fontWeight: 500 }}> {user?.contact_number || ""}</span>
                    </span>

                    <span
                        style={{
                            display: "flex",
                            borderLeft: "1px solid #282828",
                            paddingLeft: "0.8rem",
                            textTransform:"none" ,
                        }}
                    >
                        Email :<span style={{ fontWeight: 500 }}> {user?.email || ""}</span>
                    </span>
                </div>
                <div
                    style={{
                        color: "#000",
                        fontSize: "10px",
                        fontWeight: "600",
                        paddingTop:"4px",
                    }}
                >
                    Address :
                    <span style={{ fontWeight: 500 }}>
                        {" "}
                        {user?.TenantAddressEntity?.street},
                        {user?.TenantAddressEntity?.city},
                        {user?.TenantAddressEntity?.area},
                        {user?.TenantAddressEntity?.state},
                        {user?.TenantAddressEntity?.pincode}{" "}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PrintHeader

import React, { useRef } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { Download, Print } from '@mui/icons-material'
import Pdf from 'react-to-pdf'
import BloodCampReport from '../BloodCampReport'
import PrintWrapperWithoutButton from '../../print-components/PrintWrapperWithoutButton'
import { printAll } from '../../../helper/CommonAPI'

const active = {
  background: 'var(--default, linear-gradient(89deg, #204E89 0%, #12B9AA 100%))',
  color: '#FFFFFF',
  borderRadius: '0'
}

function PreReport({ data }) {
  const previewRef = useRef()

  return (
    <>
      <div className='bloodbank_action'>
        <p>Pre-Camp Report</p>
        <div className='d-flex' style={{ gap: '10px' }}>
          <Tooltip title='Print'>
            <IconButton
              onClick={() => {
                if (previewRef.current) {
                  previewRef.current.click()
                } else {
                  printAll('report_pdf', 'report_iframe')
                }
              }}
              color='primary'
              sx={{ ...active, borderRadius: 1 }}
            >
              <Print />
            </IconButton>
          </Tooltip>
          {/* <Pdf targetRef={previewRef} filename="document.pdf">
            {({ toPdf }) => (
              <IconButton color="primary" sx={{ ...active, borderRadius: 1 }} onClick={toPdf}>
                <Download />
              </IconButton>
            )}
          </Pdf> */}

          {/* <IconButton
            color="primary"
            sx={{ ...active, borderRadius: 1 }}
            //   onClick={() => setEditTab(true)}
          >
            <img
              src={"/assets/images/reports/Vector (1).svg"}
              alt="Blood Bag"
            />
          </IconButton> */}

          <IconButton
            color='primary'
            sx={{ ...active, borderRadius: 1 }}
            //   onClick={() => setEditTab(true)}
          >
            <a href={`mailto:email@example.com?subject='Reports!'&body=Report Link -${`/print-pages/camp/${data.id}`}`}>
              <img src='/assets/images/reports/Group.svg' alt='Blood Bag' />
            </a>
          </IconButton>
        </div>
      </div>
      <PrintWrapperWithoutButton >
        <BloodCampReport id='report_pdf' data={data} />
      </PrintWrapperWithoutButton>
    </>
  )
}

export default PreReport

import React from 'react'
import moment from 'moment'
import { bloodGroupGet } from '../../donors/TTI'

function removeUnderscores(str) {
  return str?.replace(/_/g, ' ')
}

const BloodCampPostReport = ({ reportData, id, print = false, data, ref }) => {
  const { CampDetail, GroupWiseCollection } = reportData

  return (
    <div
      id={id || 'report_pdf'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        // width: '800px',
        width: '100%',
        minHeight: '842px',
        flexShrink: '0',
        background: '#FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: '400'
      }}
    >
      {print === false ? null : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 16px',
            alignItems: 'center'
          }}
        >
          <img
            alt=''
            src={'/assets/images/templateCertificate/organizer-logo.png'}
            style={{ width: '96px', height: '48px' }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px',
              fontWeight: '500'
            }}
          >
            <div>
              <span
                style={{
                  color: 'var(--ehr-secondary-d-0, #214F8A)',
                  fontSize: '12px'
                }}
              >
                Swastik Blood Centre-Rajasthan
              </span>
            </div>
            <div style={{ display: 'flex', gap: '12px', marginTop: '4px' }}>
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    display: 'flex',
                    color: 'var(--ehr-secondary-d-0, #214F8A)'
                  }}
                >
                  Call :
                </span>

                <span
                  style={{
                    color: 'var(--gray-gray-800, #111827)'
                  }}
                >
                  0123456789
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    display: 'flex',
                    color: 'var(--ehr-secondary-d-0, #214F8A)'
                  }}
                >
                  Email :
                </span>

                <span
                  style={{
                    color: 'var(--gray-gray-800, #111827)'
                  }}
                >
                  admin@gmail.com
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          width: '100%',
          height: '37px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFFFFF',
          fontSize: '16px',
          fontWeight: '700',
          background: 'var(--Ehr, linear-gradient(180deg, #21538B 0%, #13B9AA 100%))'
        }}
      >
        Post Camp Collection Report -{moment().format('MMM-YYYY')}
      </div>

      {/* 1.Total blood / blood group wise collected */}
      <div>
        <div
          style={{
            maxWidth: '100%',
            height: '37px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#000000',
            fontSize: '14px',
            fontWeight: '600',
            padding: '0 16px',
            background: 'var(--ehr-secondary-l-9, #E9EDF3)'
          }}
        >
          <div>1.Information related to blood donation camp</div>
        </div>
        <div style={{ padding: '16px' }}>
          <table style={{ textAlign: 'center', width: '100%' }}>
            <thead
              style={{
                fontSize: '12px',
                fontWeight: '500',
                whiteSpace: 'nowrap'
              }}
            >
              <tr
                style={{
                  height: '30px',
                  background: '#F2F9F7',
                  padding: '10px',
                  verticalAlign: 'middle',
                  textAlign:"left"
                }}
              >
                <th>Camp Date</th>
                <th>District</th>
                <th>Tehsil</th>
                <th>Blood Donation Place</th>
                <th>Camp Organizer</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '12px', whiteSpace: 'normal' }}>
              <tr
                style={{
                  height: '23px',
                  marginTop: '10px',
                  // borderBottom: '1px solid #E1E3E1',
                  fontWeight: '400',
                  textAlign:"left"
                }}
              >
                <td> {moment(CampDetail?.camp_schedule_date).format('DD/MM/YYYY')} </td>
                <td>{CampDetail?.district || '-'}</td>
                <td>{CampDetail?.city || '-'}</td>
                <td>{CampDetail?.address || '-'}</td>
                <td>{CampDetail?.organizer_name || '-'}</td>
              </tr>
            </tbody>
          </table>
          <table style={{ textAlign: 'center', width: '100%', marginTop: '10px' }}>
            <thead
              style={{
                fontSize: '12px',
                fontWeight: '500',
                whiteSpace: 'nowrap'
              }}
            >
              <tr
                style={{
                  height: '30px',
                  background: '#F2F9F7',
                  padding: '10px',
                  verticalAlign: 'middle',
                  textAlign:"left"
                }}
              >
                <th>Blood Bank Name</th>
                <th>Address</th>
                <th>Blood Collection</th>
                <th>Total Collection</th>
                <th>Reason/Ocassion</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '12px', whiteSpace: 'initial' }}>
              <tr
                style={{
                  height: '23px',
                  marginTop: '10px',
                  fontWeight: '400',
                  textAlign:"left"
                }}
              >
                <td>{CampDetail?.TenantDetail?.name}</td>
                <td>
                  {CampDetail?.TenantDetail?.TenantAddressEntity?.street},
                  {CampDetail?.TenantDetail?.TenantAddressEntity?.area},<br />
                  {CampDetail?.TenantDetail?.TenantAddressEntity?.state},
                  {CampDetail?.TenantDetail?.TenantAddressEntity?.pincode},
                </td>
                <td>
                  350ml : {CampDetail?.BloodCollection?.BloodCollection350ml || '-'}<br />
                450ml : {CampDetail?.BloodCollection?.BloodCollection450ml || '-'}
                  </td>
                <td>{CampDetail?.TotalBloodCollection || '-'}</td>
                <td>{removeUnderscores(CampDetail?.camp_purpose) || '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* 9.Blood group wise */}

      <div>
        <div
          style={{
            maxWidth: '100%',
            height: '37px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#000000',
            fontSize: '14px',
            fontWeight: '600',
            padding: '0 16px',
            background: 'var(--ehr-secondary-l-9, #E9EDF3)'
          }}
        >
          <div>2.Total blood group wise collected</div>
        </div>

        <div style={{ padding: '16px' }}>
          <table style={{ textAlign: 'center', width: '100%' }}>
            <thead>
              <tr
                style={{
                  height: '26px',
                  background: '#F2F9F7',
                  fontSize: '12px',
                  padding: '5px 0px'
                }}
              >
                <th style={{ textAlign: 'left' }}>Blood Group</th>
                {GroupWiseCollection?.map((item, i) => (
                  <th key={i}>{bloodGroupGet[item?.BloodGroup] || item?.BloodGroup || ''}</th>
                ))}
              </tr>
            </thead>
            <tbody style={{ fontSize: '14px' }}>
              <tr>
                <td style={{ textAlign: 'left' }}>Unit</td>
                {GroupWiseCollection?.map((item, i) => (
                  <td key={i}>{item?.Unit || 0}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BloodCampPostReport

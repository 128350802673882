import { Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NoDataFound from '../../common/NoDataFound'
import { useHistory } from 'react-router-dom'
import ItemForm from './ItemForm'
import ScrollBar from 'react-perfect-scrollbar'
import { checkPermission } from '../../middleware/auth'

const ItemTable = ({ allItems = [], id, getCampDetails, camp, campReturnTab, isOldItem }) => {
  const [editTab, setEditTab] = useState(false)
  const [returnTab, setReturnTab] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (campReturnTab) {
      setReturnTab(true)
    }
  }, [campReturnTab])

  return (
    <>
      {allItems?.length === 0 || editTab === true ? null : (
        <div className='bloodbank_action  '>
          <p>Store Items</p>
          <div className='d-flex  gap-2 '>
            {editTab || !campReturnTab ? null : checkPermission('UPDATE_CAMP') === true ? (
              <button
                onClick={() => {
                  setEditTab(true)
                  setReturnTab(true)
                }}
                type='submit'
                className='btn gradient-btn'
              >
                Return Item
              </button>
            ) : null}
            {editTab || campReturnTab || isOldItem ? null : checkPermission('UPDATE_CAMP') === true ? (
              <button
                onClick={() => {
                  setEditTab(true)
                  setReturnTab(false)
                }}
                type='submit'
                className='btn gradient-btn'
              >
                Edit
              </button>
            ) : null}
          </div>
        </div>
      )}

      {editTab ? (
        <ItemForm
          currentItems={allItems}
          setEditTab={setEditTab}
          setReturnTab={setReturnTab}
          state={returnTab ? 'Return' : 'Edit'}
          camp={camp}
          returnTab={returnTab}
          editId={id}
          getCampDetails={getCampDetails}
        />
      ) : (
        <Card sx={{ mt: 2 }}>
          <div className='table-responsive'>
            <ScrollBar>
              {isOldItem ? (
                <table className='table  table-hover'>
                  <thead>
                    <tr className='table_header'>
                      <th>S. No.</th>
                      <th colSpan={2}>Item</th>
                      <th>Required in camp</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody className='inventory_tab'>
                    {allItems?.length > 0
                      ? allItems?.map((ele, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td colSpan={2}>{ele?.item || ''}</td>
                            <td>{ele?.selected ? 'Yes' : 'No'}</td>
                            <td>{ele?.quantity || '0'}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              ) : (
                <table className='table  table-hover'>
                  <thead>
                    <tr className='table_header'>
                      <th>S. No.</th>
                      <th colSpan={2}>Item</th>
                      <th>Manufacturer</th>
                      <th>Supplier</th>
                      {/* <th>Required in camp</th> */}
                      <th>Quantity</th>
                      <th>Used Quantity</th>
                      <th>Return Quantity</th>
                    </tr>
                  </thead>
                  <tbody className='inventory_tab'>
                    {allItems?.length > 0
                      ? allItems?.map((ele, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td colSpan={2}>{ele?.ItemStockEntity?.name || ''}</td>
                            <td>{ele?.ItemStockEntity?.ManufacturerEntity?.name || ''}</td>
                            <td>{ele?.ItemStockEntity?.SupplierEntity?.name || ''}</td>
                            {/* <td>{ele?.selected ? "Yes" : "No"}</td> */}
                            <td>{ele?.quantity || '0'}</td>
                            <td>{ele?.used_qty || '0'}</td>
                            <td>{ele?.return_qty || '0'}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              )}
            </ScrollBar>
          </div>
        </Card>
      )}

      {allItems?.length === 0 ? (
        <div className='blood-requisition-no-data'>
          <NoDataFound title={' '} />
          {checkPermission('UPDATE_CAMP') && (
            <div className=' empty-requisition-msg '>
              <button
                className='btn btn-gray add-requisition-btn-table'
                onClick={() => {
                  history.push(`/blood-camp-management/items/add/${id}`)
                }}
              >
                {' '}
                Add Item
              </button>

              <div className='add-requisition-msg'>
                <p>Click on Add Item button to add Items</p>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default ItemTable

import React, { useState, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import AxiosHelper from '../../../helper/AxiosHelper'
import * as yup from 'yup'
import { CircularProgress, Grid, FormLabel, MenuItem } from '@mui/material'
import Loader from '../../shared/Loader'
import FormProvider from '../../shared/FormProvider'
import RHFTextField from '../../shared/RHFTextfield'
import RHFSelect from '../../shared/RHFSelect'
import { toast } from 'react-toastify'
import { Table, TableBody, TableCell, TableRow, TableContainer,TableHead } from '@mui/material'
import { BorderBottom } from '@mui/icons-material'

const FormDivider = ({ title }) => {
  return (
    <Grid className='Donor-details-sections' item xs={12}>
      <div className='detail-header'>{title ? title : ''}</div>
    </Grid>
  )
}

const componentFields = {
  'Packed Red Blood Cells': [
    { label: 'Volume', name: 'volume' },
    { label: 'HB', name: 'hb' },
    { label: 'Culture Report', name: 'culture_report' },
    { label: 'Gross Appearance', name: 'gross_appearance' },
    { label: 'Haematocrit', name: 'haemotocrit' },
    { label: 'Anticoagulant Quantity', name: 'anticoagulant_quantity' }
  ],
  'Fresh Frozen Plasma': [
    { label: 'Volume', name: 'volume' },
    { label: 'Gross Appearance', name: 'gross_appearance' },
    { label: 'Culture Report', name: 'culture_report' },
    { label: 'PT', name: 'pt' },
    { label: 'PTT', name: 'ptt' },
    { label: 'Fibrinogen', name: 'fibrinogen' },
    { label: 'Factor VIII', name: 'factor_viii' }
  ],
  'PLC': [
    { label: 'Volume', name: 'volume' },
    { label: 'Gross Appearance', name: 'gross_appearance' },
    { label: 'WBC Contamination', name: 'wbc_contamination' },
    { label: 'RBC Contamination', name: 'rbc_contamination' },
    { label: 'Swirling of Platelets', name: 'swirling_of_platelets' },
    { label: 'Platelet Count', name: 'platelet_count' },
    { label: 'Platelet Yield', name: 'platelet_yield' },
    { label: 'PH Value', name: 'ph_value' }
  ],
  'Whole Blood (CPD)': [
    { label: 'Volume', name: 'volume' },
    { label: 'Hb', name: 'hb' },
    { label: 'Sterility', name: 'sterility' },
    { label: 'PH Value', name: 'ph_value' },
    { label: 'Leukocyte Reduction', name: 'leukocyte_reduction' },
    { label: 'Anticoagulant Quantity', name: 'anticoagulant_quantity' }
  ],
  'other':[
    { label: 'Volume', name: 'volume' },
  { label: 'HB', name: 'hb' },
  { label: 'Culture Report', name: 'culture_report' },
  { label: 'Gross Appearance', name: 'gross_appearance' },
  { label: 'Anticoagulant Quantity', name: 'anticoagulant_quantity' },
  { label: 'Haemotocrit', name: 'haemotocrit' },
  { label: 'WBC Contamination', name: 'wbc_contamination' },
  { label: 'PTT', name: 'pt' },
  { label: 'PH Value', name: 'ph_value' },
  { label: 'RBC Contamination', name: 'rbc_contamination' },
  { label: 'Sterility', name: 'sterility' },
  { label: 'Swirling of Platelets', name: 'swirling_platelets' },
  { label: 'PTT', name: 'ptt' },
  { label: 'Leukocyte Reduction', name: 'leukocyte_reduction' },
  { label: 'Platelet Count', name: 'platelet_count' },
  { label: 'Fibrinogen', name: 'fibrinogen' },
  { label: 'Platelet Yield', name: 'platelet_yield' },
  { label: 'Factor VIII', name: 'factor_viii' },
  ]
}

const ComponentForm = ({ compData, id, getCompData, editForm }) => {
  const fields = componentFields[compData?.component] || componentFields.other
  const [isLoading, setLoading] = useState(false)
  let editData = {}

  if (compData?.BloodCompQualityEntity?.id) {
    editData = {
      anticoagulant_quantity: compData?.BloodCompQualityEntity?.anticoagulant_quantity || '',
      culture_report: compData?.BloodCompQualityEntity?.culture_report || '',
      factor_viii: compData?.BloodCompQualityEntity?.factor_viii || '',
      fibrinogen: compData?.BloodCompQualityEntity?.fibrinogen || '',
      gross_appearance: compData?.BloodCompQualityEntity?.gross_appearance || '',
      haemotocrit: compData?.BloodCompQualityEntity?.haemotocrit || '',
      hb: compData?.BloodCompQualityEntity?.hb || '',
      leukocyte_reduction: compData?.BloodCompQualityEntity?.leukocyte_reduction || '',
      medical_officer: compData?.BloodCompQualityEntity?.medical_officer || '',
      ph_value: compData?.BloodCompQualityEntity?.ph_value || '',
      platelet_count: compData?.BloodCompQualityEntity?.platelet_count || '',
      platelet_yield: compData?.BloodCompQualityEntity?.platelet_yield || '',
      pt: compData?.BloodCompQualityEntity?.pt || '',
      ptt: compData?.BloodCompQualityEntity?.ptt || '',
      rbc_contamination: compData?.BloodCompQualityEntity?.rbc_contamination || '',
      remark: compData?.BloodCompQualityEntity?.remark || true,
      result: compData?.BloodCompQualityEntity?.result || '',
      sterility: compData?.BloodCompQualityEntity?.sterility || '',
      swirling_platelets: compData?.BloodCompQualityEntity?.swirling_platelets || '',
      verified_by: compData?.BloodCompQualityEntity?.verified_by || '',
      volume: compData?.BloodCompQualityEntity?.volume || '',
      wbc_contamination: compData?.BloodCompQualityEntity?.wbc_contamination || ''
    }
  }

  const schema = yup.object().shape({
    volume: yup.string().optional(),
    gross_appearance: yup.string().optional(),
    anticoagulant_quantity: yup.string().optional(),
    haemotocrit: yup.string().optional(),
    pt: yup.string().optional(),
    ptt: yup.string().optional(),
    sterility: yup.string().optional(),
    fibrinogen: yup.string().optional(),
    factor_viii: yup.string().optional(),
    hb: yup.string().optional(),
    ph_value: yup.string().optional(),
    culture_report: yup.string().optional(),
    leukocyte_reduction: yup.string().optional(),
    wbc_contamination: yup.string().optional(),
    rbc_contamination: yup.string().optional(),
    swirling_of_platelets: yup.string().optional(),
    platelet_count: yup.string().optional(),
    platelet_yield: yup.string().optional(),
    result: yup.string().required('Results are required'),
    verified_by: yup.string().required('Verified By is required'),
    medical_officer: yup.string().required('Medical Officer is required'),
    remark: yup.string().optional()
  })

  const defaultValues = {
    volume:'',
    gross_appearance: '',
    anticoagulant_quantity: '',
    haemotocrit: '',
    pt: '',
    ptt: '',
    sterility: '',
    fibrinogen: '',
    factor_viii: '',
    hb: '',
    ph_value: '',
    culture_report: '',
    leukocyte_reduction: '',
    wbc_contamination: '',
    rbc_contamination: '',
    swirling_platelets: '',
    platelet_count: '',
    platelet_yield: '',
    result: 'true',
    verified_by: '',
    medical_officer: '',
    remark: ''
  }

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    setFocus,
    reset,
    formState: { errors }
  } = methods

  const onSubmit = data => {
    const bodyData = { ...data, blood_log_id: id }
    console.log('data', { data })
    setLoading(true);
    AxiosHelper.post('qc', bodyData)
      .then(response => {
        toast.success('Blood Component Successfully Added!')
        console.log(response.data.data)
        getCompData()
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        toast.error(error || 'Something went wrong on our side')
      })
    setLoading(false)
  }

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])
  console.log(compData?.volume)

  useEffect(() => {
    if(editData){
      reset(editData)
    }else{
      setValue('volume', compData?.volume)
    }
  }, [compData])

  return (
    <>
      <Loader isLoading={isLoading} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className='bloodbank_action mb-8 '>
          {compData?.BloodCompQualityEntity ? <p>Edit Blood Component QC ({compData?.component})</p> : <p>Add Blood Component QC ({compData?.component})</p>}
        </div>
        
          <TableContainer className='min-w-fit'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{color:"#000",fontWeight:600,fontSize:'0.9rem'}}>S.NO</TableCell>
                  <TableCell style={{color:"#000",fontWeight:600,fontSize:'0.9rem'}}>Parameter</TableCell>
                  <TableCell style={{color:"#000",fontWeight:600,fontSize:'0.9rem'}}>Result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field,id)=> (
                  <TableRow key={field.name}>
                    <TableCell style={{color:"#000",fontSize:'0.875rem'}}>
                      {id+1}.
                    </TableCell>
                    <TableCell>
                      <FormLabel style={{color:"#000",fontSize:'0.875rem'}}>{field.label}</FormLabel>
                    </TableCell>
                    <TableCell style={{minWidth:'150px'}}>
                      <RHFTextField name={field.name} size='small' fullWidth  style={{BorderBottom:'2px'}}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={3}>
          <FormDivider title='Results' />
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Results<span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect name='result' size='small' fullWidth sx={inputStyles}>
              <MenuItem value='true'>Pass</MenuItem>
              <MenuItem value='false'>Fail</MenuItem>
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Verified By <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField name='verified_by' size='small' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Medical Officer <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField name='medical_officer' size='small' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12}>
            <FormLabel sx={labelStyles}>Remark</FormLabel>
            <RHFTextField size='small' multiline minRows={2} maxRows={5} fullWidth sx={inputStyles} name='remark' />
          </Grid>
          <Grid item xs={12}>
            <div className='d-flex mb-32' style={{ gap: '10px', marginBottom: '40px' }}>
              <>
                <button type='button' className='btn backBtn '>
                  Cancel
                </button>

                <button type='submit' disabled={isLoading} className='btn gradient-btn'>
                  {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
                </button>
              </>
            </div>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}

export default ComponentForm

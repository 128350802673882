import React, { useState, useMemo, useEffect } from 'react'
import BreadCrumb from '../../common/BreadCrumb'
import PageHeader from '../../common/PageHeader'
import ScrollCard from '../../shared/ScrollCard'
import Loader from '../../shared/Loader'
import ProgressBloodComponent from './ProgressBloodComponent'
import { createColumnHelper } from '@tanstack/react-table'
import ReactTable, { SortingImg } from '../../shared/ReactTable'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import AxiosHelper from '../../../helper/AxiosHelper'
import { QCReportPrint } from '../shared'
import { printAll } from '../../../helper/CommonAPI'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import BloodComponentQCReport from './BloodComponentQCReport'
import { toast } from 'react-toastify'

const BloodComponentValidated = ({ getCompData, compData }) => {
  const handleValid = async qc_id => {
    try {
      const res = await AxiosHelper.put(`qc/${qc_id}`)
      toast.success('QC Validated Successfully!')
      getCompData()
    } catch (error) {
      console.log(error)
    }
  }

  const testData = {
    basicdata: (() => {
      if (compData?.component === 'Packed Red Blood Cells') {
        return [
          { label: 'Blood Bag No.', value: compData?.BloodBagIssueEntity?.bag_number || '-' },
          { label: 'Component', value: compData?.component || '-' },
          { label: 'Volume', value: compData?.BloodCompQualityEntity?.volume || '-' },
          { label: 'HB', value: compData?.BloodCompQualityEntity?.hb || '-' },
          { label: 'Culture Report', value: compData?.BloodCompQualityEntity?.culture_report || '-' },
          { label: 'Gross Appearance', value: compData?.BloodCompQualityEntity?.gross_appearance || '-' },
          { label: 'Haemotocrit', value: compData?.BloodCompQualityEntity?.haemotocrit || '-' },
          { label: 'Anticoagulant Quantity', value: compData?.BloodCompQualityEntity?.anticoagulant_quantity || '-' },
          { label: 'Result', value: compData?.BloodCompQualityEntity?.result === true ? 'Pass' : 'Fail' }
        ]
      } else if (compData?.component === 'Fresh Frozen Plasma') {
        return [
          { label: 'Blood Bag No.', value: compData?.BloodBagIssueEntity?.bag_number || '-' },
          { label: 'Component', value: compData?.component || '-' },
          { label: 'Volume', value: compData?.BloodCompQualityEntity?.volume || '-' },
          { label: 'Gross Appearance', value: compData?.BloodCompQualityEntity?.gross_appearance || '-' },
          { label: 'Culture Report', value: compData?.BloodCompQualityEntity?.culture_report || '-' },
          { label: 'PT', value: compData?.BloodCompQualityEntity?.pt || '-' },
          { label: 'PTT', value: compData?.BloodCompQualityEntity?.ptt || '-' },
          { label: 'Fibrinogen', value: compData?.BloodCompQualityEntity?.fibrinogen || '-' },
          { label: 'Factor VIII', value: compData?.BloodCompQualityEntity?.factor_viii || '-' },
          { label: 'Result', value: compData?.BloodCompQualityEntity?.result === true ? 'Pass' : 'Fail' }
        ]
      } else if (compData?.component === 'PLC') {
        return [
          { label: 'Blood Bag No.', value: compData?.BloodBagIssueEntity?.bag_number || '-' },
          { label: 'Component', value: compData?.component || '-' },
          { label: 'Volume', value: compData?.BloodCompQualityEntity?.volume || '-' },
          { label: 'Gross Appearance', value: compData?.BloodCompQualityEntity?.gross_appearance || '-' },
          { label: 'WBC Contamination', value: compData?.BloodCompQualityEntity?.wbc_contamination || '-' },
          { label: 'RBC Contamination', value: compData?.BloodCompQualityEntity?.rbc_contamination || '-' },
          { label: 'Swirling of Platelets', value: compData?.BloodCompQualityEntity?.swirling_platelets || '-' },
          { label: 'Platelet Count', value: compData?.BloodCompQualityEntity?.platelet_count || '-' },
          { label: 'Platelet Yield', value: compData?.BloodCompQualityEntity?.platelet_yield || '-' },
          { label: 'PH Value', value: compData?.BloodCompQualityEntity?.ph_value || '-' },
          { label: 'Result', value: compData?.BloodCompQualityEntity?.result === true ? 'Pass' : 'Fail' }
        ]
      } else if (compData?.component === 'Whole Blood (CPD)') {
        return [
          { label: 'Blood Bag No.', value: compData?.BloodBagIssueEntity?.bag_number || '-' },
          { label: 'Component', value: compData?.component || '-' },
          { label: 'Volume', value: compData?.BloodCompQualityEntity?.volume || '-' },
          { label: 'Hb', value: compData?.BloodCompQualityEntity?.hb || '-' },
          { label: 'Sterility', value: compData?.BloodCompQualityEntity?.sterility || '-' },
          { label: 'pH Value', value: compData?.BloodCompQualityEntity?.ph_value || '-' },
          { label: 'Leukocyte Reduction', value: compData?.BloodCompQualityEntity?.leukocyte_reduction || '-' },
          { label: 'Anticoagulant Quantity', value: compData?.BloodCompQualityEntity?.anticoagulant_quantity || '-' },
          { label: 'Result', value: compData?.BloodCompQualityEntity?.result === true ? 'Pass' : 'Fail' }
        ]
      } else {
        return [
          { label: 'Blood Bag No.', value: compData?.BloodBagIssueEntity?.bag_number || '-' },
          { label: 'Component', value: compData?.component || '-' },
          { label: 'Volume', value: compData?.BloodCompQualityEntity?.volume || '-' },
          { label: 'HB', value: compData?.BloodCompQualityEntity?.hb || '-' },
          { label: 'Culture Report', value: compData?.BloodCompQualityEntity?.culture_report || '-' },
          { label: 'Gross Appearance', value: compData?.BloodCompQualityEntity?.gross_appearance || '-' },
          { label: 'Anticoagulant Quantity', value: compData?.BloodCompQualityEntity?.anticoagulant_quantity || '-' },
          { label: 'Haemotocrit', value: compData?.BloodCompQualityEntity?.haemotocrit || '-' },
          { label: 'WBC Contamination', value: compData?.BloodCompQualityEntity?.wbc_contamination || '-' },
          { label: 'PTT', value: compData?.BloodCompQualityEntity?.pt || '-' },
          { label: 'PH Value', value: compData?.BloodCompQualityEntity?.ph_value || '-' },
          { label: 'RBC Contamination', value: compData?.BloodCompQualityEntity?.rbc_contamination || '-' },
          { label: 'Sterility', value: compData?.BloodCompQualityEntity?.sterility || '-' },
          { label: 'Swirling of Platelets', value: compData?.BloodCompQualityEntity?.swirling_platelets || '-' },
          { label: 'PTT', value: compData?.BloodCompQualityEntity?.ptt || '-' },
          { label: 'Leukocyte Reduction', value: compData?.BloodCompQualityEntity?.leukocyte_reduction || '-' },
          { label: 'Platelet Count', value: compData?.BloodCompQualityEntity?.platelet_count || '-' },
          { label: 'Fibrinogen', value: compData?.BloodCompQualityEntity?.fibrinogen || '-' },
          { label: 'Platelet Yield', value: compData?.BloodCompQualityEntity?.platelet_yield || '-' },
          { label: 'Factor VIII', value: compData?.BloodCompQualityEntity?.factor_viii || '-' },
          { label: 'Result', value: compData?.BloodCompQualityEntity?.result === true ? 'Pass' : 'Fail' }
        ]
      }
    })()
  }
  return (
    <>
      <div className='bloodbank_action  '>
        <p>QC Validation Details</p>
      </div>
      <section className='detail-section'>
        <div className='detail-header'>{testData.title}</div>
        <div className='detail-grid'>
          {testData?.basicdata.map((item, idx) => (
            <div className='grid-item' key={idx}>
              <p className='item-header'>{item['label']}</p>
              <p className='item-value text-capitalize'>{item['value'] || 'NA'}</p>
            </div>
          ))}
        </div>
      </section>
      <div className='d-flex items-center' style={{ gap: '10px' }}>
        <button
          type='button'
          className={` ${compData?.BloodCompQualityEntity?.id ? 'btn gradient-btn mx-auto mt-5' : ' mx-auto mt-5 cursor-not-allowed border-0	bg-red-600 outline-0	 py-2 px-3 rounded-lg	 min-w-24 '}`}
          onClick={() => {
            if (compData?.BloodCompQualityEntity?.id) {
              handleValid(compData?.BloodCompQualityEntity?.id)
            }
          }}
        >
          Validate Result
        </button>
      </div>
    </>
  )
}

export default BloodComponentValidated

import React, { useState } from 'react'
import { toast } from 'react-toastify'
import AxiosHelper from '../../../helper/AxiosHelper'
import { useHistory, useParams } from 'react-router-dom'
import FormProvider from '../../shared/FormProvider'
import Loader from '../../shared/Loader'

import { Checkbox, CircularProgress, FormHelperText, Grid, TextField } from '@mui/material'
import { useEffect } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { checkPermission } from '../../middleware/auth'

const radioStyles = {
  ' &.Mui-checked': {
    color: '#214F8A'
  }
}

const items = [
  {
    itemId: 1,
    error: false,
    selected: false,
    item: 'BP apparatus'
  },
  {
    itemId: 2,
    error: false,
    selected: false,
    item: 'Stethoscope'
  },
  {
    itemId: 3,
    error: false,
    selected: false,
    item: 'Blood bags(single, double, triple)'
  },
  {
    itemId: 4,
    error: false,
    selected: false,
    item: 'Donor questionnaire'
  },
  {
    itemId: 5,
    error: false,
    selected: false,
    item: 'Weighing device for donors'
  },
  {
    itemId: 6,
    error: false,
    selected: false,
    item: 'Artery forceps, scissors'
  },
  {
    itemId: 7,
    error: false,
    selected: false,
    item: 'Needle destroyer(wherever necessary)'
  },
  {
    itemId: 8,
    error: false,
    selected: false,
    item: 'Stripper for blood tubing'
  },
  {
    itemId: 9,
    error: false,
    selected: false,
    item: 'Bed sheets, blankets/mattress'
  },
  {
    itemId: 10,
    error: false,
    selected: false,
    item: 'Lancets, swab stick/tooth picks'
  },
  {
    itemId: 11,
    error: false,
    selected: false,
    item: 'Glass slitemIdes'
  },
  {
    itemId: 12,
    error: false,
    selected: false,
    item: 'Quantitative method for Hb estimation'
  },
  {
    itemId: 13,
    error: false,
    selected: false,
    item: 'Test tube (big) and 12x100mm(small)'
  },
  {
    itemId: 14,
    error: false,
    selected: false,
    item: 'Test tube stand'
  },
  {
    itemId: 15,
    error: false,
    selected: false,
    item: 'Anti-A, Anti-B Anti-D Antisera'
  },
  {
    itemId: 16,
    error: false,
    selected: false,
    item: 'Test tube sealer film'
  },
  {
    itemId: 17,
    error: false,
    selected: false,
    item: 'Medicated adhesive tape'
  },
  {
    itemId: 18,
    error: false,
    selected: false,
    item: 'Plastic waste basket'
  },
  {
    itemId: 19,
    error: false,
    selected: false,
    item: 'Emergency medical kit'
  },
  {
    itemId: 20,
    error: false,
    selected: false,
    item: 'Insulated blood bag containers'
  },
  {
    itemId: 21,
    error: false,
    selected: false,
    item: 'Dielectric sealer or portable tube sealer'
  },
  {
    itemId: 22,
    error: false,
    selected: false,
    item: 'Weighing device for blood bags'
  },

  {
    itemId: 23,
    error: false,
    selected: false,
    item: 'Donor cards and refreshment for donors'
  },
  {
    itemId: 24,
    error: false,
    selected: false,
    item: 'Storage Facilities of blood units '
  }
]

const ItemForm = ({
  state = 'Add',
  setReturnTab,
  returnTab,
  currentItems,
  setEditTab,
  editId,
  camp,
  addFromView,
  setItemStatus,
  getCampDetails
}) => {
  const [isLoading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)
  const [list, setList] = useState([])
  const [allItems, setAllItems] = useState([])

  const history = useHistory()
  let { id } = useParams()

  const handleAddStaff = bodyData => {
    let url
    if (state === 'Edit' || state === 'Return') {
      url = returnTab ? 'item-stock/return-item' : `item-stock/update-camp-items`
      // url = `camp/edit-item/${id || editId}`;

      AxiosHelper.post(url, bodyData)
        .then(response => {
          setLoading(false)
          toast.success(`Items Updated Successfully`)
          if (editId) {
            getCampDetails()
          }
          if (camp) {
            setEditTab(false)
          } else {
            history.push(`/blood-camp-management/items/${id || editId}`)
          }
        })
        .catch(error => {
          console.log(error)
          toast.error(error || 'Update Store Item Failed!')
          setLoading(false)
        })
      return
    } else {
      url = 'item-stock/assign-item'
      // url = "camp/add-item";
      setLoading(true)
      AxiosHelper.post(url, bodyData)
        .then(response => {
          setLoading(false)
          toast.success(`Store Item ${state === 'Edit' ? 'Updated' : 'Added'} Successfully`)
          if (addFromView) {
            getCampDetails()
          } else {
            history.push(`/blood-camp-management/items/${id || editId}`)
          }
        })
        .catch(error => {
          console.log(error)
          toast.error('Add Store Item Failed!')
          setLoading(false)
        })
    }
  }

  const getAllItems = () => {
    setLoading(true)
    AxiosHelper.get(`item-stock/list`)
      .then(response => {
        let res = response.data.data
        setAllItems(res)
        // setPageData(res?.pagination);
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    if (allItems) {
      let itemarr = []
      // currentItems
      if (returnTab) {
        for (let i = 0; i < currentItems?.length; i++) {
          let itm = {
            error: false,
            selected: false,
            id: currentItems[i]?.id,
            manufacture: currentItems[i]?.ItemStockEntity?.ManufacturerEntity?.name,
            supplier: currentItems[i]?.ItemStockEntity?.SupplierEntity?.name,
            name: currentItems[i]?.ItemStockEntity?.name,
            totalQuantity: currentItems[i]?.quantity,
            quantity: currentItems[i]?.return_qty || ''
          }

          itemarr.push(itm)
        }
      } else {
        for (let i = 0; i < allItems?.length; i++) {
          let itm
          let findCurrent = currentItems?.find(item => item?.ItemStockEntity?.name == allItems[i]?.name)

          if (state === 'Edit' && findCurrent) {
            itm = {
              ...allItems[i],
              error: false,
              selected: true,
              manufacture: findCurrent?.ItemStockEntity?.ManufacturerEntity?.name,
              supplier: findCurrent?.ItemStockEntity?.SupplierEntity?.name,
              name: findCurrent?.ItemStockEntity?.name,
              quantity: findCurrent?.quantity,
              totalQuantity: Number(allItems[i]?.totalQuantity) + (findCurrent?.used_qty || findCurrent?.quantity)
            }
          } else {
            itm = {
              ...allItems[i],
              selected: false,
              quantity: '',
              manufacture: allItems[i]?.ManufacturerEntity?.name,
              supplier: allItems[i]?.SupplierEntity?.name,
              error: false
            }
          }

          itemarr.push(itm)
        }
      }

      itemarr.sort((a, b) => b.selected - a.selected)

      setList(itemarr)
    }
  }, [allItems, currentItems])

  const handleChangeAll = (e, index) => {
    let newValue = list?.map((data, i) => {
      if (i === index) {
        let obj = {
          ...data,
          [e.target.name]: e.target.name === 'selected' ? e.target.checked : e.target.value
        }
        if (e.target.name === 'selected' && !e.target.checked) {
          obj.quantity = ''
        }

        return obj
      }

      return data
    })
    setList(newValue)
  }

  const handleSelect = e => {
    if (e.target.checked) {
      setSelectedItem(true)
      let newValue = list?.map((data, i) => {
        let obj = {
          ...data,
          selected: true
        }
        return obj
      })
      setList(newValue)
    } else {
      setSelectedItem(false)
      let newValue = list?.map((data, i) => {
        let obj = {
          ...data,
          selected: false
        }
        return obj
      })
      setList(newValue)
    }
  }

  const handleValidations = e => {
    e.preventDefault()
    let newArr = []
    let errorArr = []
    let valied = true
    let count = 0
    for (let i of list) {
      errorArr.push({
        ...i,
        error:
          i.selected && (i.quantity === undefined || i.quantity?.length === 0)
            ? true
            : false || i.quantity === ''
              ? true
              : false
      })

      // camp_id: id || editId,
      // selected: i.selected,
      if (i.selected) {
        if (returnTab) {
          newArr.push({
            id: i.id,
            returnQty: Number(i.quantity)
          })
        } else {
          newArr.push({
            name: i.name,
            quantity: i.quantity
          })
        }
      }
      if (i.selected && (i.quantity === undefined ? true : false || i.quantity === '' ? true : false)) {
        if (
          !(i?.item === 'Donor cards and refreshment for donors' || i?.item === 'Storage Facilities of blood units ')
        ) {
          valied = false
        }
      }
      if (i.selected) {
        count = count + 1
      }
    }
    setList(errorArr)

    if (valied && count !== 0) {
      let bodyData = {
        items: newArr,
        camp_id: id || editId
      }

      handleAddStaff(bodyData)
    }
  }

  useEffect(() => {
    getAllItems()
  }, [])

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className='my-auto'>
        <form onSubmit={handleValidations}>
          <div className='bloodbank_action'>
            <p>{state} Store Item</p>
          </div>

          <div>
            <div className='table-responsive mt-2'>
              <ScrollBar>
                <table className='table  table-hover'>
                  <thead>
                    <tr className='table_header'>
                      <th>
                        <Checkbox
                          id='radio'
                          checked={selectedItem}
                          onKeydown={handleSelect}
                          onChange={handleSelect}
                          name='selectedAll'
                          sx={radioStyles}
                        />
                        Select items
                      </th>
                      <th>Manufacturer</th>
                      <th>Supplier</th>
                      {returnTab ? null : <th>Required in Camp</th>}
                      <th>{returnTab ? 'Quantity' : 'Available Quantity'}</th>
                      <th> {returnTab ? 'Return Quantity' : 'Quantity'}</th>
                    </tr>
                  </thead>
                  <tbody className='inventory_tab'>
                    {list?.length > 0
                      ? list?.map((ele, idx) => (
                          <tr key={idx}>
                            <td>
                              <Checkbox
                                id={`radio${idx}`}
                                checked={ele?.selected}
                                value={ele?.selected}
                                sx={radioStyles}
                                onKeydown={e => handleChangeAll(e, idx)}
                                onChange={e => handleChangeAll(e, idx)}
                                name='selected'
                              />

                              {ele?.name}
                            </td>
                            <td>{ele?.manufacture}</td>
                            <td>{ele?.supplier}</td>
                            {returnTab ? null : <td>{ele?.selected ? 'Yes' : 'No'}</td>}
                            <td>{ele?.totalQuantity}</td>
                            <td>
                              {ele?.selected &&
                              ele.item !== 'Storage Facilities of blood units ' &&
                              ele.item !== 'Donor cards and refreshment for donors' ? (
                                <>
                                  <TextField
                                    sx={{ maxWidth: 130 }}
                                    name='quantity'
                                    type='number'
                                    value={ele?.quantity}
                                    onChange={e => {
                                      if (!e.target.value) {
                                        handleChangeAll(e, idx)
                                        return
                                      }
                                      if (
                                        Number(e.target.value) > 0 &&
                                        Number(e.target.value) <= Number(ele?.totalQuantity)
                                      ) {
                                        handleChangeAll(e, idx)
                                      } else {
                                        toast.error(
                                          `Please enter a valid quantity between 1 and ${ele?.totalQuantity}`,
                                          { position: 'top-right' }
                                        )
                                      }
                                    }}
                                    size='small'
                                    error={ele?.error}
                                  />
                                  {ele?.error ? (
                                    <FormHelperText sx={{ color: 'error.main' }}>Please fill this field</FormHelperText>
                                  ) : null}
                                </>
                              ) : ele?.selected ? (
                                ele?.quantity
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </ScrollBar>
            </div>
            {checkPermission('UPDATE_CAMP') === true ? (
              <div className='btn-container mt-2'>
                <button
                  type='button'
                  className='btn backBtn'
                  onClick={() => {
                    if (state === 'Return' || state === 'Edit') {
                      setReturnTab(false)
                      setEditTab(false)
                      return
                    }
                    history.push('/blood-camp-management')
                  }}
                >
                  Cancel
                </button>
                <button disabled={isLoading} type='submit' className='btn gradient-btn '>
                  {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
                </button>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  )
}

export default ItemForm

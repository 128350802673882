import { CircularProgress, FormLabel, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormDivider } from './AddFacility'
import moment from 'moment'
import { labelStyles } from '../../certification/EditCertificate'
import AxiosHelper from '../../../helper/AxiosHelper'
import { toast } from 'react-toastify'
import Loader from '../../shared/Loader'
import NoDataFound from '../../common/NoDataFound'
import { useHistory } from 'react-router-dom'
import { checkPermission } from '../../middleware/auth'
const ViewFacility = ({ data, pageLoading, id }) => {
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)
  const [facilitystatus, setFacilityStatus] = useState(false)
  const history = useHistory()
  let allFacility = data?.CampFacilityEntity?.facility ? JSON.parse(data?.CampFacilityEntity?.facility) : []

  console.log('allFacility', allFacility)

  const onSubmit = async () => {
    setLoading(true)
    AxiosHelper.put(`camp/update-facility-comment/${data?.CampFacilityEntity?.id}`, {
      comment: comment
    })
      .then(response => {
        setLoading(false)
        toast.success('Facilities Updated Successfully')
      })
      .catch(error => {
        console.log(error)
        toast.error('Facilities Updating Failed!')
        setLoading(false)
      })
  }

  useEffect(() => {
    if (data?.CampFacilityEntity?.id > 0) {
      setFacilityStatus(true)
    } else {
      setFacilityStatus(false)
    }
  }, [data])

  useEffect(() => {
    if (data?.CampFacilityEntity?.comment) {
      setComment(data?.CampFacilityEntity?.comment)
    }
  }, [data?.CampFacilityEntity])

  return (
    <div>
      {facilitystatus ? (
        <Grid container spacing={3}>
          <FormDivider title='General Information' />

          <Grid item xs={12} sm={6} md={4}>
            <FormLabel sx={labelStyles}>Blood Camp Name</FormLabel>
            <Typography>{data?.camp_name || '-'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormLabel sx={labelStyles}>Organizer Name</FormLabel>
            <Typography>{data?.CampFacilityEntity?.organizer_name || '-'}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormLabel sx={labelStyles}> Camp Schedule Date</FormLabel>
            <Typography>
              {data?.CampFacilityEntity?.date ? moment(data?.CampFacilityEntity?.date).format('DD/MM/YYYY') : '-'}
            </Typography>
          </Grid>

          <FormDivider title='Facilities' />
          <Grid item xs={12}>
            {allFacility?.length > 0 ? (
              <List
                sx={{
                  width: '100%',
                  px: 1,
                  bgcolor: 'background.paper'
                }}
              >
                {allFacility?.map((value, index) => {
                  const labelId = `checkbox-list-label-${index}`

                  return (
                    <ListItem sx={{ backgroundColor: '#F5F5F5', mb: 2 }} key={index}>
                      <ListItemText
                        sx={{
                          '&.MuiListItemText-root': {
                            fontWeight: 600,
                            fontSize: '1rem'
                          }
                        }}
                        id={labelId}
                        // primary={value}
                      >
                        <span style={{ fontWeight: '600' }}>{value?.substring(0, value?.indexOf(':'))} : </span>
                        {value?.substring(value?.indexOf(':') + 1)}.
                      </ListItemText>
                    </ListItem>
                  )
                })}
              </List>
            ) : (
              <Typography sx={{ py: 2 }}>No Facilities Available</Typography>
            )}
          </Grid>
          <FormDivider title='Remark' />
          <Grid item xs={12}>
            <TextField
              sx={{
                background: '#f5f5f5',
                border: '1px solid #d1d5db',
                borderRadius: '0.5rem',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: 0
                },
                '&:focus-within fieldset, &:focus-visible fieldset': {
                  borderWidth: '1px!important',
                  borderRadius: '0.5rem'
                }
              }}
              value={comment}
              onChange={e => setComment(e.target.value)}
              fullWidth
              multiline
              minRows={3}
              placeholder='Add Comment here...'
            />
          </Grid>
          {checkPermission('UPDATE_CAMP') && (
            <Grid item xs={12}>
              <button
                disabled={loading}
                onClick={onSubmit}
                className='btn btn-gradient-primary rounded-lg responsiveBtn-create-donor'
              >
                {loading ? <CircularProgress size={30} color='inherit' /> : 'Add Comment'}
              </button>
            </Grid>
          )}
        </Grid>
      ) : null}
      {!facilitystatus ? (
        <div className='blood-requisition-no-data'>
          <NoDataFound title={' '} />
          {checkPermission('UPDATE_CAMP') && (
            <div className=' empty-requisition-msg '>
              <button
                className='btn btn-gray add-requisition-btn-table'
                onClick={() => {
                  history.push(`/blood-camp-management/facility/add/${id}`)
                }}
              >
                {' '}
                Add Facility
              </button>

              <div className='add-requisition-msg'>
                <p>Click on Add Facility button to add Facilities</p>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ViewFacility

import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import AxiosHelper from '../../../helper/AxiosHelper'
import CardHeading, { endOfMonthDefault, startOfMonthDefault } from './cardHeading'
import { DashboardNoDat } from './WasteChart'
import { checkTenantId } from '../../../helper/CommonAPI'

const BlodgroupWiseChart = () => {
  const [apiData, setApiData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [modalData, setModalData] = useState([])
  const [startDate, setStartDate] = useState(startOfMonthDefault)
  const [endDate, setEndDate] = useState(endOfMonthDefault)
  const [updatedDate, setUpdatedDate] = useState(null)
  const [chartData, setChartData] = useState({
    series: [15, 8, 8, 7, 15, 20, 15],
    options: {
      chart: {
        type: 'donut'
      },
      stroke: { width: 0 },
      labels: ['O+Ve', 'O-Ve', 'B-Ve', 'B+Ve', 'A-Ve', 'A+Ve', 'AB+Ve', 'AB-Ve'],
      colors: ['#13BCAB', '#376196', '#881A1A', '#9B8126', '#783611', '#a2b861', '#d24e4e', '#C450D7'],
      // stroke: { width: 0 },
      // labels: ['AB+ve', 'B-ve', 'A-ve', 'O-ve', 'B+ve', 'O+ve', 'A+ve'],
      // colors: ['#13BCAB', '#376196', '#881A1A', '#9B8126', '#A74B4B', '#8BA32E', '#C05C5C'],
      dataLabels: {
        enabled: true,
        formatter: (val, opts) => {
          return opts.w.globals.series[opts.seriesIndex]
        }
      },
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'Helvetica, Arial',
        labels: {
          colors: ['#000'],
          useSeriesColors: false
        }
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '60%', // Increase the width of the donut by decreasing this value
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total Units',
                fontSize: '20px',
                fontWeight: 600,
                color: '#000',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 768, // small screen breakpoint
          options: {
            chart: {
              height: 400, // Increase height on small screens
            },
            legend: {
              position: 'bottom', // Move legend below the chart
              horizontalAlign: 'center',
            },
            plotOptions: {
              pie: {
                donut: {
                  size: '60%', // Increase the donut size on small screens
                },
              },
            },
          },
        },
      ],
    }
  })

  const getDashboardInfo = () => {
    setLoading(true)
    const URL=checkTenantId(`dashboard/blood-collection?startDate=${startDate || ''}&endDate=${endDate || ''}`)
    AxiosHelper.get(URL)
      .then(resp => {
        if (resp?.data?.data) {
          let resData = resp?.data?.data
          setUpdatedDate(new Date())
          if (resData?.bloodGroupWiseCount?.length) {
            const bloodGroupLabels = resData?.bloodGroupWiseCount?.map(item => item.blood_group)
            const unitsArray = resData?.bloodGroupWiseCount?.map(item => item?.unit || 0)
            setApiData(resData)
            setChartData({
              ...chartData,
              series: unitsArray,
              options: {
                ...chartData?.options,
                labels: bloodGroupLabels
              }
            })

            let newArray = bloodGroupLabels?.map((item, index) => {
              return {
                label: item,
                value: unitsArray[index] || '0'
              }
            })
            setModalData(newArray)
          } else {
            setApiData(null)
          }
        }
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    getDashboardInfo()
  }, [startDate, endDate])

  return (
    <>
      <CardHeading
      path='/blood-stock'
        title='Total blood group wise collected'
        size='sm'
        count={apiData?.totalBloodCount || 0}
        refreshDataApi={getDashboardInfo}
        loading={loading}
        setStartDateCustom={setStartDate}
        setEndDateCustom={setEndDate}
        modalData={modalData}
        updatedDate={updatedDate}
      />
      {apiData ? (
        <ReactApexChart options={chartData.options} series={chartData.series} type='donut' height={350} />
      ) : (
        <DashboardNoDat />
      )}
    </>
  )
}

export default BlodgroupWiseChart

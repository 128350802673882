import React, { useEffect, useRef, useState } from 'react'
import BreadCrumb from '../../common/BreadCrumb'
import PageHeader from '../../common/PageHeader'
import { useParams, useHistory } from 'react-router-dom'
import AxiosHelper from '../../../helper/AxiosHelper'
import Qrcode from './Qrcode'
import AddBloodCamp from '../addBloodCamp/AddBloodCamp'
import Loader from '../../shared/Loader'
import moment from 'moment'
import EditCamp from './EditCamp'
import { checkPermission, getEntityInfo } from '../../middleware/auth'
import StaffTable from '../addstaff/StaffTable'
import ViewFacility from '../addfacilty/ViewFacility'
import BloodCampReport from '../BloodCampReport'
import PostReport from '../postreport/PostReport'
import { downLoadFiles } from '../../middleware/auth'
import {
  Box,
  StepContent,
  Step,
  StepLabel,
  Stepper,
  Collapse,
  Alert,
  IconButton,
  Grid,
  Tooltip,
  Menu,
  ListItemIcon,
  MenuItem
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ItemTable from '../addItems/ItemTable'
import ScrollCard from '../../shared/ScrollCard'
import PreReport from '../pre-report'
import ProfileProgress from '../../shared/ProfileProgress'
import ItemForm from '../addItems/ItemForm'
import StaffForm from '../addstaff/StaffForm'
import AddFacility from '../addfacilty/AddFacility'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../AppContext'
import { checkTenantId } from '../../../helper/CommonAPI'
import { DocumentScanner, Download, PictureAsPdf } from '@mui/icons-material'
import { active } from '../../blood-requisition/Filters'
import { ReportPreview } from '../../common/report-preview'

export const progressdone = () => {
  return <img src={'/assets/images/status/Done.svg'} alt='' />
}

export const progresspending = () => {
  return <img src={'/assets/images/status/Pending.svg'} alt='' />
}

export const activeProgress = {
  background: '#F2F9F7',
  cursor: 'pointer'
}
export const normalProgress = {
  paddingBottom: '0',
  cursor: 'pointer'
}

const ViewCamp = () => {
  const [currentTab, setCurrentTab] = useState('campinfo')
  const [editCamp, setEditCamp] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [staffStatus, setStaffStatus] = useState(false)
  const [itemStatus, setItemStatus] = useState(false)
  const [isOldItem, setIsOldItem] = useState(false)
  const [returnItemStatus, setReturnItemStatus] = useState(false)
  const [facilityStatus, setFacilityStatus] = useState(false)
  const [certificateStatus, setCertificateStatus] = useState(false)
  const [totalStatus, setTotalStatus] = useState(0)
  const [isEditable, setIsEditable] = useState(false)
  const [campRegStatus, setRegCampStatus] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [errorMsg, setErrorMsg] = useState('')
  const history = useHistory()
  const [data, setData] = useState([])
  const today = new Date()
  const { id, tab } = useParams()
  const { setActiveTutorial } = useAppContext()
  const ref = useRef(null)
  const getCampDetails = () => {
    setLoading(true)
    let url = `camp/${id}`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        setLoading(false)
        setData(response.data.data)
        if (response.data.data?.registered_donor || response.data.data?.inward_bag) {
          console.log("oKK")
          setIsEditable(true)
        } else {
          setIsEditable(false)
        }
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getCampDetails()
  }, [id])

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab)
      if (tab === 'certificate') {
        history.push(`/certification/camp/${id}`)
      }
    }
  }, [tab])

  // useEffect(() => {
  //   if (data) {
  //     getEventStatus(data?.camp_schedule_date, data?.camp_ending_time)
  //   }
  // }, [data])

  const campOrganizerData = [
    { label: 'Camp Organised For', ItemValue: data?.camp_type || 'NA' },
    { label: 'Camp Name', ItemValue: data?.camp_name || 'NA' },
    {
      label: 'Organization Name',
      ItemValue: data?.OrganizationEntity?.name || 'NA'
    },
    { label: 'Organizer Name', ItemValue: data?.organizer_name || 'NA' },
    {
      label: 'Organizer Designation',
      ItemValue: data?.organizer_designation || 'NA'
    },
    { label: 'Organizer Mobile', ItemValue: data?.organizer_mobile || 'NA' },
    { label: 'Organizer Email', ItemValue: data?.organizer_email || 'NA' },
    {
      label: 'Organizer DOB',
      ItemValue: data?.organizer_dob ? moment(data?.organizer_dob).format('DD-MMM-YYYY') : 'NA'
    },
    { label: 'Organization Type', ItemValue: data?.organizer_type || 'NA' }
  ]

  // const getEventStatus = (eventdate, eventtime) => {
  //   console.log(eventdate + eventtime)
  //   const date1 = moment(eventdate).format('YYYY-MM-DD') + 'T' + eventtime + 'Z'
  //   const date2 = moment(today).format('YYYY-MM-DD') + 'T' + moment(today).format('HH:MM') + 'Z'
  //   if (moment(date1).isAfter(date2)) {
  //     setIsEditable(true)
  //   } else {
  //     setIsEditable(false)
  //   }
  // }
  const campdetails = [
    {
      label: 'Camp Schedule Date',
      ItemValue: moment(data?.camp_schedule_date).format('DD-MMM-YYYY') || 'NA'
    },
    {
      label: 'Camp Starting Timing',
      ItemValue: moment(data?.camp_start_timing, ['hh:mm']).format('hh:mm A') || 'NA'
    },
    {
      label: 'Camp Ending Timing',
      ItemValue: moment(data?.camp_ending_time, ['hh:mm']).format('hh:mm A') || 'NA'
    },
    { label: 'State', ItemValue: data?.state || 'NA' },
    { label: 'District', ItemValue: data?.district || 'NA' },
    { label: 'City/Village', ItemValue: data?.city || 'NA' },
    { label: 'Address', ItemValue: data?.address || 'NA' },
    { label: 'Pin Code', ItemValue: data?.pincode || 'NA' }
  ]

  const campotherDetails = [
    { label: 'PRO Name', ItemValue: data?.pro_name || 'NA' },
    { label: 'PRO Contact Number', ItemValue: data?.pro_contact || 'NA' },
    {
      label: 'Tentative Blood Unit Collection Quantity',
      ItemValue: data?.expected_unit_collection || 'NA'
    },
    { label: 'Remark', ItemValue: data?.remark || 'NA' }
  ]

  const handleCancel = () => {
    setEditCamp(false)
    getCampDetails()
  }

  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    },
    {
      path: '/blood-camp-management',
      label: 'Blood Camp Management'
    },
    {
      path: '/view',
      label: 'Camp Name - ' + data?.camp_name
    }
  ]

  function checkReturnOrUsed(item) {
    return item?.return_qty > 0 || item?.used_qty > 0
  }

  useEffect(() => {
    let progress = 0
    if (data) {
      if (data?.id) {
        setRegCampStatus(true)
        progress = progress + 20
      }
      if (data?.CampStaffEntities && data?.CampStaffEntities?.length > 0) {
        setStaffStatus(true)
        progress = progress + 20
      }
      if (data?.CampFacilityEntity) {
        setFacilityStatus(true)
        progress = progress + 20
      }
      if (data?.ItemCampEntities && data?.ItemCampEntities.length > 0) {
        setItemStatus(true)
        progress = progress + 20
      }
      if (data?.CampItemEntities && data?.CampItemEntities.length > 0) {
        setItemStatus(true)
        setIsOldItem(true)
        progress = progress + 20
      }
      if (data?.certificate) {
        setCertificateStatus(true)
        progress = progress + 20
      }
      if (data?.ItemCampEntities && data?.ItemCampEntities?.some(checkReturnOrUsed)) {
        setReturnItemStatus(true)
        // progress = progress + 20;
      }
      setTotalStatus(progress)
    }
  }, [data])

  useEffect(() => {
    setActiveTutorial('manageCamp')

    return () => {
      setActiveTutorial('')
    }
  }, [])

  const handleScroll = () => {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }, 500)
    // ref.current.scrollTo({
    //   top: 100,
    //   left: 0,
    //   behavior: "smooth",
    // });
  }

  const handleQrRequest = () => {
    if (!isEditable) {
      setErrorMsg('QR Code Is Available Only Before The Camp Scheduled Date')
      setErrorShow(true)
    } else {
      setCurrentTab('qr')
      handleScroll()
    }
  }

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute}>
        <PageHeader title='Camp Details' />
      </BreadCrumb>

      <Loader isLoading={isLoading} />

      <ScrollCard>
        <div className='profile-section-item profile-header'>
          <span className='profile-header-heading text-capitalize'>{data?.camp_name}</span>
        </div>

        <div className='mb-3'>
          <Collapse in={errorShow} sx={{ mt: 2 }}>
            <Alert
              severity='error'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setErrorShow(false)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              {errorMsg}
            </Alert>
          </Collapse>
          <div className='progress-div-main mt-2'>
            <div className='profile-image-section'>
              <ProfileProgress value={`${totalStatus}%`}>
                <h6
                  style={{
                    color: '#A6B9D0',
                    fontSize: '1.2rem',
                    fontWeight: '500',
                    textAlign: 'center'
                  }}
                >
                  {facilityStatus && staffStatus && itemStatus ? (
                    <>{isEditable ? 'Published' : 'Done'}</>
                  ) : (
                    'Pending to publish'
                  )}
                </h6>
              </ProfileProgress>
            </div>
            <div className='progress-container'>
              <div className='progress-main-content-div '>
                <Grid container spacing={3}>
                  {/* <div className="progress-headings-content"> */}
                  <Grid item xs={12} sm={6} md={3}>
                    <div className='progress-lable'>Camp Organized For</div>
                    <div className='progress-value mt-1'>{data?.camp_type || 'NA'}</div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className='progress-lable'>Schedule Date & Time</div>
                    <div className='progress-value mt-1'>
                      {data?.camp_schedule_date ? moment(data?.camp_schedule_date).format('DD-MMM-YYYY') : 'NA'}&{' '}
                      {data?.camp_start_timing || '-'} - {data?.camp_ending_time || '-'}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <div className='progress-lable'>Organization Name</div>
                    <div className='progress-value mt-1'>{data?.OrganizationEntity?.name || 'NA'}</div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <div
                      style={{
                        textWrap: 'nowrap'
                      }}
                      className='progress-lable'
                    >
                      Inward Bags / Reg. Donors
                    </div>
                    <div className='progress-value mt-1'>{`${data?.inward_bag || 0} / ${data?.registered_donor || 0}`}</div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <div className='progress-lable'>Camp QR</div>
                    <div className='progress-value mt-1 div-links' onClick={handleQrRequest}>
                      QR Code
                    </div>
                  </Grid>
                  {/* </Grid> */}
                  {/* <div className="progress-subdiv mt-2"> */}
                  <Grid item xs={12} sm={6}>
                    <div className='progress-lable'>
                      Organizer Info
                      <div className='d-flex flex-wrap progress-value mt-1'>
                        {data?.organizer_name && (
                          <div className='progress-organizer-details'>
                            <img
                              src={'/assets/images/contact/user.svg'}
                              style={{ width: '20px', height: '20px' }}
                              alt='user'
                            />
                            {data?.organizer_name || 'NA'}({data?.organizer_designation || 'NA'})
                          </div>
                        )}

                        {data?.organizer_mobile && (
                          <div className='progress-organizer-details'>
                            <img
                              src={'/assets/images/contact/phone-call.svg'}
                              style={{ width: '20px', height: '20px' }}
                              alt='user'
                            />
                            {data?.organizer_mobile || 'NA'}
                          </div>
                        )}

                        {data?.organizer_email && (
                          <div className='progress-organizer-details'>
                            <img
                              src={'/assets/images/contact/mail.svg'}
                              style={{ width: '20px', height: '20px' }}
                              alt='user'
                            />
                            {data?.organizer_email || 'NA'}
                          </div>
                        )}
                        {/* {data?.organizer_dob && <div className="progress-organizer-details">
                                <img src={"/assets/images/contact/calendar (1).svg"}
                                  style={{ width: "20px", height: "20px" }}
                                  alt="user" />
                                {moment(data?.organizer_dob).format("DD-MMM-YYYY") || "NA"}
                              </div>} */}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <div className='progress-lable'>Pre-Camp Report</div>
                    <div className='progress-value mt-1'>
                      {facilityStatus && staffStatus && itemStatus ? (
                        <div
                          className='div-links'
                          onClick={() => {
                            setCurrentTab('report')
                          }}
                        >
                          {/* <img src={'/assets/images/report.svg'} alt='pre-camp report' style={{ cursor: 'pointer' }} />
                          Done */}
                          View
                        </div>
                      ) : (
                        <>Pending</>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <div className='progress-lable'>Post-Camp Report</div>
                    <div className='progress-value mt-1'>
                      {facilityStatus && staffStatus && itemStatus && !isEditable ? (
                        <div
                          className='div-links'
                          onClick={() => {
                            setCurrentTab('post-report')
                          }}
                        >
                          {/* <img src={'/assets/images/report.svg'} alt='pre-camp report' style={{ cursor: 'pointer' }} />
                          Done */}
                          View
                        </div>
                      ) : (
                        <>Pending</>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <div className='progress-lable'>Reports</div>
                    <div className='progress-value mt-1'>
                      {facilityStatus && staffStatus && itemStatus && !isEditable && data?.registered_donor > 0 ? (
                        <div
                          className='div-links'
                          // onClick={() =>
                          //   downLoadFiles(`/report/camp-donor/download/${id}?download=true`, 'CampDonorReport')
                          // }
                        >
                          {/* <img src={'/assets/images/file-icons/xl.svg'} alt='pre-camp report' style={{ cursor: 'pointer', marginRight: "4px" }} height={24} /> */}

                          <div>
                            <Tooltip title='Download'>
                              <IconButton
                                color='primary'
                                sx={{ ...active, borderRadius: 1, maxHeight: '28px' }}
                                onClick={handleDropdownOpen}
                              >
                                <Download color='white' />
                              </IconButton>
                              <span className='ml-1'>Download</span>
                            </Tooltip>

                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => handleDropdownClose()}
                              sx={{ '& .MuiMenu-paper': { width: 290, mt: 1 } }}
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                              <ReportPreview
                                url={`/blood-testing/camp-multiple-tti-report/${id}`}
                                filename={'Camp_donors_tti_report'}
                                title={'Camp Donor TTI Report'}
                              >
                                <MenuItem sx={{ pl: 2 }}>
                                  <ListItemIcon>
                                    <PictureAsPdf />
                                  </ListItemIcon>{' '}
                                  Donors TTI Reports
                                </MenuItem>
                              </ReportPreview>

                              <ReportPreview
                                url={`/report/camp-donor/download/${id}?downloadPdf=true`}
                                filename={'CampDonorReport'}
                                title={'Camp Donor Report'}
                              >
                                <MenuItem sx={{ pl: 2 }}>
                                  <ListItemIcon>
                                    <PictureAsPdf />
                                  </ListItemIcon>
                                  Camp donors PDF
                                </MenuItem>
                              </ReportPreview>

                              <ReportPreview
                                url={`/report/camp-donor/download/${id}?downloadPdf=true&isOrganizerReport=true`}
                                filename={'CampDonorReport'}
                                title={'Camp Donors PDF (Organizer)'}
                              >
                                <MenuItem sx={{ pl: 2 }}>
                                  <ListItemIcon>
                                    <PictureAsPdf />
                                  </ListItemIcon>
                                  Camp Donors PDF (Organizer)
                                </MenuItem>
                              </ReportPreview>

                              <MenuItem
                                sx={{ pl: 2 }}
                                onClick={() => {
                                  downLoadFiles(`/report/camp-donor/download/${id}?download=true`, 'CampDonorReport')
                                }}
                              >
                                <ListItemIcon>
                                  <DocumentScanner />
                                </ListItemIcon>{' '}
                                Camp Donors Excel
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      ) : (
                        <>Pending</>
                      )}
                    </div>
                  </Grid>
                  {/* </div> */}
                </Grid>
              </div>
            </div>
          </div>
          <div className='progress-msg mt-3' ref={ref}>
            Please Complete Below Steps To Publish
          </div>
          <div className='mt-3  full-progress'>
            <Stepper alternativeLabel>
              <Step>
                <StepLabel
                  onClick={() => {
                    setCurrentTab('campinfo')
                    handleScroll()
                  }}
                  StepIconComponent={campRegStatus ? progressdone : progresspending}
                  sx={currentTab === 'campinfo' ? activeProgress : normalProgress}
                >
                  <div className='progress-label'>Camp Profile</div>
                  <div>
                    {' '}
                    {campRegStatus ? (
                      <div className='progress-status-done'>Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  onClick={() => {
                    if (checkPermission('UPDATE_CAMP') !== true) return

                    setCurrentTab('facility')
                    handleScroll()
                  }}
                  StepIconComponent={facilityStatus ? progressdone : progresspending}
                  sx={currentTab === 'facility' ? activeProgress : normalProgress}
                >
                  <div className='progress-label'>Facility Inspection</div>
                  <div>
                    {' '}
                    {facilityStatus ? (
                      <div className='progress-status-done'>Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  onClick={() => {
                    if (checkPermission('UPDATE_CAMP') !== true) return

                    setCurrentTab('staff')
                    handleScroll()
                  }}
                  StepIconComponent={staffStatus ? progressdone : progresspending}
                  sx={currentTab === 'staff' ? activeProgress : normalProgress}
                >
                  <div className='progress-label'>Add Staff</div>
                  <div>
                    {staffStatus ? (
                      <div className='progress-status-done'>Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  onClick={() => {
                    if (checkPermission('UPDATE_CAMP') !== true) return

                    // history.push(`/blood-camp-management/view/${id}/item`)
                    setCurrentTab('item')
                    handleScroll()
                  }}
                  StepIconComponent={itemStatus ? progressdone : progresspending}
                  sx={currentTab === 'item' ? activeProgress : normalProgress}
                >
                  <div className='progress-label'>Add Store Item</div>
                  <div>
                    {itemStatus ? (
                      <div className='progress-status-done'>Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>

              <Step>
                <StepLabel
                  StepIconComponent={certificateStatus ? progressdone : progresspending}
                  onClick={() => {
                    if (checkPermission('CREATE_CERTIFICATE') !== true) return

                    history.push(`/certification/camp/${id}`)
                  }}
                >
                  <div className='progress-label'>Certification</div>
                  <div>
                    {certificateStatus ? (
                      <div className='progress-status-done'>Done</div>
                    ) : (
                      <div className='progress-status-pending'>Pending</div>
                    )}
                  </div>
                </StepLabel>
              </Step>
              {isOldItem ? null : (
                <Step>
                  <StepLabel
                    onClick={() => {
                      if (checkPermission('UPDATE_CAMP') !== true) return
                      if (itemStatus === false) {
                        toast.error('Please add the items in camp first !')
                        return
                      }
                      setCurrentTab('returnItem')
                      handleScroll()
                    }}
                    StepIconComponent={returnItemStatus ? progressdone : progresspending}
                    sx={currentTab === 'returnItem' ? activeProgress : normalProgress}
                  >
                    <div className='progress-label'>Return Store Item</div>
                    <div>
                      {returnItemStatus ? (
                        <div className='progress-status-done'>Done</div>
                      ) : (
                        <div className='progress-status-pending'>Pending</div>
                      )}
                    </div>
                  </StepLabel>
                </Step>
              )}
            </Stepper>
          </div>
          <div className='mt-3 row mobile-progress'>
            <div
              className={currentTab === 'campinfo' ? 'mobile-progress-items-active' : 'mobile-progress-items'}
              onClick={() => {
                setCurrentTab('campinfo')
                handleScroll()
              }}
            >
              {campRegStatus ? (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              ) : (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              )}
              Camp Profile
            </div>
            <div
              className={currentTab === 'facility' ? 'mobile-progress-items-active' : 'mobile-progress-items'}
              onClick={() => {
                if (checkPermission('UPDATE_CAMP') === true) {
                  setCurrentTab('facility')
                  handleScroll()
                }
              }}
            >
              {facilityStatus ? (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              ) : (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              )}
              <div className='d-flex'>Facility Inspection</div>
            </div>
            <div
              className={currentTab === 'staff' ? 'mobile-progress-items-active' : 'mobile-progress-items'}
              onClick={() => {
                if (checkPermission('UPDATE_CAMP') === true) {
                  setCurrentTab('staff')
                  handleScroll()
                }
              }}
            >
              {staffStatus ? (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              ) : (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              )}
              Add Staff
            </div>
            <div
              className={currentTab === 'item' ? 'mobile-progress-items-active' : 'mobile-progress-items'}
              onClick={() => {
                // history.push(`/blood-camp-management/view/${id}/item`)
                if (checkPermission('UPDATE_CAMP') === true) {
                  setCurrentTab('item')
                  handleScroll()
                }
              }}
            >
              {itemStatus ? (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              ) : (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              )}
              Add Store Item
            </div>
            <div
              className={currentTab === '/certification' ? 'mobile-progress-items-active' : 'mobile-progress-items'}
              onClick={() => {
                if (checkPermission('CREATE_CERTIFICATE') === true) {
                  history.push('/certification')
                }
              }}
            >
              {certificateStatus ? (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              ) : (
                <img src={'/assets/images/status/Done.svg'} alt='' />
              )}
              Certification
            </div>
          </div>
          <div className='mt-5'>
            {currentTab === 'campinfo' ? (
              <>
                <div>
                  {editCamp ? (
                    <div>
                      <AddBloodCamp editdata={data} handleCancel={handleCancel} isEditable={isEditable} />
                    </div>
                  ) : (
                    <>
                      <div className='profile-section-item profile-header'>
                        <span className='profile-header-heading text-capitalize'>Camp Name : {data?.camp_name}</span>
                        <div className='profile-header-actions'>                          
                              {checkPermission('UPDATE_CAMP') === true ? (
                                <button
                                  onClick={() => {
                                    setEditCamp(true)
                                  }}
                                  type='button'
                                  className='btn gradient-btn mr-0 ml-2 rounded-lg '
                                >
                                  Edit
                                </button>
                          ) : null}
                        </div>
                      </div>

                      <div>
                        <section className='detail-section'>
                          <div className='detail-header'>Camp Information</div>
                          <div className='detail-grid'>
                            {campOrganizerData?.map((item, idx) => (
                              <div className='grid-item' key={idx}>
                                <p className='item-header'>{item['label']}</p>
                                <p className={idx < 3 ? 'item-value text-capitalize' : 'item-value'}>
                                  {item['ItemValue'] || 'NA'}
                                </p>
                              </div>
                            ))}
                          </div>
                        </section>
                        <section className='detail-section'>
                          <div className='detail-header'>Camp Venue And Timings</div>
                          <div className='detail-grid '>
                            {campdetails.map((item, idx) => (
                              <div className='grid-item' key={idx}>
                                <p className='item-header'>{item['label']}</p>
                                <p className='item-value'>{item['ItemValue'] || 'NA'}</p>
                              </div>
                            ))}
                          </div>
                        </section>
                        <section className='detail-section'>
                          <div className='detail-header'>Camp Addition Details</div>
                          <div className='detail-grid'>
                            {campotherDetails.map((item, idx) => (
                              <div className='grid-item' key={idx}>
                                <p className='item-header'>{item['label']}</p>
                                <p className='item-value'>{item['ItemValue'] || 'NA'}</p>
                              </div>
                            ))}
                          </div>
                        </section>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : null}

            {currentTab === 'qr' ? <Qrcode name={data.camp_name} isEditable={isEditable} campdata={data} /> : null}

            {currentTab === 'certification' ? 'certification ' : null}
            {currentTab === 'staff' ? (
              <>
                {staffStatus ? (
                  <StaffTable getCampDetails={getCampDetails} allStaff={data?.CampStaffEntities} id={id} camp={true} />
                ) : (
                  <StaffForm setItemStatus={setItemStatus} addFromView={true} getCampDetails={getCampDetails} />
                )}
              </>
            ) : null}
            {currentTab === 'returnItem' ? (
              <>
                <ItemTable
                  getCampDetails={getCampDetails}
                  allItems={data?.ItemCampEntities}
                  id={id}
                  camp={true}
                  campReturnTab={true}
                />
              </>
            ) : null}
            {currentTab === 'item' ? (
              <>
                {itemStatus ? (
                  <ItemTable
                    getCampDetails={getCampDetails}
                    allItems={data?.CampItemEntities?.length ? data?.CampItemEntities : data?.ItemCampEntities}
                    isOldItem={isOldItem}
                    id={id}
                    camp={true}
                  />
                ) : (
                  <ItemForm setItemStatus={setItemStatus} addFromView={true} getCampDetails={getCampDetails} />
                )}
              </>
            ) : null}
            {currentTab === 'facility' ? (
              <>
                {facilityStatus ? (
                  <ViewFacility data={data} />
                ) : (
                  <AddFacility campdata={data} addFromView={true} getCampDetails={getCampDetails} />
                )}
              </>
            ) : null}
            {currentTab === 'report' ? <PreReport data={data} /> : null}

            {currentTab === 'post-report' ? <PostReport data={data} /> : null}
          </div>
        </div>
      </ScrollCard>
    </>
  )
}

export default ViewCamp

import { Box, Button, Dialog, DialogActions, DialogContent, Fade, IconButton, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import LoadingButton from './LoadingButton'

const Transition = forwardRef((props, ref) => <Fade ref={ref} {...props} />)

function DeleteConfirm({ closeDialog, children, open, confirmDialog, title, size, scroll, id, fullScreen,loading }) {

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen || false}
      open={open}
      maxWidth={size || 'sm'}
      scroll={scroll || 'body'}
      onClose={closeDialog}
      onBackdropClick={closeDialog}
      TransitionComponent={Transition}
      id={id || 'custom_modal'}
    >
      <div>
        <DialogContent sx={{}}>
          <div className='model-heading'>{title || 'Are You Sure?'}</div>
          <div className='model-body'>{children}</div>

          <DialogActions>
            <button className='btn backBtn' onClick={closeDialog}>
              Close
            </button>
            <LoadingButton title={'Confirm'} onClick={confirmDialog} isLoading={loading} />
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default DeleteConfirm

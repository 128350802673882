import React from 'react'
import { FRONT_END_URL } from '../../helper/AxiosHelper'
import QRCode from 'react-qr-code'

const PrintQr = ({ settingData, value, id }) => {
  return (
    <div
      id={id}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          // maxWidth: "450px",
          // maxHeight: "570px",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: '100%',
            background: 'var(--ehr-background, #f2f9f7)',
            position: 'relative',
            display: 'flex'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '5px'
            }}
          >
            <img
              alt=''
              src={settingData?.logo || '/assets/images/qr/Group.svg'}
              style={{
                objectFit: 'contain',
                width: '180px',
                height: '80px'
              }}
            ></img>
            <div
              style={{
                color: 'black',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                marginBottom: '10px',
                textTransform: 'capitalize'
              }}
            >
              {settingData?.name || ''}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              color: 'black',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
              marginBottom: '5px',
              marginTop: '11px'
            }}
          >
            Self registration QR Code
          </div>
          <div
            style={{
              position: 'relative'
            }}
          >
            <img
              src={'/assets/images/qr/Isolation_Mode.svg'}
              alt='QR-Code'
              style={{
                width: '365.57px',
                height: '310px',
                flexShrink: '0',
                fill: '#1f4168',
                position: 'relative'
              }}
            ></img>
            <div
              style={{
                position: 'absolute',
                top: '38px',
                left: '98px'
              }}
            >
              <QRCode id='qrCodeEl' title='title' value={value} bgColor='#FFFFFF' fgcolor='#000000' size={170} />
            </div>
          </div>

          <div
            style={{
              color: 'black',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
              marginBottom: '5px',
              marginTop: '15px'
            }}
          >
            Powered By
          </div>
          <div
            style={{
              marginBottom: '5px'
            }}
          >
            <img
              src={'/assets/images/qr/Logo-main.svg'}
              style={{
                width: '215px',
                height: '50px'
              }}
              alt='EHR Logo'
            ></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintQr

import React, { useEffect, useRef, useState } from 'react'
import './CustomAutocomplete.css'
import { inputStyles } from '../../certification/EditCertificate'
import RHFTextField from '../RHFTextfield'

/**
 * CustomAutocomplete Component
 *
 * A reusable autocomplete input component that filters dropdown options
 * as the user types, allows selection from the filtered list, and enables
 * adding a new item to the list dynamically.
 *
 * @param {Object} props - Component props.
 * @param {Array<string>} props.items - The initial list of items for the dropdown.
 * @param {string} [props.placeholder="Search..."] - Placeholder text for the input field.
 * @param {function} props.onSelect - Callback function triggered when an item is selected.
 * @returns {JSX.Element} The CustomAutocomplete component.
 */
export const CustomAutocomplete = ({ items, placeholder = 'Search...', onSelect, showModal, setShowModal, name }) => {
  const [query, setQuery] = useState('')
  const [filteredItems, setFilteredItems] = useState(items)
  const [isOpen, setIsOpen] = useState(false)
  const [mainItems, setMainItems] = useState(items) // Store the main list of items
  const dropdownRef = useRef()
  const handleInputChange = e => {
    const value = e.target.value
    setQuery(value)

    // Filter items based on the input value
    const filtered = mainItems.filter(item => item.hospital_name.toLowerCase().includes(value.toLowerCase()))
    setFilteredItems(filtered)
    setIsOpen(filtered.length > 0 || value.trim() !== '')
  }

  const handleSelect = item => {
    setQuery(item.hospital_name)
    setIsOpen(false)
    onSelect(item)
  }

  const handleInputFocus = () => {
    setIsOpen(true)
  }

  const handleBlur = () => {
    // Hide the dropdown when input loses focus
    setTimeout(() => setIsOpen(false), 200)
  }

  const handleAddNewItem = () => {
    // const newItem = prompt('Enter the new item name:')
    // if (newItem && newItem.trim() !== '') {
    //   const updatedItems = [...mainItems, newItem]
    //   setMainItems(updatedItems)
    //   setFilteredItems(updatedItems)
    //   setQuery(newItem)
    //   onSelect(newItem)
    //   setIsOpen(false)
    // }
    setShowModal(true)
  }

  useEffect(() => {
    if (items) {
      setMainItems(items)
      setFilteredItems(items)
    }
  }, [items])

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      dropdownRef.current.focus()
    }
  }, [isOpen])

  console.log(`citem -> `, items)

  return (
    <div className='custom-autocomplete'>
      {/* Input Field */}
      <RHFTextField
        name={name}
        type='text'
        value={query}
        placeholder={placeholder}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleBlur}
        size='small'
        fullWidth
        sx={inputStyles}
      />

      {/* Dropdown List */}
      {isOpen && items ? (
        <ul
          ref={dropdownRef}
          onMouseEnter={e => {
            // Ensure that the list scrolls without propagating to the parent
            e.currentTarget.addEventListener('wheel', e => e.stopPropagation(), { passive: false })
          }}
          className='custom-autocomplete__list'
        >
          {filteredItems.map((item, index) => (
            <li key={index} onClick={() => handleSelect(item)} className='custom-autocomplete__item'>
              {item?.hospital_name || '-'}
            </li>
          ))}

          {/* Add Item Option */}
          <li onClick={handleAddNewItem} className='custom-autocomplete__item custom-autocomplete__add-item'>
            + Add Item
          </li>
        </ul>
      ) : null}
    </div>
  )
}

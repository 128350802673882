import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import AxiosHelper from '../../../helper/AxiosHelper'
import CardHeading, { endOfMonthDefault, startOfMonthDefault } from './cardHeading'
import { DashboardNoDat } from './WasteChart'
import { checkTenantId } from '../../../helper/CommonAPI'

const DonorsChart = () => {
  const [apiData, setApiData] = useState(null)
  const [campData, setCampData] = useState(null)
  const [bankData, setBankData] = useState(null)
  const [totalData, setTotalData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(startOfMonthDefault)
  const [updatedDate, setUpdatedDate] = useState(endOfMonthDefault)
  const [endDate, setEndDate] = useState(null)
  const options = {
    chart: {
      type: 'donut'
    },
    stroke: { width: 0 },
    labels: ['Voluntary F', 'Voluntary M', 'Replacement F', 'Replacement M', 'Autologous F', 'Autologous M'],
    colors: ['#76B7B2', '#F28E2B', '#59A14F', '#E15759', '#376196', '#881A1A'],
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        return opts.w.globals.series[opts.seriesIndex]
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '12px',
      fontWeight: '500',
      fontFamily: 'Helvetica, Arial',
      labels: {
        colors: ['#000'],
        useSeriesColors: false
      },
      markers: {
        width: 12,
        height: 20, // Increase height to make it rectangular
        radius: 2 // Small radius for slightly rounded corners
      },
      itemMargin: {
        horizontal: 2,
        vertical: 5
      }
    }
  }

  const campPlot = {
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '58%', // Increase the width of the donut by decreasing this value
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Blood Camp',
              fontSize: '14px',
              fontWeight: 600,
              color: '#000',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
              }
            }
          }
        }
      }
    }
  }
  const bankPlot = {
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '58%', // Increase the width of the donut by decreasing this value
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Blood Bank',
              fontSize: '14px',
              fontWeight: 600,
              color: '#000',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
              }
            }
          }
        }
      }
    }
  }
  const totalPlot = {
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '58%', // Increase the width of the donut by decreasing this value
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              fontSize: '14px',
              fontWeight: 600,
              color: '#000',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
              }
            }
          }
        }
      }
    }
  }

  const findCounts = data => {
    const counts = {
      Voluntary_Female: 0,
      Voluntary_Male: 0,
      Replacement_Female: 0,
      Replacement_Male: 0,
      Autologous_Female: 0,
      Autologous_Male: 0
    }

    data.forEach(d => {
      const key = `${d.donation_type}_${d.gender}`
      if (counts.hasOwnProperty(key)) {
        counts[key] += d.donors
      }
    })

    return counts
  }

  function filterData(donorData, type) {
    let arrayData = donorData
    if (type !== 'total') {
      arrayData = donorData.filter(d => d.category === type)
    }

    return findCounts(arrayData)
  }

  const getDashboardInfo = () => {
    setLoading(true)
    const URL=checkTenantId(`dashboard/total-donors?startDate=${startDate || ''}&endDate=${endDate || ''}`)
    AxiosHelper.get(URL)
      .then(resp => {
        if (resp?.data?.data) {
          let resData = resp?.data?.data
          setApiData(resData)
          setCampData(filterData(resData?.donor, 'camp'))
          setBankData(filterData(resData?.donor, 'bank'))
          setTotalData(filterData(resData?.donor, 'total'))
          setLoading(false)
          setUpdatedDate(new Date())
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  function returnArraySeries(obj) {
    if (!obj) {
      return [0, 0, 0, 0, 0, 0]; // Default values if obj is undefined
    }
    return [
      obj?.Voluntary_Female || 0,
      obj?.Voluntary_Male || 0,
      obj?.Replacement_Female || 0,
      obj?.Replacement_Male || 0,
      obj?.Autologous_Female || 0,
      obj?.Autologous_Male || 0
    ];
  }

  useEffect(() => {
    getDashboardInfo()
  }, [startDate, endDate])

  return (
    <>
      <CardHeading
        path='/donors'
        title='Total Donors'
        size='sm'
        hideDetail={true}
        count={apiData?.total_donor?.totalDonor}
        refreshDataApi={getDashboardInfo}
        loading={loading}
        setStartDateCustom={setStartDate}
        setEndDateCustom={setEndDate}
        updatedDate={updatedDate}
      />
      {apiData?.total_donor?.totalDonor == 0 ? (
        <DashboardNoDat />
      ) : (
        <Grid container spacing={2} sx={{ alignItems: 'center', mt: 3, width: '100%', pb: 2 }}>
          <Grid item xs={12} md={4}>
            <ReactApexChart
              options={{ ...options, ...bankPlot }}
              series={returnArraySeries(bankData)}
              type='donut'
              height={350}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReactApexChart
              options={{ ...options, ...campPlot }}
              series={returnArraySeries(campData)}
              type='donut'
              height={350}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReactApexChart
              options={{ ...options, ...totalPlot }}
              series={returnArraySeries(totalData)}
              type='donut'
              height={350}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default DonorsChart

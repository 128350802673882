import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactApexcharts from 'react-apexcharts'
import AxiosHelper from '../../../helper/AxiosHelper'
import NoDataFound from '../../common/NoDataFound'
import CardHeading, { endOfMonthDefault, startOfMonthDefault } from './cardHeading'
import { checkTenantId } from '../../../helper/CommonAPI'

const labels = ['Assign blood bag', 'Blood inventory', 'Screening', 'TTI testing']

const DonorStatsChart = () => {
  const [apiData, setApiData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(startOfMonthDefault)
  const [endDate, setEndDate] = useState(endOfMonthDefault)
  const [modalData, setModalData] = useState([])
  const [updatedDate, setUpdatedDate] = useState(null)
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Quantity',
        data: [2, 2, 3, 4, 4]
      }
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true, // This makes each bar have a different color
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: ['#13BCAB', '#376196', '#881A1A', '#9B8126', '#783611'],
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333']
        },
        offsetX: 6,
        formatter: function (val) {
          return val.toFixed(0)
        }
      },
      xaxis: {
        categories: labels,
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '13px',
            textAlign: 'left',
            borderBottom: '1px solid #000'
          },
          align: 'left'
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          width: 100,
          offsetX: 0,
          offsetY: 0
        }
      },
      tooltip: {
        enabled: false
      },
      colors: ['#13BCAB', '#376196', '#881A1A', '#9B8126', '#783611']
    }
  })

  const getDashboardInfo = () => {
    setLoading(true)
    const URL=checkTenantId(`dashboard/donor-statistics?startDate=${startDate || ''}&endDate=${endDate || ''}`)
    AxiosHelper.get(URL)
      .then(resp => {
        if (resp?.data?.data) {
          let resData = resp?.data?.data
          setApiData(resData)
          // Assuming resData contains keys like 'assignedBag', 'inventory', 'screening', 'tti_testing'
          let newSeriesData = [
            resData.assignedBag || 0,
            resData.inventory || 0,
            resData.screening || 0,
            resData.tti_testing || 0
          ]

          let newArray = labels?.map((item, index) => {
            return {
              label: item,
              value: newSeriesData[index] || '0'
            }
          })
          setModalData(newArray)
          setUpdatedDate(new Date())
          setLoading(false)
          setChartData({
            ...chartData,
            series: [
              {
                name: 'Quantity',
                data: newSeriesData
              }
            ]
          })
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    getDashboardInfo()
  }, [startDate, endDate])

  let total = apiData ? apiData?.assignedBag + apiData?.inventory + apiData?.screening + apiData?.tti_testing : 0

  return (
    <>
      <CardHeading
        path='/donors'
        title='Blood Donor'
        size='sm'
        count={total}
        refreshDataApi={getDashboardInfo}
        loading={loading}
        setStartDateCustom={setStartDate}
        setEndDateCustom={setEndDate}
        modalData={modalData}
        updatedDate={updatedDate}
      />
      <div style={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            left: 4,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            pl: 3
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 'bold',
              textAlign: 'left',
              width: '100px',
              borderRight: '1px solid gray'
            }}
          >
            Task
          </Typography>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 'bold',
              textAlign: 'left',
              pl: 2
            }}
          >
            Pending's
          </Typography>
        </Box>
        <ReactApexcharts options={chartData.options} series={chartData.series} type='bar' height={250} />
      </div>
    </>
  )
}

export default DonorStatsChart

import React, { useState } from 'react'
import { Alert, Collapse, FormLabel, Grid } from '@mui/material'
import { useEffect } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { RequiredField } from '../../stocks/ItemForm'
import Loader from '../../shared/Loader'
import ModalCustom, { ModalActions } from '../../shared/ModalCustom'
import LoadingButton from '../../shared/LoadingButton'
import FormProvider from '../../shared/FormProvider'
import RHFTextField from '../../shared/RHFTextfield'
import { RHFAutoComplete } from '../../shared/RHFAutoComplete'
import { labelStyles, inputStyles } from '../../certification/EditCertificate'
import AxiosHelper from '../../../helper/AxiosHelper'
import { checkTenantId } from '../../../helper/CommonAPI'

const defaultValues = {
  name: '',
  manufacture_id: '',
  limit: '',
  duration: ''
}

const schema = yup.object().shape({
  name: yup.string().required('Item name is required'),
  manufacture_id: yup.string(),
  limit: yup.string().required('Bag Count is required'),
  duration: yup.string().required('Duration is required')
})

const AddMachine = ({ state = 'Add', openModal, setEditState, setOpenModal, editState, getStocks }) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const [error, setError] = useState(false)
  const [manufactureList, setManufactureList] = useState([])

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    reset,
    getValues,
    formState: { errors }
  } = methods

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  function onSubmit(data) {
    setError(false)
    setBtnLoading(false)
    let bodyData = [{ ...data, category: 'blood-process' }]
    let url = 'setting/tenant-machine'

    if (state === 'Edit') {
      bodyData = [{ ...data, id: editState?.id, category: 'blood-process' }]
    }
    AxiosHelper.post(url, bodyData)
      .then(response => {
        setBtnLoading(false)
        toast.success(`Machine ${state === 'Edit' ? 'Updated' : 'Added'} Successfully`)
        handleModalClose()
        getStocks()
      })
      .catch(error => {
        handlerError(error)
        setBtnLoading(false)
      })
  }

  const getManufacturer = () => {
    let url = `manufacture`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        let arr = response.data.data
        const manufactureLabels = arr?.map(item => {
          return {
            label: item?.name,
            id: item?.id
          }
        })
        setManufactureList(manufactureLabels)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handlerError = error => {
    setBtnLoading(false)
    setMessage(error)
    setError(true)
  }

  const fillFormEditData = () => {
    let item = editState

    reset({
      name: item?.name || '',
      manufacture_id: item?.manufacture_id || '',
      limit: item?.limit || '',
      duration: item?.duration || ''
    })
  }

  useEffect(() => {
    if (state === 'Edit') {
      fillFormEditData()
    }
  }, [state])

  useEffect(() => {
    getManufacturer()
  }, [])

  function handleModalClose() {
    reset(defaultValues)
    setOpenModal(false)
    setEditState?.(null)
  }

  return (
    <ModalCustom
      title={`${state} Machine`}
      open={openModal}
      size='sm'
      dialogStyle={{
        px: 2,
        pt: 0,
        pb: 0
      }}
      closeDialog={handleModalClose}
      actionComponent={
        <ModalActions>
          <div className='d-flex justify-content-end align-items-end p-3 '>
            <button type='button' className='btn backBtn mr-2 ' onClick={handleModalClose}>
              Cancel
            </button>
            <LoadingButton onClick={handleSubmit(onSubmit)} isLoading={btnLoading} title='Submit' />
          </div>
        </ModalActions>
      }
    >
      <div className='my-auto'>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormLabel sx={labelStyles}>Item Name{RequiredField()}</FormLabel>
              <RHFTextField placeholder='Enter Item Name' fullWidth name='name' sx={inputStyles} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel sx={labelStyles}>Bag Count{RequiredField()}</FormLabel>
              <RHFTextField
                placeholder='Enter Bag Count'
                name='limit'
                size='small'
                type='number'
                fullWidth
                sx={inputStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel sx={labelStyles}>Manufacture Name</FormLabel>
              <RHFAutoComplete
                freeSolo
                name='manufacture_id'
                options={manufactureList}
                size='small'
                placeholder='Enter Manufacture Name'
                fullWidth
                onInputChange={(e, newInputValue, reason) => {
                  if (reason === 'reset') return
                  if (e.target.value) {
                    setValue('manufacture_name', e.target.value)
                    setValue('manufacture_id', 'other')
                    return
                  }
                  setValue('manufacture_name', '')
                  setValue('manufacture_id', '')
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormLabel sx={labelStyles}>Duration in min.</FormLabel>
              <RHFTextField
                placeholder='Enter Duration'
                name='duration'
                size='small'
                type='number'
                fullWidth
                sx={inputStyles}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={error}>
                <Alert severity='error' onClose={() => setError(false)} dismissible>
                  {message || 'Something Went Wrong on our side'}
                </Alert>
              </Collapse>
            </Grid>
          </Grid>
        </FormProvider>
      </div>
    </ModalCustom>
  )
}

export default AddMachine

import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { usersListByRole } from '../../helper/CommonAPI'

const UserRolesAutocomplete = props => {
    const { name, size, handleChange, role, value, onChange, options = [],inputHeight, ...other } = props
    const autocompleteOptions = options.length ? options : usersListByRole(role);
    return (
        <Autocomplete
            size={size || 'small'}
            value={
                autocompleteOptions.find((option) => option.id === value) || { label: value }
            }
            getOptionLabel={(option) => option.label || ''}
            freeSolo
            onChange={(event, newValue) => {
                const newValueId = newValue?.id || newValue?.label || newValue || '';
                if (onChange) {
                    onChange(event, newValueId);
                }
            }}
            onInputChange={(event, newInputValue) => {
                if (onChange) {
                    onChange(event, newInputValue);
                }
            }}
            id="controllable-states-demo"
            options={autocompleteOptions}
            renderInput={(params) => <TextField
                sx={{
                    ...(params.sx || {}),
                    '& .MuiInputBase-root': {
                        height: inputHeight || 'auto', // Use custom height or default
                    },
                }}
                {...params} />}
            {...other}
        />
    )

}

export default UserRolesAutocomplete

import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import AxiosHelper from '../../../helper/AxiosHelper'
import CardHeading, { endOfMonthDefault, startOfMonthDefault } from './cardHeading'
import NoDataFound from '../../common/NoDataFound'
import { Box } from '@mui/material'
import { checkTenantId } from '../../../helper/CommonAPI'

export function DashboardNoDat() {
  return (
    <Box sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
      <NoDataFound />
    </Box>
  )
}

const labels = ['Anti-HCV', 'Expired', 'H Bs Ag', 'HIV', 'Malaria', 'Other', 'A+ Vdrl']

const WasteChart = () => {
  const [apiData, setApiData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(startOfMonthDefault)
  const [endDate, setEndDate] = useState(endOfMonthDefault)
  const [modalData, setModalData] = useState([])
  const [updatedDate, setUpdatedDate] = useState(null)

  const [chartData, setChartData] = useState({
    series: [15, 8, 8, 7, 15, 20, 15],
    options: {
      chart: {
        type: 'donut'
      },
      stroke: { width: 0 },
      labels: labels,
      colors: ['#13BCAB', '#376196', '#881A1A', '#9B8126', '#783611', '#a2b861', '#d24e4e'],
      dataLabels: {
        enabled: true,
        formatter: (val, opts) => {
          return opts.w.globals.series[opts.seriesIndex]
        }
      },
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'Helvetica, Arial',
        labels: {
          colors: ['#000'],
          useSeriesColors: false
        }
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '60%', // Increase the width of the donut by decreasing this value
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total Units',
                fontSize: '20px',
                fontWeight: 600,
                color: '#000',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 768, // small screen breakpoint
          options: {
            chart: {
              height: 400 // Increase height on small screens
            },
            legend: {
              position: 'bottom', // Move legend below the chart
              horizontalAlign: 'center'
            },
            plotOptions: {
              pie: {
                donut: {
                  size: '60%' // Increase the donut size on small screens
                }
              }
            }
          }
        }
      ]
    }
  })

  const getDashboardInfo = () => {
    setLoading(true)
    const URL = checkTenantId(`dashboard/total-waste?startDate=${startDate || ''}&endDate=${endDate || ''}`)
    AxiosHelper.get(URL)
      .then(resp => {
        if (resp?.data?.data) {
          let resData = resp?.data?.data
          if (Object.keys(resData)?.length) {
            let array = [
              resData?.anty_hcv,
              resData?.expired,
              resData?.hsbg,
              resData?.hiv,
              resData?.malaria,
              resData?.other,
              resData?.vdrl
            ]
            setChartData({
              ...chartData,
              series: array
            })
            setApiData(resData)

            let newArray = labels?.map((item, index) => {
              return {
                label: item,
                value: array[index] || '0'
              }
            })
            setModalData(newArray)
          } else {
            setApiData(null)
          }
          setUpdatedDate(new Date())
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    getDashboardInfo()
  }, [])

  return (
    <>
      <CardHeading
        path='/waste-management/waste-list'
        title='Waste management'
        size='sm'
        count={apiData?.total || 0}
        refreshDataApi={getDashboardInfo}
        loading={loading}
        setStartDateCustom={setStartDate}
        setEndDateCustom={setEndDate}
        modalData={modalData}
        updatedDate={updatedDate}
      />
      {apiData ? (
        <ReactApexChart options={chartData.options} series={chartData.series} type='donut' height={350} />
      ) : (
        <DashboardNoDat />
      )}
    </>
  )
}

export default WasteChart

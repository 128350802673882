import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Alert, Collapse, FormLabel, Grid } from '@mui/material'
import { useEffect } from 'react'
import Loader from '../shared/Loader'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormProvider from '../shared/FormProvider'
import RHFTextField from '../shared/RHFTextfield'
import { inputStyles, labelStyles } from '../certification/EditCertificate'
import { RHFDatePicker } from '../shared/RHFDatePicker'
import AxiosHelper from '../../helper/AxiosHelper'
import LoadingButton from '../shared/LoadingButton'
import dayjs from 'dayjs'
import ModalCustom, { ModalActions } from '../shared/ModalCustom'
import { RHFAutoComplete } from '../shared/RHFAutoComplete'
import { toast } from 'react-toastify'
import { checkTenantId } from '../../helper/CommonAPI'

export function RequiredField() {
  return <span className='text-danger'>*</span>
}

const defaultValues = {
  type: 1,
  name: '',
  expiry_date: null,
  supplier_id: '',
  manufacture_id: '',
  receipt_date: null
}

const items = [
  {
    label: 'BP apparatus',
    id: 'BP apparatus'
  },
  {
    label: 'Stethoscope',
    id: 'Stethoscope'
  },
  {
    label: 'SDP kit',
    id: 'SDP kit'
  },
  {
    label: 'Blood bags 350ml(single)',
    id: 'Blood bags 350ml(single)'
  },
  {
    label: 'Blood bags 450ml(single)',
    id: 'Blood bags 450ml(single)'
  },
  {
    label: 'Blood bags 350ml(double)',
    id: 'Blood bags 350ml(double)'
  },
  {
    label: 'Blood bags 450ml(double)',
    id: 'Blood bags 450ml(double)'
  },
  {
    label: 'Blood bags 350ml(triple)',
    id: 'Blood bags 350ml(triple)'
  },
  {
    label: 'Blood bags 450ml(triple)',
    id: 'Blood bags 450ml(triple)'
  },
  {
    label: 'Donor questionnaire',
    id: 'Donor questionnaire'
  },
  {
    label: 'Weighing device for donors',
    id: 'Weighing device for donors'
  },
  {
    label: 'Artery forceps',
    id: 'Artery forceps'
  },
  {
    label: 'Scissors',
    id: 'Scissors'
  },
  {
    label: 'Needle destroyer(wherever necessary)',
    id: 'Needle destroyer(wherever necessary)'
  },
  {
    label: 'Stripper for blood tubing',
    id: 'Stripper for blood tubing'
  },
  {
    label: 'Bed sheets',
    id: 'Bed sheets'
  },
  {
    label: 'Blankets/mattress',
    id: 'Blankets/mattress'
  },
  {
    label: 'Lancets',
    id: 'Lancets'
  },
  {
    label: 'Swab stick/tooth picks',
    id: 'Swab stick/tooth picks'
  },
  {
    label: 'Glass slitemIdes',
    id: 'Glass slitemIdes'
  },
  {
    label: 'Quantitative method for Hb estimation',
    id: 'Quantitative method for Hb estimation'
  },
  {
    label: 'Test tube (big)',
    id: 'Test tube (big)'
  },
  {
    label: 'Test tube 12x100mm(small)',
    id: 'Test tube 12x100mm(small)'
  },
  {
    label: 'Test tube stand',
    id: 'Test tube stand'
  },
  {
    label: 'Anti-A',
    id: 'Anti-A'
  },
  {
    label: 'Anti-B',
    id: 'Anti-B'
  },
  {
    label: 'Anti-D',
    id: 'Anti-D'
  },
  {
    label: 'Antisera',
    id: 'Antisera'
  },
  {
    label: 'Test tube sealer film',
    id: 'Test tube sealer film'
  },
  {
    label: 'Medicated adhesive tape',
    id: 'Medicated adhesive tape'
  },
  {
    label: 'Plastic waste basket',
    id: 'Plastic waste basket'
  },
  {
    label: 'Emergency medical kit',
    id: 'Emergency medical kit'
  },
  {
    label: 'ELISA Kit (HCV)',
    id: 'ELISA Kit (HCV)'
  },
  {
    label: 'ELISA Kit (HIV)',
    id: 'ELISA Kit (HIV)'
  },
  {
    label: 'ELISA Kit (HBsAg)',
    id: 'ELISA Kit (HBsAg)'
  },
  {
    label: 'ELISA Kit (Syphilis)',
    id: 'ELISA Kit (Syphilis)'
  },
  {
    label: 'ELISA Kit (Malaria)',
    id: 'ELISA Kit (Malaria)'
  },
  {
    label: 'Rapid Kit (HCV)',
    id: 'Rapid Kit (HCV)'
  },
  {
    label: 'Rapid Kit (HIV)',
    id: 'Rapid Kit (HIV)'
  },
  {
    label: 'Rapid Kit (HBsAg)',
    id: 'Rapid Kit (HBsAg)'
  },
  {
    label: 'Rapid Kit (Syphilis)',
    id: 'Rapid Kit (Syphilis)'
  },
  {
    label: 'Rapid Kit (Malaria)',
    id: 'Rapid Kit (Malaria)'
  },
  {
    label: 'Insulated blood bag containers',
    id: 'Insulated blood bag containers'
  },
  {
    label: 'Dielectric sealer or portable tube sealer',
    id: 'Dielectric sealer or portable tube sealer'
  },
  {
    label: 'Weighing device for blood bags',
    id: 'Weighing device for blood bags'
  },

  {
    label: 'Donor cards and refreshment for donors',
    id: 'Donor cards and refreshment for donors'
  },
  {
    label: 'Storage Facilities of blood units ',
    id: 'Storage Facilities of blood units '
  }
]
const typeOptions = [
  {
    label: 'Consumable',
    id: 1
  },
  {
    label: 'Non Consumable',
    id: 2
  }
]

const schema = yup.object().shape({
  name: yup.string().required('Item name is required'),
  manufacture_id: yup.string().required('Manufacturer name is required'),
  supplier_id: yup.string().required('Supplier name is required'),
  // batch_no: yup.string().required('Batch number is required'),
  // invoice_no: yup.string().required('Invoice number is required'),
  // lot_number: yup.string().required('Lot number is required'),
  quantity: yup.string().required('Quantity is required'),
  // price: yup.string().required('Price is required'),
  // receipt_date: yup.string().required('Receipt Date is required'),
  // expiry_date: yup.string().required('Expiry Date is required')
})

const ItemForm = ({ state = 'Add', openModal, setOpenModal, editState, getStocks }) => {
  const [isLoading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const [error, setError] = useState(false)
  const [manufactureList, setManufactureList] = useState([])
  const [supplierList, setSupplierList] = useState([])

  let id = editState?.id

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    reset,
    getValues,
    formState: { errors }
  } = methods

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  function onSubmit(data) {
    setError(false)
    setBtnLoading(false)
    let bodyData = data
    let url = id ? `item-stock/${id}` : 'item-stock'

    if (state === 'Edit') {
      AxiosHelper.put(url, bodyData)
        .then(response => {
          setBtnLoading(false)
          handleModalClose()
          toast.success('Stock Updated Successfully')
          getStocks()
        })
        .catch(error => {
          handlerError(error)
          setBtnLoading(false)
        })
      return
    }
    AxiosHelper.post(url, bodyData)
      .then(response => {
        setBtnLoading(false)
        toast.success('Stock Added Successfully')

        handleModalClose()
        getStocks()
      })
      .catch(error => {
        handlerError(error)
        setBtnLoading(false)
      })
  }

  const getManufacturer = () => {

    let url = `manufacture`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        let arr = response.data.data
        const manufactureLabels = arr?.map(item => {
          return {
            label: item?.name,
            id: item?.id
          }
        })
        setManufactureList(manufactureLabels)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const getSuppliers = () => {
    AxiosHelper.get('supplier')
      .then(response => {
        let arr = response.data.data
        const supplierLabels = arr?.map(item => {
          return {
            label: item?.name,
            id: item?.id
          }
        })
        setSupplierList(supplierLabels)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handlerError = error => {
    setLoading(false)
    setMessage(error)
    setError(true)
  }

  const fillFormEditData = () => {
    let item = editState

    reset({
      name: item?.name || '',
      manufacture_id: item?.manufacture_id || '',
      supplier_id: item?.supplier_id || '',
      batch_no: item?.batch_no || '',
      invoice_no: item?.invoice_no || '',
      expiry_date: dayjs(item?.expiry_date) || '',
      receipt_date: dayjs(item?.receipt_date) || '',
      price: item?.price || '',
      quantity: item?.quantity || '',
      lot_number: item?.lot_number || '',
      type: Number(item?.type) || ''
    })
  }

  useEffect(() => {
    if (state === 'Edit') {
      fillFormEditData()
    }
  }, [state])

  useEffect(() => {
    getManufacturer()
    getSuppliers()
  }, [])

  function handleModalClose() {
    setOpenModal(false)
  }

  if (isLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <ModalCustom
      title={`${state} Store Item`}
      open={openModal}
      size='md'
      dialogStyle={{
        px: 2,
        pt: 0,
        pb: 0
      }}
      closeDialog={handleModalClose}
      actionComponent={
        <ModalActions>
          <div className='d-flex justify-content-end align-items-end p-3 '>
            <button type='button' className='btn backBtn mr-2 ' onClick={handleModalClose}>
              Cancel
            </button>
            <LoadingButton onClick={handleSubmit(onSubmit)} isLoading={isLoading} title='Submit' />
          </div>
        </ModalActions>
      }
    >
      <div className='my-auto'>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Type{RequiredField()}</FormLabel>
              <RHFAutoComplete
                name='type'
                options={typeOptions}
                size='small'
                placeholder='Select type'
                fullWidth
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Item Name{RequiredField()}</FormLabel>
              <RHFAutoComplete
                name='name'
                options={items}
                size='small'
                placeholder='Enter Item Name'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Manufacture Name{RequiredField()}</FormLabel>
              <RHFAutoComplete
                freeSolo
                name='manufacture_id'
                options={manufactureList}
                size='small'
                placeholder='Enter Manufacture Name'
                fullWidth
                onInputChange={(e, newInputValue, reason) => {
                  if (reason === 'reset') return
                  if (e.target.value) {
                    setValue('manufacture_name', e.target.value)
                    setValue('manufacture_id', 'other')
                    return
                  }
                  setValue('manufacture_name', '')
                  setValue('manufacture_id', '')
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Supplier Name{RequiredField()}</FormLabel>
              <RHFAutoComplete
                freeSolo
                name='supplier_id'
                onInputChange={(e, newInputValue, reason) => {
                  if (reason === 'reset') return
                  if (e.target.value) {
                    setValue('supplier_name', e.target.value)
                    setValue('supplier_id', 'other')
                    return
                  }
                  setValue('supplier_id', '')
                  setValue('supplier_name', '')
                }}
                options={supplierList}
                size='small'
                placeholder='Enter Supplier Name'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Quantity{RequiredField()}</FormLabel>
              <RHFTextField
                placeholder='Enter Quantity'
                name='quantity'
                size='small'
                type='number'
                fullWidth
                sx={inputStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Price</FormLabel>
              <RHFTextField
                placeholder='Enter Price'
                name='price'
                type='number'
                size='small'
                fullWidth
                sx={inputStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Invoice No.</FormLabel>
              <RHFTextField name='invoice_no' size='small' placeholder='Enter Invoice No.' fullWidth sx={inputStyles} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Batch No.</FormLabel>
              <RHFTextField name='batch_no' size='small' placeholder='Enter Batch No.' fullWidth sx={inputStyles} />
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Date of Receipt</FormLabel>
              <RHFDatePicker name='receipt_date' sx={inputStyles} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Expiry Date</FormLabel>
              <RHFDatePicker disablePast name='expiry_date' sx={inputStyles} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormLabel sx={labelStyles}>Lot Number</FormLabel>
              <RHFTextField placeholder='Enter Lot Number' name='lot_number' size='small' fullWidth sx={inputStyles} />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={error}>
                <Alert severity='error' onClose={() => setError(false)} dismissible>
                  {message || 'Something Went Wrong on our side'}
                </Alert>
              </Collapse>
            </Grid>
            {/* <Grid item xs={12}>
              <div className="d-flex" style={{ gap: "10px" }}>
                <button type="button" className="btn backBtn ">
                  Cancel
                </button>

                <LoadingButton
                  isLoading={btnLoading}
                  title={"Submit"}
                  type="submit"
                />
              </div>
            </Grid> */}
          </Grid>
        </FormProvider>
      </div>
    </ModalCustom>
  )
}

export default ItemForm

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../common/BreadCrumb'
import { useParams, useHistory } from 'react-router-dom'
import AxiosHelper from '../../helper/AxiosHelper'
import { radioStyles } from '../blood-camp-management/addBloodCamp/AddBloodCamp'
import { Checkbox, CircularProgress, FormHelperText, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import Loader from '../shared/Loader'
import ScrollCard from '../shared/ScrollCard'
import ScrollBar from 'react-perfect-scrollbar'
import DonorTop from '../common/DonorTop'
import ValidateBags from '../bulk-inventory/NewInventory/components/ValidateBags'
import BloodTable from '../bulk-inventory/NewInventory/components/BloodTable'
import AddSegment from '../bulk-inventory/NewInventory/components/AddSegment'

export const bloodComponents = [
  {
    error: false,
    selected: false,
    expiry_days: '21',
    expiry_date: dayjs(new Date()).add(21, 'day'),
    standard_volume: '450',
    volume_range: '',
    volume: '',
    component: 'Whole Blood (CPD)'
  },
  {
    error: false,
    selected: false,
    expiry_days: '35',
    expiry_date: dayjs(new Date()).add(35, 'day'),
    standard_volume: '450',
    volume_range: '',
    volume: '',
    component: 'Whole Blood (CPDA-1)'
  },
  {
    error: false,
    selected: false,
    expiry_days: '42',
    expiry_date: dayjs(new Date()).add(42, 'day'),
    standard_volume: '220',
    volume_range: '250 - 350',
    volume: '',
    component: 'Packed Red Blood Cells'
  },
  {
    error: false,
    selected: false,
    expiry_days: '365',
    expiry_date: dayjs(new Date()).add(1, 'year'),
    standard_volume: '225',
    volume_range: '200 - 250',
    volume: '',
    component: 'Fresh Frozen Plasma'
  },
  {
    error: false,
    selected: false,
    expiry_days: '5',
    expiry_date: dayjs(new Date()).add(5, 'day'),
    standard_volume: '80',
    volume_range: '',
    volume: '',
    component: 'Single Donor Platelet'
  },
  {
    error: false,
    selected: false,
    expiry_days: '365',
    expiry_date: dayjs(new Date()).add(1, 'year'),
    standard_volume: '25',
    volume_range: '',
    volume: '',
    component: 'Cryoprecipitate'
  },
  {
    error: false,
    selected: false,
    expiry_days: '365',
    expiry_date: dayjs(new Date()).add(1, 'year'),
    standard_volume: '200',
    volume_range: '170 - 220',
    volume: '',
    component: 'Plasma'
  },
  {
    error: false,
    selected: false,
    expiry_days: '5',
    expiry_date: dayjs(new Date()).add(5, 'day'),
    standard_volume: '60',
    volume_range: '50 - 70',
    volume: '',
    component: 'Platelet Concentrate'
  },
  {
    error: false,
    selected: false,
    expiry_days: '365',
    expiry_date: dayjs(new Date()).add(20, 'day'),
    standard_volume: '20',
    volume_range: '',
    volume: '',
    component: 'Cryo Poor Plasma'
  },
  {
    error: false,
    selected: false,
    expiry_days: '5',
    expiry_date: dayjs(new Date()).add(5, 'day'),
    standard_volume: '250',
    volume_range: '200 - 300',
    volume: '',
    component: 'Random Donor Platelets'
  },
  {
    error: false,
    selected: false,
    expiry_days: '5',
    expiry_date: dayjs(new Date()).add(5, 'day'),
    standard_volume: '',
    volume_range: '',
    volume: '',
    component: 'Platelets additive solutions'
  }
]

const AddInventory = () => {
  const { id } = useParams()
  const [donorHistory, setDonorHistory] = useState(null)
  const [sectionInfo, setSectionInfo] = useState([])

  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bagIssueData, setBagIssueData] = useState(bloodComponents)
  const [todayBag, setTodayBag] = useState({})

  const [currentTab, setCurrentTab] = useState(1)
  const [progressCount, setProgressCount] = useState(null)
  const history = useHistory()

  const getHistory = async () => {
    setPageLoading(true)
    await AxiosHelper.get(`donor/history/${id}`)
      .then(response => {
        let res = response?.data?.data
        setDonorHistory(res)

        let arr = []
        if (res?.DonorScreenings) {
          for (let i of res?.DonorScreenings) {
            if (i?.BloodBagIssueEntity) {
              arr.push(i?.BloodBagIssueEntity)
            }
          }
        }
        const allocatedComponents = arr[arr?.length - 1]?.BloodLogsEntities.map(item => {
          return item?.component
        })
        const updatedBloodComponents = bloodComponents?.filter(item => {
          return !allocatedComponents?.includes(item.component)
        })
        setBagIssueData(updatedBloodComponents)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
      })
  }

  const getTodayBag = () => {
    setPageLoading(true)
    AxiosHelper.get(`donor/today-blood-bag/${id}`)
      .then(response => {
        setTodayBag(response.data.data)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getHistory()
    getTodayBag()
  }, [])

  useEffect(() => {
    if (donorHistory && bagIssueData?.length) {
      // expiry_days

      const expiryNewDate = bagIssueData.map(item => {
        let obj = {
          ...item,
          expiry_date: dayjs(donorHistory?.donation_date).add(item?.expiry_days, 'day')
        }

        return obj
      })

      setBagIssueData(expiryNewDate)
    }
  }, [donorHistory])

  const breadcrumbRoute = [
    {
      path: '/donors',
      label: 'Donors'
    },
    {
      path: '',
      label: 'Donor Inventory'
    },
    {
      path: '/donors-detail/donor-inventory/' + id,
      label: 'Add'
    }
  ]

  const handleChangeAll = (e, index) => {
    let newValue = bagIssueData?.map((data, i) => {
      if (i === index) {
        let obj = {
          ...data,
          [e.target.name]: e.target.name === 'selected' ? e.target.checked : e.target.value
        }
        if (e.target.name === 'selected' && !e.target.checked) {
          obj.volume = ''
        }

        return obj
      }

      return data
    })
    setBagIssueData(newValue)
  }

  const handleSubmit = () => {
    let newArr = []
    let errorArr = []

    for (let i of bagIssueData) {
      errorArr.push({
        ...i,
        error: i.selected && i.volume === '' ? true : false
      })

      if (i.selected) {
        newArr.push({
          expiry_days: i.expiry_days,
          expiry_date: i.expiry_date,
          standard_volume: i.standard_volume,
          volume_range: i.volume_range,
          volume: i.volume,
          component: i.component
        })
      }
    }

    setBagIssueData(errorArr)
    let valid = false
    newArr.forEach(element => {
      if (!element.volume) {
        valid = true
        toast.error('Please fill all the fields!')
      }
    })
    if (valid) return
    if (newArr.length === 0) {
      return toast.error('Cannot submit empty data!')
    }
    setLoading(true)
    AxiosHelper.post('blood-stock', {
      blood: newArr,
      blood_bag_id: todayBag?.id
    })
      .then(response => {
        toast.success('Blood Component added successfully!')
        setLoading(false)
        history.push('/donors-detail/donor-inventory/' + donorHistory.id)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
        toast.error(error || 'Something went wrong our side!')
      })
  }

  function redirectDonor(){
    history.push('/donors-detail/donor-inventory/' + donorHistory.id)
  }

  useEffect(() => {
    let topinfo = []
    if (donorHistory) {
      topinfo = [
        { label: 'Donor-ID', value: donorHistory?.reg_id || 'N/A' },
        {
          label: 'Mobile',
          value: donorHistory?.mobile ? <>+91 {donorHistory?.mobile}</> : 'N/A'
        },
        {
          label: 'Donation Date',
          value: donorHistory?.donation_date ? dayjs(donorHistory?.donation_date).format('DD-MMM-YYYY') : 'N/A'
        },
        { label: 'ABHA Id', value: donorHistory?.abha_id || 'N/A' }
      ]
    }
    setSectionInfo(topinfo)
  }, [donorHistory])

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />

      <ScrollCard>
        <DonorTop info={sectionInfo} basicInfo={donorHistory} />
        {/* <div className='bloodbank_action  '>
          <p>Add Blood Component</p>
        </div>

        <div className='table-responsive mt-2'>
          <ScrollBar>
            <table className='table  table-hover'>
              <thead>
                <tr className='table_header'>
                  <th>Select Component</th>
                  <th>Expiry Days</th>
                  <th>Collection Date</th>
                  <th>Date of Expiry</th>
                  <th>Standard Volume (ML)</th>
                  <th>Volume Range (ML)</th>
                  <th>Extracted Volume (ML)</th>
                  <th>Unit</th>
                </tr>
              </thead>
              <tbody className='inventory_tab'>
                {bagIssueData?.length > 0
                  ? bagIssueData?.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <Checkbox
                            sx={radioStyles}
                            value={item?.selected}
                            onChange={e => handleChangeAll(e, idx)}
                            name='selected'
                          />
                          {item.component || '-'}
                        </td>
                        <td>{item?.expiry_days || '-'}</td>
                        <td>
                          {donorHistory?.donation_date ? dayjs(donorHistory?.donation_date).format('DD-MMM-YYYY') : '-'}
                        </td>
                        <td>{dayjs(item?.expiry_date).format('DD/MM/YYYY') || '-'}</td>
                        <td>{item?.standard_volume || '-'}</td>
                        <td>{item?.volume_range ? item?.volume_range : '-'}</td>
                        <td>
                          {item?.selected ? (
                            <>
                              <TextField
                                sx={{ maxWidth: 130 }}
                                name='volume'
                                type='number'
                                value={item?.volume}
                                onChange={e => handleChangeAll(e, idx)}
                                size='small'
                                error={item?.error}
                              />
                              {item?.error ? (
                                <FormHelperText sx={{ color: 'error.main' }}>Please fill this field</FormHelperText>
                              ) : null}
                            </>
                          ) : (
                            item?.volume || '-'
                          )}
                        </td>
                        <td>{item?.selected && item?.volume.length > 0 ? '1' : ''}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </ScrollBar>
        </div>

        <div className='d-flex mt-2' style={{ gap: '10px' }}>
          <button
            className='btn backBtn '
            onClick={() => {
              history.push(`/donor-inventory/${id}`)
            }}
          >
            Cancel
          </button>
          <button disabled={loading} onClick={handleSubmit} className='btn gradient-btn'>
            {loading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
          </button>
        </div> */}

        <div className='tab-container-gen mt-2'>
          <button
            type='button'
            onClick={() => {
              setCurrentTab(1)
            }}
            className={`tab-gen-option-btn ${currentTab === 1 ? 'tab-gen-option-btn-active' : ''}`}
          >
            Blood Processing
          </button>

          <button
            type='button'
            onClick={() => {
              setCurrentTab(2)
            }}
            className={`tab-gen-option-btn ${currentTab === 2 ? 'tab-gen-option-btn-active' : ''}`}
          >
            Volume Measurement
          </button>
          <button
            type='button'
            onClick={() => {
              setCurrentTab(3)
            }}
            className={`tab-gen-option-btn ${currentTab === 3 ? 'tab-gen-option-btn-active' : ''}`}
          >
            Validation
          </button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}></div>
        {currentTab === 3 ? <ValidateBags setProgressCount={setProgressCount} setCurrentTab={setCurrentTab} bag_number={todayBag?.bag_number} redirectDonor={redirectDonor} /> : null}
        {currentTab === 2 ? <BloodTable setProgressCount={setProgressCount} setCurrentTab={setCurrentTab} bag_number={todayBag?.bag_number}/> : null}
        {currentTab === 1 ? <AddSegment setProgressCount={setProgressCount} setCurrentTab={setCurrentTab} bag_number={todayBag?.bag_number} /> : null}
      </ScrollCard>
    </div>
  )
}

export default AddInventory

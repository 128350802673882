import React, { useEffect, useRef, useState } from 'react'

import { IconButton, Tooltip } from '@mui/material'
import { Download, Print } from '@mui/icons-material'
import BloodCampPostReport from './BloodCampPostReport'
import AxiosHelper from '../../../helper/AxiosHelper'
import Loader from '../../shared/Loader'
import { downLoadFiles } from '../../middleware/auth'
import { useParams } from 'react-router-dom'
import PrintWrapperWithoutButton from '../../print-components/PrintWrapperWithoutButton'

const active = {
  background: 'var(--default, linear-gradient(89deg, #204E89 0%, #12B9AA 100%))',
  color: '#FFFFFF',
  borderRadius: '0'
}

const PostReport = ({ data, camp_id }) => {
  const [reportData, setReportData] = useState({})
  console.log(camp_id)
  const [pageLoading, setPageLoading] = useState(false)

  const previewRef = useRef()

  const { id } = useParams()

  function printAll(elementId, uniqueIframeId) {
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
      pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
      const iframe = document.createElement('iframe')
      iframe.setAttribute('title', uniqueIframeId)
      iframe.setAttribute('id', uniqueIframeId)
      iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
      document.body.appendChild(iframe)
      pri = iframe.contentWindow
    }
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
  }

  const getReportData = () => {
    setPageLoading(true)

    AxiosHelper.get(`report/post-camp-report/${id || camp_id}`)
      .then(resp => {
        setReportData(resp?.data?.data)
        setPageLoading(false)
      })
      .catch(err => {
        console.log(err)
        setPageLoading(false)
      })
  }

  useEffect(() => {
    getReportData()
  }, [])

  useEffect(() => {
    getReportData()
  }, [camp_id])

  async function downloadPdfDocument(filename) {
    let url = `/report/post-camp-report/${id || camp_id}?download=true`
    downLoadFiles(url, filename)
  }

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <>
      <div className='bloodbank_action'>
        <p>Post Camp Report</p>
        <div className='d-flex' style={{ gap: '10px' }}>
          <Tooltip title='Print'>
            <IconButton
              onClick={() => {
                console.log('previewRef', previewRef)
                if (previewRef?.current) {
                  previewRef.current.click()
                }
                //  printAll('report_pdf', 'report_iframe')
              }}
              color='primary'
              sx={{ ...active, borderRadius: 1 }}
            >
              <Print />
            </IconButton>
          </Tooltip>

          <Tooltip title='Download'>
            <IconButton
              color='primary'
              sx={{ ...active, borderRadius: 1 }}
              onClick={() => {
                console.log('download clicked!!')
                downloadPdfDocument('PostCampReport')
              }}
            >
              <Download />
            </IconButton>
          </Tooltip>

          {/* <IconButton
            color="primary"
            sx={{ ...active, borderRadius: 1 }}
            //   onClick={() => setEditTab(true)}
          >
            <img
              src={"/assets/images/reports/Vector (1).svg"}
              alt="Blood Bag"
            />
          </IconButton> */}

          <IconButton
            color='primary'
            sx={{ ...active, borderRadius: 1 }}
            //   onClick={() => setEditTab(true)}
          >
            <a
              href={`mailto:email@example.com?subject='Reports!'&body=Report Link -${`/print-pages/camp/${data?.id}`}`}
            >
              <img src={'/assets/images/reports/Group.svg'} alt='Blood Bag' />
            </a>
          </IconButton>
        </div>
      </div>
      <PrintWrapperWithoutButton ref={previewRef}>
        <BloodCampPostReport id='report_pdf' reportData={reportData} />
      </PrintWrapperWithoutButton>
    </>
  )
}

export default PostReport

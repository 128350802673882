import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const CerificateMarqueItem = ({ icon, title = '', url, type = 'Bank' }) => {
  const history = useHistory()
  return (
    <section className='dash-marquee-item px-1'>
        <div className='marquee-icon-div' >

      <img src={icon} className='marquee-icon' alt='' />
        </div>
      <p className='marquee-slogan'>
        <span className='marquee-title'>"{title}"</span> Blood {type} Certificate not attached yet, <br />
        <span className='marquee-link' onClick={() => history.push(url || `/certification`)}>
          Click to attach
        </span>
      </p>
    </section>
  )
}

export default CerificateMarqueItem

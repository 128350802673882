import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom/'

const ActivityItem = ({ item }) => {
  const history = useHistory()

  return (
    <TimelineItem sx={{ cursor: 'pointer' }}>
      <TimelineSeparator>
        <TimelineDot sx={{ background: '#214F8A' }}>
          <img
            style={{ width: '16px', height: '16px', objectFit: 'contain' }}
            alt=''
            src='/assets/images/dashboard/activityIcon.svg'
          />
        </TimelineDot>
        <TimelineConnector sx={{ background: '#214F8A' }} />
      </TimelineSeparator>
      <TimelineContent
        sx={{ py: '6px', px: 2, }}
        onClick={() => history.push(item?.redirectUrl || '/activityrecord')}
      >
        <Typography className='activity_das_title_new'>
          {item?.user_name} - System Admin
          {/* {item?.userInfo['UserDetailsEntity.RolesEntity.name'] || 'Technician'} */}
        </Typography>{' '}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: { sm: 'flex-start', md: 'space-between' },
            flexDirection: { sm: 'column', md: 'row' },
            alignItems: 'center'
          }}
        >
          {' '}
          <Typography className='activity_das_desc_new'>{item?.message || 'change'}</Typography>
          <Typography className='activity_das_desc_new' sx={{ color: '#6B7280', minWidth: 'max-content' }}>
            {dayjs(item?.created_at).get('day')} days ago
          </Typography>
        </Box>
      </TimelineContent>
    </TimelineItem>
  )
}

export default ActivityItem

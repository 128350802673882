import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import AxiosHelper from '../../../helper/AxiosHelper'
import CardHeading, { endOfMonthDefault, startOfMonthDefault } from './cardHeading'
import { DashboardNoDat } from './WasteChart'
import { checkTenantId } from '../../../helper/CommonAPI'

const labels = ['Blood Issues', 'Payments', 'Sample Re-grouping', 'Cross Matching']

const BloodRequistionChart = () => {
  const [apiData, setApiData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(startOfMonthDefault)
  const [endDate, setEndDate] = useState(endOfMonthDefault)
  const [modalData, setModalData] = useState([])
  const [updatedDate, setUpdatedDate] = useState(null)
  const [chartData, setChartData] = useState({
    series: [20, 20, 20, 60, 20],
    options: {
      chart: {
        type: 'donut'
      },
      stroke: { width: 0 },
      labels: labels,
      colors: ['#4E79A7', '#E15759', '#F28E2B', '#59A14F'],
      dataLabels: {
        enabled: true,
        formatter: (val, opts) => {
          return opts.w.globals.series[opts.seriesIndex]
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'Helvetica, Arial',
        labels: {
          colors: ['#000'],
          useSeriesColors: false
        },
        markers: {
          width: 12,
          height: 20, // Increase height to make it rectangular
          radius: 2 // Small radius for slightly rounded corners
        },
        itemMargin: {
          horizontal: 4,
          vertical: 5
        }
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '50%', // Increase the width of the donut by decreasing this value
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                fontSize: '20px',
                fontWeight: 600,
                color: '#000',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                }
              }
            }
          }
        }
      }
    }
  })

  const getDashboardInfo = () => {
    setLoading(true)
    const URL=checkTenantId(`dashboard/request-statistics?startDate=${startDate || ''}&endDate=${endDate || ''}`)
    AxiosHelper.get(URL)
      .then(resp => {
        if (resp?.data?.data) {
          let resData = resp?.data?.data
          setApiData(resData)
          if (Object.keys(resData)?.length) {
            let array = [resData?.bloodIssue, resData?.payment, resData?.bloodGrouping, resData?.crossmatch]
            setChartData({
              ...chartData,
              series: array
            })
            let newArray = labels?.map((item, index) => {
              return {
                label: item,
                value: array[index] || '0'
              }
            })
            setModalData(newArray)
          }
        }
        setUpdatedDate(new Date())
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    getDashboardInfo()
  }, [startDate, endDate])

  let total = apiData ? apiData?.bloodIssue + apiData?.payment + apiData?.bloodGrouping + apiData?.crossmatch : 0

  return (
    <>
      <CardHeading
        path='/blood-requisition'
        size='sm'
        fetchFunction={getDashboardInfo}
        title='Blood Requisition'
        count={total || 0}
        refreshDataApi={getDashboardInfo}
        loading={loading}
        setStartDateCustom={setStartDate}
        setEndDateCustom={setEndDate}
        modalData={modalData}
        updatedDate={updatedDate}
      />

      {total == 0 ? (
        <DashboardNoDat />
      ) : (
        <ReactApexChart options={chartData.options} series={chartData.series} type='donut' height={350} />
      )}
    </>
  )
}

export default BloodRequistionChart

import { Card, Collapse, FormLabel, Menu, MenuItem, Select, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import LoadingButton from '../../shared/LoadingButton'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import AxiosHelper from '../../../helper/AxiosHelper'
import BreadCrumb from '../../common/BreadCrumb'
import Loader from '../../shared/Loader'
import ScrollCard from '../../shared/ScrollCard'
import { ColumnDetail, LabelDiv } from '../../screening/ScreeningDonorDetails'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import PaginationLink from '../../pagination/PaginationLink'
import { checkTenantId, debouncing } from '../../../helper/CommonAPI'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ProfileBagRedirect } from '../../donors/profile/utils/shared/profile-bag-redirect'
import { ComponentLabelsCompFn } from '../../shared/ComponentLabelsComp'

const bloodGroup = {
  'AB-': 'AB-Ve',
  'AB+': 'AB+Ve',
  'A+': 'A+Ve',
  'A-': 'A-Ve',
  'B+': 'B+Ve',
  'B-': 'B-Ve',
  'O-': 'O-Ve',
  'O+': 'O+Ve',
  'OH-': 'OH-Ve',
  'OH+': 'OH+Ve'
}

const TableRow = ({ bag }) => {
  return (
    <tr>
      <td valign='top'>
        {bag?.collection_date
          ? moment(bag?.collection_date).format('DD-MMM-YYYY')
          : moment(bag?.createdAt).format('DD-MMM-YYYY') || '-'}
      </td>
      <td>
        {' '}
        <ProfileBagRedirect bag_id={bag?.BloodBagIssueEntity?.id}>
          {bag?.BloodBagIssueEntity?.bag_number || '-'}
        </ProfileBagRedirect>
      </td>
      <td>{bag?.BloodBagIssueEntity?.tube_number || '-'}</td>
      <td>{bag?.BloodBagIssueEntity?.bag_type || '-'}</td>
      <td>{bag?.volume || '-'}</td>
      <td>
        <p className='chip_custom'>
          {bag?.component ? ComponentLabelsCompFn(bag?.component) : '-'}({bag?.volume})
        </p>
      </td>
      <td>{moment(bag?.expiry_date).format('DD-MMM-YYYY')}</td>
      <td>{bag?.expiry_date ? <>{moment(bag?.expiry_date, 'YYYY-MM-DD').diff(moment(), 'days') + 1}</> : 'N/A'}</td>
    </tr>
  )
}

// /blood-stock?stocktype=camp&search=whole&expiry=2&inventorytype=all
const initialFil = {
  stocktype: 'all',
  search: '',
  expiry: '',
  inventorytype: 'all',
  search_main: ''
}

function BloodStockDetail() {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [isFiltered, setIsFiltered] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [filters, setFilters] = useState(initialFil)
  const [searchquery, setSearchQuery] = useState('')
  const { id, comp } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const inventorytype = queryParams.get('inventorytype')
  console.log('queryParams', inventorytype)

  const handleFilterChange = e => {
    let checkValues = {
      ...filters,
      [e.target.name]: e.target.value
    }
    setFilters(checkValues)
    if (e.target.name === 'search_main') {
      handleApply(checkValues)
    }
  }

  const updateAll = useCallback(
    debouncing(bgnumber => {
      getBloodStock(bgnumber)
    }, 500),
    []
  )

  const getBloodStock = search => {
    setLoading(true)
    const URL = checkTenantId(`blood-stock/component-details?${inventorytype && `inventorytype=${inventorytype}`}`)
    AxiosHelper.post(URL, {
      type: id,
      component: comp,
      pageSize: 10,
      search: search,
      page: page
      // daysUntilExpiry: "12",
    })
      .then(resp => {
        setData(resp.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getBloodStock()
  }, [page])

  // console.log('col', columns, data)

  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    },
    {
      path: '/blood-stock',
      label: 'Blood Stock'
    }
  ]

  const openMenu = event => {
    setAnchor(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchor(null)
  }

  function handleApply(dataa, type) {
    setBtnLoading(true)
    let url = `blood-stock?stocktype=${dataa?.stocktype}&search=${
      dataa?.search || dataa?.search_main || ''
    }&expiry=${dataa?.expiry}&inventorytype=${dataa?.inventorytype}`
    AxiosHelper.get(url)
      .then(resp => {
        setData(resp.data.data)

        setBtnLoading(false)
        setAnchor(null)
        if (type !== false) {
          setIsFiltered(true)
        }
      })
      .catch(error => {
        setBtnLoading(false)
        console.log(error)
      })
  }

  function resetFilter() {
    setFilters(initialFil)
    handleApply(initialFil, false)
    setIsFiltered(false)
  }

  function removeFilters(name) {
    let checkVal = {
      ...filters,
      [name]: ''
    }
    setFilters(checkVal)
    handleApply(checkVal)
  }

  return (
    <div>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />
      <Loader isLoading={isLoading} />
      <ScrollCard>
        <LabelDiv title='Blood Bag Detail'>
          <div className='row'>
            <ColumnDetail
              label='Blood Group'
              title={id || 'B+'}
              // err={
              //   previewData?.hemoglobin_level < 12.5 || previewData?.hemoglobin_level > 20
              // }
            />
            <ColumnDetail
              label='Component'
              title={comp || 'N/A'}
              // err={
              //   previewData?.hemoglobin_level < 12.5 || previewData?.hemoglobin_level > 20
              // }
            />
            {/* <ColumnDetail
              label="Inventory Type"
              title={"-" || "N/A"}
              // err={
              //   previewData?.hemoglobin_level < 12.5 || previewData?.hemoglobin_level > 20
              // }
            /> */}
            {/* <ColumnDetail
              label="Total"
              title={"N/A"}
              // err={
              //   previewData?.hemoglobin_level < 12.5 || previewData?.hemoglobin_level > 20
              // }
            /> */}
          </div>
        </LabelDiv>

        <div className='search-and-filter-container align-items-center'>
          <div className='search-container custom-height'>
            <input
              name='search_main'
              value={searchquery}
              onChange={e => {
                setSearchQuery(e.target.value)
                updateAll(e.target.value)
              }}
              className='search-input inputSize '
              type='text'
              placeholder='Search by bag number'
            />
            <img
              src={'../../../assets/images/search-icon.svg'}
              id='basic-button'
              // onClick={() => {
              //   setFilterbtn(!filterbtn);
              // }}
              // onClick={openMenu}
              alt='search-icon'
            />
          </div>
        </div>
        <Menu
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={closeMenu}
          keepMounted
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}

          //TransitionComponent={Slide}
        >
          {' '}
          <div className='fltr-container' style={{ width: '33rem', height: '330px' }}>
            <div className='row mx-widthh'>
              <div className='fltrlabel-div'>
                <FormLabel sx={labelStyles}>Inventory Type</FormLabel>
              </div>
              <div className='col-sm-12   col-md-8'>
                <Select
                  sx={inputStyles}
                  fullWidth
                  select
                  size='small'
                  placeholder='Enter Inventory Type'
                  name='inventorytype'
                  value={filters?.inventorytype}
                  onChange={handleFilterChange}
                >
                  <MenuItem value='all'>All</MenuItem>
                  <MenuItem value='1'>Free for Issue</MenuItem>
                  <MenuItem value='2'>Buffer Stock</MenuItem>
                  <MenuItem value='3'>Reserved & Free for Issue</MenuItem>
                  <MenuItem value='4'>Reserved for Issue</MenuItem>
                </Select>
              </div>
            </div>
            <div className='row mx-widthh'>
              <div className='fltrlabel-div'>
                <FormLabel sx={labelStyles}>Source of Stock</FormLabel>
              </div>
              <div className='col-md-8'>
                <Select
                  sx={inputStyles}
                  fullWidth
                  select
                  placeholder='Enter Stock Type'
                  name='stocktype'
                  size='small'
                  value={filters?.stocktype}
                  onChange={handleFilterChange}
                >
                  <MenuItem value='all'>All</MenuItem>
                  <MenuItem value='loanbank'>Loan Bank</MenuItem>
                  <MenuItem value='bloodbank'>Blood Bank</MenuItem>
                  <MenuItem value='camp'>Camp</MenuItem>
                </Select>
              </div>
            </div>
            <div className='row mx-widthh'>
              <div className='fltrlabel-div'>
                <FormLabel sx={labelStyles}>Stock Expiry</FormLabel>
              </div>
              <div className='col-md-8'>
                <Select
                  sx={inputStyles}
                  fullWidth
                  select
                  size='small'
                  placeholder='Enter District'
                  inputProps={{
                    placeholder: 'Select Expiry'
                  }}
                  name='expiry'
                  value={filters?.expiry}
                  onChange={handleFilterChange}
                >
                  <MenuItem value='2'>In 2 Days</MenuItem>
                  <MenuItem value='3'>In 3 Days</MenuItem>
                  <MenuItem value='5'>In 5 Days</MenuItem>
                  <MenuItem value='9'>In 9 Days</MenuItem>
                </Select>
              </div>
            </div>

            <div className='row mx-widthh'>
              <div className='fltrlabel-div'>
                <FormLabel sx={labelStyles}>Text</FormLabel>
              </div>
              <div className='col-md-8'>
                <TextField
                  sx={inputStyles}
                  fullWidth
                  type='text'
                  size='small'
                  placeholder='Enter Text'
                  name='search'
                  value={filters?.search}
                  onChange={handleFilterChange}
                />
              </div>
            </div>

            <div className='row fltraction'>
              <LoadingButton
                //   isLoading={btnLoading}
                //   onClick={() => handleApply(filters)}
                title='Apply Filter'
              />

              <button
                className='btn backBtn '
                // onClick={resetFilter}
              >
                Reset
              </button>
            </div>
          </div>
        </Menu>

        {/* <Collapse in={isFiltered}>
        <div
          style={{ gap: "10px" }}
          className="d-flex flex-wrap align-items-center pb-3"
        >
          {filters?.stocktype ? (
            <span className="filter-tab-div ">
              {filters?.stocktype}
              <HighlightOffIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  color: "#214F8A",
                }}
                onClick={() => removeFilters("stocktype")}
              />
            </span>
          ) : null}
          {filters?.expiry ? (
            <span className="filter-tab-div">
              {filters?.expiry}
              <HighlightOffIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  color: "#214F8A",
                }}
                onClick={() => removeFilters("expiry")}
              />
            </span>
          ) : null}
          {filters?.inventorytype ? (
            <span className="filter-tab-div">
              {filters?.inventorytype}
              <HighlightOffIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  color: "#214F8A",
                }}
                onClick={() => removeFilters("inventorytype")}
              />
            </span>
          ) : null}

          <div
            style={{
              color: "#214F8A",
              fontSize: "1rem",
              cursor: "pointer",
            }}
            onClick={resetFilter}
          >
            Clear Filter
          </div>
        </div>
      </Collapse> */}

        <Card
          sx={{
            mt: 3,
            boxShadow: '0px 4px 14px 0px #00000024'
          }}
        >
          <div className='table-responsive stock_table pt-3'>
            <ScrollBar>
              <table className='table  table-hover'>
                <thead>
                  <tr className='table_header'>
                    <th>Collection Date</th>
                    <th>Bag Number</th>
                    <th>Segment Number</th>
                    {/* <th>Bag Number</th> */}
                    <th>Bag Type</th>
                    <th>Volume</th>
                    <th>Blood Component (ml)</th>
                    <th>Expiry Date</th>
                    <th>No. of Days Left</th>
                  </tr>
                </thead>
                <tbody className='inventory_tab'>
                  {data?.items?.length > 0 ? data?.items?.map((item, idx) => <TableRow bag={item} key={idx} />) : null}
                </tbody>
              </table>
            </ScrollBar>
          </div>
          {console.log('data.totalPage', data.totalPage)}
          {data && data.totalPages > 0 && (
            <div className='float-right mt-1'>
              <PaginationLink
                pagination={{ totalPage: data.totalPages }}
                getNext={page => {
                  setPage(page)
                }}
              />
            </div>
          )}
        </Card>
      </ScrollCard>
    </div>
  )
}

export default BloodStockDetail

import React from 'react';

function ProfileProgress({ value, img, children }) {
  return (
    <div
      className="profile_progress"
      style={{
        background: `radial-gradient(closest-side, #d3dce8 93%, transparent 80% 100%),
                        conic-gradient(from 180deg,#214f81 0%,#214f81 0% ${value}, #d3dce8  ${value}, #d3dce8  100%)`,
      }}
    >
      {/* <img
        src={img || "/assets/images/donor_profile/profile.png"}
        alt="profile-pic"
      /> */}
      {children ? (
        <div className="children">{children}</div>
      ) : (
        <img src={img || '/assets/images/donor_profile/profile.png'} alt="profile-pic" />
      )}
      <div className="valuess">{value}</div>
    </div>
  );
}

export default ProfileProgress;

import React from 'react'
import PrintHeader from '../../print-components/PrintHeader'
import { IconButton, Tooltip } from '@mui/material'
import { active } from '../../blood-requisition/Filters'
import {  Download, Print } from '@mui/icons-material'
import {  printAll } from '../../../helper/CommonAPI'
import { usePDF, Margin } from 'react-to-pdf'

export const QCReportPrint = ({ Data, id, children }) => {
  const { toPDF, targetRef } = usePDF({
    filename: 'qc-Report.pdf',
    page: {
      margin: Margin.SMALL,
      format: 'A4'
    }
  })
  const tdStyle = {
    borderRight: '1px solid #e9edf3',
    borderBottom: '1px solid #e9edf3',
    height: '40px',
    fontFamily: 'Inter',
    fontSize: '0.8rem',
    lineHeight: '15px',
    letterSpacing: '0px',
    textAlign: 'left',
    fonWeight: '500',
    padding: '5px'
  }

  const tdBottomStyle = {
    // height: "40px",
    fontFamily: 'Inter',
    fontSize: '0.8rem',
    lineHeight: '15px',
    letterSpacing: '0px',
    textAlign: 'left',
    fonWeight: '500',
    padding: '5px'
  }

  return (
    <div
      
      style={{
        display: 'flex',
        flexDirection: 'column',
        // width: "800px",
        width: '100%',
        minHeight: '842px',
        flexShrink: '0',
        background: '#FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '400',
        border: '1px solid #e9edf3'
      }}
    >
      <style type='text/css' media='print'>
        {
          '\
   @page { size: portrait; }\
'
        }
      </style>
      <div className='m-3' style={{ display: 'flex', flexDirection: 'row-reverse', gap: '16px' }}>
        <Tooltip title='Download'>
          <IconButton
            onClick={toPDF}
            color='primary'
            sx={{
              ...active,
              borderRadius: 1,
              maxHeight: '40px'
            }}
          >
            <Download />
          </IconButton>
        </Tooltip>
        <Tooltip title='Print'>
          <IconButton
            onClick={() => printAll('QC-Report-print', 'report_iframe6')}
            color='primary'
            sx={{
              ...active,
              borderRadius: 1,
              maxHeight: '40px'
            }}
          >
            <Print />
          </IconButton>
        </Tooltip>
      </div>
      <div ref={targetRef} id={id}>
        <PrintHeader />

        <div style={{ border: '1px solid #e9edf3' }}>
          <div
            style={{
              maxWidth: '100%',
              height: '37px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-aroung',
              color: '#303F9F',
              fontSize: '16px',
              fontWeight: '600',
              padding: '0 16px',
              background: '#E7E7E7'
            }}
          >
            {'QC Report'} {children ? '(' + children + ')' : ''}
          </div>
          <table style={{ borderLeft: '1px solid #e9edf3', width: '100%', textTransform: 'capitalize' }}>
            <tbody>
              {Data?.basicdata?.map((element, i) => (
                <tr key={i}>
                  <td style={tdStyle}>{element.label}</td>
                  <td style={{ ...tdStyle, width: '55%' }}>{element.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

import { Box, Button, IconButton, Tab, Tabs, Typography, styled } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React, { forwardRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import Select from 'react-select'
import dayjs from 'dayjs'

export const options = [
  { value: 'thisMonth', label: 'This Month' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'all', label: 'All' },

  { value: 'custom', label: 'custom' }
]

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className='example-custom-input' onClick={onClick} ref={ref}>
    {value}
  </button>
))

const CardTitle = ({ title, fetchFunction }) => {
  const [value, setValue] = useState({
    value: 'thisMonth',
    label: 'This Month'
  })

  const handeChange = item => {
    setValue(item)
    if (item?.value === 'custom') return
    fetchFunction(item?.value)
  }

  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))

  function handleStartDateChange(date) {
    setStartDate(date)
    let endDat = dayjs(endDate).format('YYYY-MM-DD')
    let startDat = dayjs(date).format('YYYY-MM-DD')
    fetchFunction(`custom&&endDate=${endDat}&&startDate=${startDat}`)
  }
  function handleEndDateChange(date) {
    setEndDate(date)
    let endDat = dayjs(date || null).format('YYYY-MM-DD')
    let startDat = dayjs(startDate || null).format('YYYY-MM-DD')
    fetchFunction(`custom&&endDate=${endDat}&&startDate=${startDat}`)
  }

  const handleClear = () => {
    let d = dayjs(new Date())
    handleStartDateChange(d)
    handleEndDateChange(d)
  }

  return (
    <section style={{ alignItems: 'start' }} className='card-section card_das_title align-items-center'>
      <h3 style={{ minWidth: 'max-content' }} className='page-title'>
        {title}
      </h3>

      {/* <div className="d-flex align-items-start"> */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          p: 1,
          borderRadius: 2,
          gap: 2,
          ml: 1
        }}
      >
        {value?.value === 'custom' ? (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#fff',
                borderRadius: 2
              }}
            >
              <IconButton onClick={() => handleStartDateChange(dayjs(startDate).subtract(1, 'month').endOf('month'))}>
                <ChevronLeft />
              </IconButton>
              <Typography>
                {dayjs(startDate).format('DD/MM/YYYY')} - {dayjs(endDate).format('DD/MM/YYYY')}
              </Typography>
              <IconButton onClick={() => handleEndDateChange(dayjs(endDate).add(1, 'month').endOf('month'))}>
                <ChevronRight />
              </IconButton>
            </Box>
            <DatePicker
              format='DD/MM/YYYY'
              slotProps={{
                textField: {
                  size: 'small',
                  sx: { maxWidth: 170, background: '#fff' }
                }
              }}
              value={startDate}
              onChange={value => handleStartDateChange(value)}
              label='From'
            />

            <DatePicker
              format='DD/MM/YYYY'
              slotProps={{
                textField: {
                  size: 'small',
                  sx: { maxWidth: 170, background: '#fff' }
                }
              }}
              onChange={value => handleEndDateChange(value)}
              value={endDate}
              label='To'
            />

            <Button onClick={handleClear} color='primary' variant='outlined'>
              Reset
            </Button>
          </>
        ) : null}
        <Select
          defaultValue={options[0]}
          value={value}
          onChange={handeChange}
          className='card-filter-select'
          options={options}
        />
      </Box>
    </section>
  )
}

export default CardTitle

import React from 'react'
import QRCode from 'react-qr-code'

const QrPreview = ({ closePreview, name, qrurl, campdata }) => {
  return (
    <>
      <div className='qr-head'>
        <div className='qr-top-logo-area'>
          <img src={'/assets/images/qr/Frame 1833.svg'} className='qr-econnect-logo' alt='E Blood Connect Logo'></img>
          <div className='qr-econnect-logo-text'>save blood save life</div>
        </div>

        <div className='qr-top-camplogo-area'>
          {campdata?.TenantsEntity?.logo ? (
            <img src={campdata?.TenantsEntity?.logo} style={{ height: '80px' }} alt='camplogo' />
          ) : (
            <div className='qr-camp-name' style={{ fontSize: '24px' }}>
              {campdata?.TenantsEntity?.name}
            </div>
          )}

          <div className='qr-camp-name'>{name}</div>
        </div>
      </div>
      <div className='qr-body'>
        <div className='qr-body-heading'>Self registration QR Code</div>
        <div className='qr-body-img'>
          <div className='qr-body-imgs'>
            <img src={'/assets/images/qr/Isolation_Mode.svg'} className='camp-qr-generated' alt='QR-Code'></img>
          </div>
          <div className='qr-qr'>
            <QRCode id='qrCodeEl' title='title' value={qrurl} bgColor='#FFFFFF' fgcolor='#000000' size={170} />
          </div>
        </div>
        <div className='qr-powered-by'>Powered By</div>
        <div className='mb-1'>
          <img src={'/assets/images/qr/Logo-main.svg'} className='qr-footer-ehr-logo' alt='EHR Logo'></img>
        </div>
      </div>
    </>
  )
}

export default QrPreview

import { Close, Delete } from '@mui/icons-material'
import { Box, Button, Dialog, DialogContent, Fade, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { forwardRef } from 'react'
import LoadingButton from './LoadingButton'

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />
})

function ConfirmBox({ name, title, toDoFunction, closeDialog, open, loading }) {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='sm'
      scroll='body'
      onClose={closeDialog}
      onBackdropClick={closeDialog}
      TransitionComponent={Transition}
    >
      <DialogContent sx={{ px: { xs: 2, sm: 4 }, py: { xs: 3, sm: 4 }, position: 'relative' }}>
        <IconButton size='small' onClick={closeDialog} sx={{ position: 'absolute', right: '1rem', top: '1rem' }}>
          <Close />
        </IconButton>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
              <Typography variant='h4' sx={{ mb: 3, lineHeight: '2rem' }}>
                Delete {title}
              </Typography>
              <Typography variant='body1'>Are You Sure you want to delete this {name} ?</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button className=' btn backBtn mr-2' onClick={closeDialog}>
              Cancel
            </button>

            <LoadingButton
              className='gradient-btn_red'
              isLoading={loading}
              onClick={toDoFunction}
            >
              <Delete />
              Delete
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmBox

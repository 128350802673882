import { Download } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'
import QrPreview from './components/QrPreview'
import { getId } from '../../middleware/auth'
import { printAll } from '../../../helper/CommonAPI'
import { FRONT_END_URL } from '../../../helper/AxiosHelper'
import PrintQr from '../../blood-bank-management/PrintQr'
import PrintWrapperWithoutButton from '../../print-components/PrintWrapperWithoutButton'
const Qrcode = ({ name, isEditable, campdata }) => {
  const [preview, setPreview] = useState(false)
  const { id } = useParams()
  const previewRef = useRef()

  let qrName = name?.replaceAll(' ', '-').toLowerCase()
  let tanent = getId()
  const qrurl = `${FRONT_END_URL}/donor/registeration/camp/${id}/${qrName}?tanentid=${tanent}`
  console.log(qrurl)
  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
    // .toDataURL("image/png")
    let path = qrCodeURL.toDataURL('image/png')
    // .replace("image/png", "image/octet-stream");
    console.log('qrCodeURL', qrCodeURL, path)
    // let aEl = document.createElement("a");
    // aEl.href = qrCodeURL;
    // aEl.download = "QR_Code.png";
    // document.body.appendChild(aEl);
    // aEl.click();˝
    // document.body.removeChild(aEl);
  }
  const closePreview = () => {
    setPreview(false)
  }

  return (
    <>
      {/* <div>
      {" "}
      <div className="blood-bank-management-view-qr ">
        <QRCode
          getRef={(ref) => (myQRCode = ref)}
          id="qrCodeEl"
          title="title"
          value={`https://dev.ehrlogic.com/donor/registeration/camp/${id}/${qrName}`}
          bgColor="#FFFFFF"
          fgcolor="#000000"
          size={250}
        />
      </div>
      <Button
        onClick={GenerateQR}
        sx={{ mt: 2 }}
        variant="outlined"
        color="primary"
        size="large"
      >
        <Download /> Download
      </Button>
    </div> */}

      <div className='flex'>
        <div className=''>
          <h4 className='qr-descreption-title'>Convenient Self-Registration with QR Code</h4>
          <h5>
            <strong>How Does It Work?</strong>
          </h5>
          <p>
            Before you arrive at a blood donation camp or center, you can take advantage of our self-registration
            feature using a QR code. Here's how it works:
          </p>
          <div className='ml-5'>
            <p>
              1.<strong>Scan the QR Code:</strong> Simply scan the QR code provided on our website or promotional
              materials using your smartphone or mobile device.
              {/* (or we can write  **provided on clicking preview button using your ………….. )  */}
            </p>
            <p>
              2.<strong>Register in Advance:</strong> The QR code will direct you to our quick and user-friendly
              registration page. There, you can provide your details in advance, saving you time at the donation site.
            </p>
            <p>
              3.<strong>Fast and Hassle-Free:</strong> By completing the self-registration beforehand, you'll have a
              faster and more streamlined check-in process at the donation camp. Walk in with confidence, knowing you
              are already pre-registered.
            </p>
          </div>
          <div className='qr-button-div'>
            <button
              disabled={!isEditable}
              className='btn gradient-btn'
              onClick={() => {
                setPreview(!preview)
              }}
            >
              Preview QR Code
            </button>
            <button
              disabled={!isEditable}
              onClick={() => {
                if (previewRef?.current) {
                  previewRef.current.click()
                }
              }}
              className='btn backBtn'
            >
              Print QR Code
            </button>
          </div>
        </div>
        <img src={'/assets/images/bloodcamp/mobqr.png'} className='qrimg-phone' alt='phone-img ' />
      </div>
      {preview ? (
        <>
          <div
            className='qr-model-wraper'
            onClick={() => {
              closePreview()
            }}
          ></div>
          <div className='qr-model-container'>
            <QrPreview closePreview={closePreview} name={name} qrurl={qrurl} campdata={campdata} />
          </div>
        </>
      ) : null}

      <div
        style={{
          visibility: 'hidden',
          zIndex: '-1'
        }}
      >
        <PrintWrapperWithoutButton
          styles={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            background: '#fff'
          }}
          ref={previewRef}
        >
          <QrPreview closePreview={closePreview} name={name} qrurl={qrurl} campdata={campdata} />
        </PrintWrapperWithoutButton>
      </div>
    </>
  )
}

export default Qrcode

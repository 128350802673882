import React, { useEffect, useState } from 'react'
import {
  Grid,
  FormLabel,
  MenuItem,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  ListItemButton
} from '@mui/material'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useParams } from 'react-router-dom'
import FormProvider from '../../shared/FormProvider'
import RHFTextField from '../../shared/RHFTextfield'
import { RHFDatePicker } from '../../shared/RHFDatePicker'
import Loader from '../../shared/Loader'
import RHFSelect from '../../shared/RHFSelect'
import AxiosHelper from '../../../helper/AxiosHelper'
import { toast } from 'react-toastify'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import dayjs from 'dayjs'
import { checkTenantId } from '../../../helper/CommonAPI'
const radioStyles = {
  '&.MuiCheckbox-indeterminate,&.Mui-checked': {
    color: '#214F8A'
  }
}

const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard'
  },
  {
    path: '/blood-camp-management',
    label: 'Blood Camp Management'
  },
  {
    label: 'Facility Inspection'
  }
]

export const FormDivider = ({ title }) => {
  return (
    <Grid className='Donor-details-sections' item xs={12}>
      <div className='detail-header'>{title ? title : ''}</div>
    </Grid>
  )
}

export const facilities = [
  'Electricity: Continuous and uninterrupted electrical supply to power essential equipment, such as blood collection machines, lighting, and communication devices',
  'Bed:Donor beds or chairs for the blood collection process, ensuring donor comfort during donation',
  'Water: Access to clean and potable water for various purposes, including handwashing, sanitation, and medical equipment preparation',
  'Furniture: Basic furniture, such as chairs and tables, for registration, waiting areas, and post-donation rest areas',
  'Emergency Medicine:Basic first aid supplies and emergency medicines to address minor medical issues or reactions among donors',
  'Hand Washing/Sanitation Facilities:Handwashing stations equipped with soap and water to maintain proper hygiene for both donors and staff. Hand sanitizers for additional hygiene measures',
  'Medical Examination Facility:Space for healthcare professionals to conduct pre-donation health assessments, including checking vital signs and assessing donor eligibility',
  'Disposal of Waste:Proper waste disposal facilities, including bins for general waste and biohazard materials, ensuring a clean and safe environment',
  'Area & Lighting : Sufficient area & Adequate lighting for all the required activities for conducting the blood camp',
  'Ventilation: (Window/Fan/AC)'
]

const defaultValues = {
  camp_id: '',
  organizer_name: '',
  facility: facilities
}

const schema = yup.object().shape({
  camp_id: yup.string().required('Camp Id is required'),
  organizer_name: yup.string().required('Organiser name is required'),
  date: yup.string().required('Date is required')
})

const AddFacility = ({ campdata, addFromView, getCampDetails }) => {
  const [isLoading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [data, setData] = useState([])

  const { id } = useParams()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    reset,
    formState: { errors }
  } = methods

  const history = useHistory()

  const getBloodCampRequests = () => {
    setPageLoading(true)
    let url = `camp/without-pagination`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        setData(response.data.data)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getBloodCampRequests('camp')
  }, [])

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  const onSubmit = async data => {
    setLoading(true)
    AxiosHelper.post('camp/add-facility', data)
      .then(response => {
        setLoading(false)
        toast.success('Facilities Added Successfully')
        if (addFromView) {
          getCampDetails()
        } else {
          history.push('/blood-camp-management')
        }
      })
      .catch(error => {
        toast.error(error)
        setLoading(false)
      })
  }

  const handleClick = (event, name) => {
    const selectedIndex = watch('facility').indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(watch('facility'), name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(watch('facility').slice(1))
    } else if (selectedIndex === watch('facility').length - 1) {
      newSelected = newSelected.concat(watch('facility').slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        watch('facility').slice(0, selectedIndex),
        watch('facility').slice(selectedIndex + 1)
      )
    }
    setValue('facility', newSelected)
  }

  useEffect(() => {
    if (id) {
      setValue('camp_id', id)
    }
  }, [id])

  function getCampDataa(id) {
    let timeD = data?.find(item => item.id == id)
    setValue('date', dayjs(timeD?.camp_schedule_date))
    setValue('organizer_name', timeD?.organizer_name)
  }

  useEffect(() => {
    if (watch('camp_id') && data.length > 0) {
      getCampDataa(watch('camp_id'))
    }
  }, [watch('camp_id'), data])

  useEffect(() => {
    if (campdata) {
      setValue('organizer_name', campdata?.organizer_name)
      setValue('date', dayjs(campdata?.camp_schedule_date))
      console.log(campdata)
    }
  }, [campdata, data])

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className='bloodbank_action'>
          <p> Venue Facility Inspection</p>
        </div>
        <Grid container spacing={3}>
          <FormDivider title='General Information' />

          <Grid item xs={12} sm={6} md={4}>
            <FormLabel sx={labelStyles}>Blood Camp Name</FormLabel>
            <RHFSelect name='camp_id' size='small' fullWidth sx={inputStyles}>
              {data?.length > 0 ? (
                data?.map((item, i) => (
                  <MenuItem key={i} value={item?.id}>
                    {item?.camp_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No Camps Found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormLabel sx={labelStyles}>Organizer Name</FormLabel>
            <RHFTextField name='organizer_name' size='small' fullWidth sx={inputStyles}>
              <MenuItem value='Bank name'>Organizer Name</MenuItem>
            </RHFTextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormLabel sx={labelStyles}>Camp Schedule Date</FormLabel>
            <RHFDatePicker name='date' sx={inputStyles} />
          </Grid>

          <FormDivider title='Requested Facility' />
        </Grid>

        <FormControlLabel
          sx={{ ml: 1 }}
          onChange={e => {
            if (e.target.checked) {
              setValue('facility', facilities)
              return
            }
            setValue('facility', [])
          }}
          label='Select All'
          control={
            <Checkbox
              sx={radioStyles}
              checked={watch('facility').length === facilities.length}
              indeterminate={watch('facility').length && watch('facility').length < facilities?.length}
            />
          }
        />

        <List
          sx={{
            width: '100%',
            px: 1,
            bgcolor: 'background.paper'
          }}
        >
          {facilities.map((value, index) => {
            const labelId = `checkbox-list-label-${index}`

            return (
              <ListItem sx={{ backgroundColor: '#F5F5F5', mb: 2 }} key={index} disablePadding>
                <ListItemButton onClick={e => handleClick(e, value)}>
                  <Checkbox
                    inputProps={{
                      'aria-labelledby': labelId
                    }}
                    sx={radioStyles}
                    edge='start'
                    checked={watch('facility').indexOf(value) !== -1}
                    // onChange={(e) => handleClick(e, value)}
                    disableRipple
                  />
                  <ListItemText
                    sx={{
                      '&.MuiListItemText-root': {
                        fontWeight: 600,
                        fontSize: '1rem'
                      }
                    }}
                    id={labelId}
                    // primary={`${value} ?`}
                  >
                    <span style={{ fontWeight: '600' }}>{value?.substring(0, value?.indexOf(':'))} : </span>
                    {value?.substring(value?.indexOf(':') + 1)} ?
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
        <div className='d-flex' style={{ gap: '10px' }}>
          <button type='button' onClick={() => history.push('/blood-camp-management')} className='btn backBtn '>
            Cancel
          </button>

          <button type='submit' className='btn gradient-btn'>
            Save
          </button>
        </div>
      </FormProvider>
    </div>
  )
}

export default AddFacility

import { Card, Chip } from '@mui/material'
import React, { useState } from 'react'
import NoDataFound from '../../common/NoDataFound'
import { useHistory } from 'react-router-dom'
import StaffForm from './StaffForm'
import moment from 'moment'
import ScrollBar from 'react-perfect-scrollbar'
import { useEffect } from 'react'
import { checkPermission } from '../../middleware/auth'
const StaffTable = ({ allStaff = [], id, getCampDetails, camp }) => {
  const [editTab, setEditTab] = useState(false)
  const [vehicleNo, setVehicalNo] = useState('')
  const [vehValidity, setVehValidity] = useState('')
  const history = useHistory()

  useEffect(() => {
    if (allStaff?.length > 0) {
      allStaff.forEach(ele => {
        if (ele?.validity) {
          setVehValidity(moment(ele?.validity).format('DD-MMM-YYYY'))
        }
        if (ele?.vehicle_no) {
          setVehicalNo(ele?.vehicle_no)
        }
      })
    }
  }, [allStaff])

  return (
    <>
      {allStaff?.length === 0 || editTab === true ? null : (
        <div className='bloodbank_action  '>
          <p>All Staff</p>

          {editTab ? null : checkPermission('UPDATE_CAMP') === true ? (
            <button onClick={() => setEditTab(true)} type='submit' className='btn gradient-btn'>
              Edit
            </button>
          ) : null}
        </div>
      )}

      {editTab ? (
        <StaffForm
          allStaff={allStaff}
          setEditTab={setEditTab}
          state='Edit'
          camp={camp}
          editId={id}
          getCampDetails={getCampDetails}
        />
      ) : (
        <Card sx={{ mt: 2 }}>
          <div className='table-responsive'>
            <ScrollBar>
              <table className='table  table-hover'>
                <thead>
                  <tr className='table_header'>
                    <th>Staff Name</th>
                    <th>Role</th>
                    <th>Mobile number</th>
                    <th>Duty Reminder</th>
                  </tr>
                </thead>
                <tbody className='inventory_tab'>
                  {allStaff?.length > 0
                    ? allStaff?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <>
                              {item?.personal_titles === 'Prefer not to Say' ? '' : <>{item?.personal_titles || ''}</>}{' '}
                              {item?.name || '-'}
                            </>
                          </td>
                          <td>
                            {item?.role
                              ? JSON.parse(item?.role)?.map((items, i) => (
                                  <Chip sx={{ mr: 1 }} key={i} label={items || '-'} color='default' />
                                ))
                              : null}
                          </td>
                          <td>{item?.mobile ? <>+91 {item?.mobile}</> : '-'} </td>
                          <td>{item?.is_sms ? 'Details send via sms' : 'Fail to send'}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </ScrollBar>
          </div>
          <div className='p-3' style={{ fontSize: '14px' }}>
            {vehicleNo.length > 0 && <div className='mt-3'>Vehicle/Ambulance No: {vehicleNo}</div>}
            {vehValidity.length > 0 && <div className='mt-3'>Certificate Valied Till: {vehValidity}</div>}
          </div>
          {allStaff?.length === 0 ? (
            <div className='blood-requisition-no-data'>
              <NoDataFound title={' '} />
              {checkPermission('UPDATE_CAMP') && (
                <div className=' empty-requisition-msg '>
                  <button
                    className='btn btn-gray add-requisition-btn-table'
                    onClick={() => {
                      history.push(`/blood-camp-management/staff/add/${id}`)
                    }}
                  >
                    {' '}
                    Add New Staff
                  </button>

                  <div className='add-requisition-msg'>
                    <p>Click on Add New Staff button to add New Staff</p>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </Card>
      )}
    </>
  )
}

export default StaffTable

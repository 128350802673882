import moment from 'moment'
import PrintComponentsView from '../../printComponents/PrintComponentsView'
import { InfoItem } from '../shared/info-item'
import ModalCustom from '../../../../shared/ModalCustom'
import { CardHeader } from '../shared/card-header'
import LoadingButton from '../../../../shared/LoadingButton'
import { useEffect, useState } from 'react'
import AxiosHelper from '../../../../../helper/AxiosHelper'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { downLoadFiles } from '../../../../middleware/auth'
import { BloogGroupPrintRHFn } from '../../../../shared/BloogGroupPrintRH'
import { StatusCapsule } from '../shared/status-capsule'

const getFormattedDate = data => {
  if (data?.assigned_date) {
    return moment(data.assigned_date).format('DD MMM YYYY hh:mm A')
  } else if (data?.screening_date) {
    return moment(data.screening_date).format('DD MMM YYYY hh:mm A')
  } else if (data?.createdAt) {
    return moment(data.createdAt).format('DD MMM YYYY hh:mm A')
  } else {
    return '-'
  }
}

export const BloodBagInfo = ({
  trailData,
  setPrintOptions,
  setOpenModal,
  openModal,
  basicData,
  printOptions,
  setCurrentData,
  info,
  setInfo,
  getDonorHistory,
  data
}) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [btnLoadingType, setBtnLoadingType] = useState(false)
  const [donType, setDonType] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (data) {
      let DONATION_TYPE
      if (data?.camp_id === null && data?.DonorScreening === null) {
        DONATION_TYPE = 'Bulk Receive'
      } else if (data?.camp_id !== null) {
        DONATION_TYPE = 'Camp'
      } else {
        DONATION_TYPE = 'InHouse'
      }
      setDonType(DONATION_TYPE)
    }
  }, [data])

  const handleCloseModal = () => {
    setPrintOptions('')
    setOpenModal(false)
  }
  function findSmsStatusObj(array, type) {
    let objj = array?.find(item => {
      return item.type === type
    })

    if (objj) return objj

    return null
  }

  const sendSMSAgain = (bag_id, type) => {
    setBtnLoading(bag_id)
    setBtnLoadingType(type)
    AxiosHelper.get(`sms-log/send-sms-via-bag/${bag_id}/${type}`)
      .then(response => {
        getDonorHistory()
        setBtnLoading(null)
        setBtnLoadingType(null)
        toast.success('Report Sent Successfully')
      })
      .catch(error => {
        console.log(error)
        setBtnLoading(null)
        setBtnLoadingType(null)
        toast.error(error || 'Something went wrong on our side')
      })
  }

  console.log(`bag data info --> `, data)

  return (
    <>
      <CardHeader lastUpdated={data?.updatedAt}>
        {/* {`Blood Bag ${data?.bag_number ? `- ${data?.bag_number}` : ''}`} */}
        <span>Blood Bag </span>
        <span>{data?.bag_number ? <span>- {data?.bag_number}</span> : null}</span>
      </CardHeader>
      <div className='bag-wrap'>
        {/* Donor Date & Token ID */}
        <InfoItem title='Collection Date & Time' value={getFormattedDate(data)} />
        {/* <InfoItem title='Token ID' value={data?.id || ''} /> */}
        <InfoItem title='Donor Registeration'>
          {data?.pending_registration === true ? (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/donors-detail/registration?id=${data.id}`)}
              className='pending'
            >
              Pending
            </div>
          ) : (
            <div className='pending'>Done</div>
          )}
        </InfoItem>

        {/* Medical Screening */}
        <InfoItem is_bulk={!data?.pending_registration && data.is_bulk} title='Medical Screening'>
          {data?.id && !data?.pending_registration ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('print_screening_Report')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Done
            </div>
          ) : (
            <div className='pending'>Pending</div>
          )}
        </InfoItem>

        {/* Blood Bag Issue & Number */}
        <InfoItem is_bulk={!data?.pending_registration && data.is_bulk} title='Blood Bag Issue'>
          {data?.bag_id && !data?.pending_registration ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('print_bloodbag_details')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Issued
            </div>
          ) : data?.is_defered ? (
            <span className='abort'>Abort</span>
          ) : (
            <div className='pending'>Pending</div>
          )}
        </InfoItem>
        <InfoItem title='Blood Bag Number' value={data?.bag_number || 'N/A'} />
        <InfoItem title='Blood Bag Type' value={data?.bag_type || 'N/A'} />

        {/* Tube Number */}
        <InfoItem title='Segment Number' value={data?.tube_number || 'N/A'} />

        {/* Tti Testing */}
        <InfoItem title='TTI Testing'>
          {!data?.BloodTestingEntity ? (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/donors-detail/testing/${data?.abha_id}`)}
              className='pending'
            >
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          ) : !data?.BloodTestingEntity?.is_declined ? (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                if (!data.is_bulk) {
                  setPrintOptions('print_TTI_Report')
                  setOpenModal(true)
                }
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'done'}>Non-Reactive</StatusCapsule>
            </div>
          ) : (
            <StatusCapsule status={'cancel'}>Reactive</StatusCapsule>
          )}
          {/* {data?.BloodTestingEntity?.id || data.is_bulk ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!data.is_bulk) {
                  setPrintOptions('print_TTI_Report')
                  setOpenModal(true)
                }
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Done
            </div>
          ) : data?.is_defered ? (
            <div className='abort'>Abort</div>
          ) : (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/donors-detail/testing/${data?.abha_id}`)}
              className='pending'
            >
              Pending
            </div>
          )} */}
        </InfoItem>

        {/* Inventory */}
        <InfoItem title='Inventory'>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data?.BloodLogsEntities?.length > 0 ? (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setPrintOptions('inventory_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'done'}>Done</StatusCapsule>
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                if (data?.abha_id) {
                  history.push(`/donors-detail/donor-inventory/${data?.abha_id}`)
                }
              }}
              className='pending'
            >
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          )}
        </InfoItem>

        {/* Donor Registeration */}

        {/* Site */}
        <InfoItem title='Site'>
          {donType === 'Camp' ? (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/blood-camp-management/view/${data?.CampEntity?.id}`)}
              className='pending'
            >
              {donType} {data?.CampEntity?.camp_name ? `- ${data?.CampEntity?.camp_name}` : ''}
            </div>
          ) : (
            donType
          )}
        </InfoItem>

        {/* Donation Site & Certificate Status */}
        {/* <InfoItem
          is_bulk={data.is_bulk}
          title='Donation Site/Place'
          value={data?.DonorCampEntities?.[0]?.CampEntity?.camp_name || data?.TenantsEntity?.name || 'N/A'}
        /> */}
        <InfoItem is_bulk={data.is_bulk} title='Donation Status'>
          {data?.is_defered ? (
            <div
              className='abort'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setPrintOptions('full_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
            </div>
          ) : (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setPrintOptions('full_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          )}
        </InfoItem>

        {/* Blood Group */}
        <InfoItem title='Blood Grouping' value={data?.BloodTestingEntity?.blood_group || 'N/A'} />

        <InfoItem is_bulk={data.is_bulk} title={'Donation Certificate'}>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data && data?.bag_id ? (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                downLoadFiles(`/download/donor-certificate/${data?.id}`, `Donation_Certificate`)
              }}
            >
              {
                <img
                  src={'/assets/images/pdf.svg'}
                  alt='pdf'
                  style={{
                    borderRadius: 'initial',
                    height: '24px'
                  }}
                />
              }
              Get Certificate
            </div>
          ) : (
            <div className='pending' style={{ cursor: 'pointer' }}>
              {
                <img
                  src={'/assets/images/pdf.svg'}
                  alt='pdf'
                  style={{
                    borderRadius: 'initial',
                    height: '24px'
                  }}
                />
              }
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          )}
        </InfoItem>

        <InfoItem is_bulk={data.is_bulk} title={'Certificate SMS Status'}>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data && data?.bag_id ? (
            <div className='pending'>
              <LoadingButton
                title={
                  findSmsStatusObj(data?.certificateDeliverLogEntities, 'Certificate')?.is_delivered ? 'Resend' : 'Send'
                }
                disabled={!data}
                style={{ height: '2rem' }}
                isLoading={btnLoading == data?.id && btnLoadingType == 'Certificate'}
                onClick={() => {
                  sendSMSAgain(data?.id, 'Certificate')
                }}
              />
            </div>
          ) : (
            'Not Sent'
          )}
        </InfoItem>

        <InfoItem is_bulk={data.is_bulk} title={'TTI SMS Status'}>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data?.BloodTestingEntity && data?.BloodTestingEntity?.id ? (
            <div className='pending'>
              <LoadingButton
                disabled={!data}
                title={findSmsStatusObj(data?.certificateDeliverLogEntities, 'TTI')?.is_delivered ? 'Resend' : 'Send'}
                style={{ height: '2rem' }}
                isLoading={btnLoading == data?.id && btnLoadingType == 'TTI'}
                onClick={() => {
                  sendSMSAgain(data?.id, 'TTI')
                }}
              />
            </div>
          ) : (
            <div className='pending'>
              {/* {<img src={'/assets/images/pdf.svg'}
                              alt="pdf"
                              onClick={() => printAll("print_TTI_Report", "i_frame")}
                              style={{ borderRadius: "initial", height: "24px" }}
                            />} */}
              Pending
            </div>
          )}
        </InfoItem>

        {/* Modal */}
        <ModalCustom open={openModal} fullScreen closeDialog={handleCloseModal}>
          <PrintComponentsView
            printId={printOptions}
            data={{
              ...trailData,
              is_bulk: data.is_bulk
            }}
            info={basicData}
            handlecloseModle={handleCloseModal}
          />
        </ModalCustom>
      </div>
    </>
  )
}

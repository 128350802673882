import React, { useState } from 'react'
import BreadCrumb from '../../common/BreadCrumb'
import PageHeader from '../../common/PageHeader'
import ComponentForm from './ComponentForm'
import Loader from '../../shared/Loader'
import ScrollCard from '../../shared/ScrollCard'


const AddBloodComponentForm = ({compData,id,getCompData,editForm}) => {

  return (
    <>
        <div>
          <div className='mt-2 pb-5'>
            <ComponentForm compData={compData} id={id} getCompData={getCompData} 
            editForm={editForm}/>
          </div>
        </div>
    </>
  )
}

export default AddBloodComponentForm

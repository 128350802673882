import React, { forwardRef, useRef, useState } from 'react'
import BreadCrumb from '../common/BreadCrumb'
import PageHeader from '../common/PageHeader'
import AxiosHelper from '../../helper/AxiosHelper'
import NoDataFound from '../common/NoDataFound'
import HeaderTabs from './HeaderTabs'
import { Bar, Line } from 'react-chartjs-2'
import CardTitle from './CardTitle'
import ReactApexcharts from 'react-apexcharts'
import Loader from '../shared/Loader'
import { useEffect } from 'react'
import { getEntityInfo } from '../middleware/auth'
import { addDays } from 'date-fns'
import BankIcon from '../../assets/images/blood-bank-marquee.svg'
import CampIcon from '../../assets/images/medical-tent-marquee.svg'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  styled
} from '@mui/material'
import { BorderBottom, Cached, ChevronLeft, ChevronRight, Refresh } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import ScrollBar from 'react-perfect-scrollbar'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { CategoryScale, LinearScale, Chart } from 'chart.js'
import 'chart.js/auto'
import { Timeline } from '@mui/lab'
import ActivityItem from './ActivityItem'
import Select from 'react-select'
import CardHeading from './components/cardHeading'
import AvailableBlood from './components/AvailableBlood'
import WasteChart from './components/WasteChart'
import BloodRequistionChart from './components/BloodRequistionChart'
import CerificateMarqueItem from './components/CerificateMarqueItem'
import BlodgroupWiseChart from './components/BlodgroupWiseChart'
import RevenueChart from './components/RevenueChart'
import OtherChart from './components/OtherChart'
import DonorsChart from './components/DonorsChart'
import DonorStatsChart from './components/DonorStatsChart'
import TtiChart from './components/TtiChart'
import { useSettings } from '../../hooks/useSetting'
import BloodComponentWise from './components/BloodComponents'
import { checkTenantId } from '../../helper/CommonAPI'

Chart.register(CategoryScale, LinearScale)

const StyledTabs = styled(props => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))({
  '& .MuiTabs-indicator': {
    display: 'none'
  }
})

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  minWidth: '30px',
  fontSize: theme.typography.pxToRem(15),
  color: '#9CA3AF',
  '&.Mui-selected': {
    color: '#000000',
    borderBottom: '2px solid #000000'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)'
  }
}))

export const bloodGroup = {
  total: 'Total',
  AB_NEGATIVE: 'AB-',
  AB_POSITIVE: 'AB+',
  A_POSITIVE: 'A+',
  A_NEGATIVE: 'A-',
  B_POSITIVE: 'B+',
  B_NEGATIVE: 'B-',
  O_NEGATIVE: 'O-',
  O_POSITIVE: 'O+'
}

export const bloodGroupToFilter = {
  total: 'total',
  AB_NEGATIVE: 'AB-Ve',
  AB_POSITIVE: 'AB+Ve',
  A_POSITIVE: 'A+Ve',
  A_NEGATIVE: 'A-Ve',
  B_POSITIVE: 'B+Ve',
  B_NEGATIVE: 'B-Ve',
  O_NEGATIVE: 'O-Ve',
  O_POSITIVE: 'O+Ve'
}

export const selectoptions = [
  { value: 'thisMonth', label: 'This Month' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'all', label: 'All' },

  { value: 'custom', label: 'custom' }
]

const BoxWrapper = styled('Box')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  [theme.breakpoints.down({ unit: 1300 })]: {
    flexDirection: 'column'
  }
}))

const EHRDashboard = () => {
  const [headerData, setHeaderData] = useState({})
  const [certificateData, setCertificateData] = useState({
    BloodBank: null,
    BloodCamp: [],
    AllCount: null,
    BankCount: null,
    CampCount: null
  })
  const [auditData, setAuditData] = useState([])
  const [headerTop, setHeaderTop] = useState('thisMonth')
  const [pageLoading, setPageLoading] = useState(false)
  const [loadingWidger, setLoadingWidger] = useState(false)
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [open, setOpen] = useState(false)
  const [certificateFilter, setCertificateFilter] = useState('all')
  const [updatedDate, setUpdatedDate] = useState(null)
  const [certificateFilterData, setCertificateFilterData] = useState({
    BloodBank: null,
    BloodCamp: []
  })
  const { settings, saveSettings } = useSettings()
  function handleStartDateChange(date) {
    setStartDate(date)
    let endDat = dayjs(endDate).format('YYYY-MM-DD')
    let startDat = dayjs(date).format('YYYY-MM-DD')
    getDashboardInfo(`?endDate=${endDat}&startDate=${startDat}`)
  }
  function handleEndDateChange(date) {
    setEndDate(date)
    let endDat = dayjs(date || null).format('YYYY-MM-DD')
    let startDat = dayjs(startDate || null).format('YYYY-MM-DD')
    getDashboardInfo(`?endDate=${endDat}&startDate=${startDat}`)
  }

  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    }
  ]

  const getDashboardInfo = item => {
    setLoadingWidger(true)
    let filter = ''
    if (item === 'thisMonth') {
      const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD')
      // End of the current month
      const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD')
      filter = `?endDate=${endOfMonth}&startDate=${startOfMonth}`
    } else if (item === 'lastMonth') {
      const startOfLastMonth = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
      // End of the last month
      const endOfLastMonth = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')

      filter = `?endDate=${endOfLastMonth}&startDate=${startOfLastMonth}`
    } else if (item === 'all') {
      filter = ``
    } else {
      filter = item
    }
    const URL = checkTenantId(`dashboard/widget-count${filter}`)
    AxiosHelper.get(URL)
      .then(resp => {
        setHeaderData(resp.data.data)
        setUpdatedDate(new Date())
        setLoadingWidger(false)
      })
      .catch(err => {
        console.log(err)
        setLoadingWidger(false)
      })
  }

  const getDashboardAuditLog = (item, page) => {
    // ?filter=patient
    const URL = checkTenantId(`audit-logs?filter=patient`)
    AxiosHelper.get(URL)
      .then(resp => {
        setAuditData(resp?.data?.data?.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getDashboardCertificate = () => {
    const URL = checkTenantId(`dashboard/marquee-certificate`)
    AxiosHelper.get(URL)
      .then(resp => {
        let message = {
          BloodBank: null,
          BloodCamp: [],
          AllCount: 0,
          BankCount: 0,
          CampCount: 0
        }
        if (resp?.data?.data?.bloodbank_certificate != null) {
          message.BloodBank = resp?.data?.data?.bloodbank_certificate
          message.BankCount = 1
          message.AllCount = message.AllCount + message.BankCount
        }
        if (resp?.data?.data?.camp_certificate.length > 0) {
          message.BloodCamp = resp?.data?.data?.camp_certificate
          message.CampCount = resp?.data?.data?.camp_certificate.length
        }
        message.AllCount = message.BankCount + message.CampCount
        if (certificateFilter === 'bank') {
          setCertificateFilterData({
            BloodBank: message.BloodBank,
            BloodCamp: []
          })
          message.AllCount = message.AllCount + message.CampCount
        } else if (certificateFilter === 'camp') {
          setCertificateFilterData({
            BloodBank: null,
            BloodCamp: message.BloodCamp
          })
        } else {
          setCertificateFilterData({
            BloodBank: message.BloodBank,
            BloodCamp: message.BloodCamp
          })
        }
        setCertificateData(message)
      })
      .catch(err => {
        console.log(err)
      })
  }

  async function getFirstTimeData() {
    setPageLoading(true)
    await getDashboardInfo(headerTop)
    await getDashboardCertificate('thisMonth')
    await getDashboardAuditLog()
    setPageLoading(false)
  }

  useEffect(() => {
    saveSettings({ ...settings, navCollapsed: true })
    getFirstTimeData()
  }, [])

  const handleChange = (event, newValue) => {
    setHeaderTop(newValue)
    if (newValue !== 'custom') {
      getDashboardInfo(newValue)
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const handleClear = () => {
    let d = dayjs(new Date())
    handleStartDateChange(d)
    handleEndDateChange(d)
  }

  const stop = () => {
    document.getElementById('myMarquee').stop()
  }
  const start = () => {
    document.getElementById('myMarquee').start()
  }

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div className='dashboard_main'>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} hideBack={true} />

      <div className='dashboard-main-content-new'>
        <ScrollBar>
          <Grid sx={{ width: '100%' }} container ml={0} spacing={1} pb={3}>
            <Grid item xs={12} lg={9} overflowY='auto'>
              <section className='dash-card mt-0 pt-2'>
                <Box
                  className='dash__tabs '
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: { lg: 'center', md: 'flex-start' },
                    width: '100%',
                    borderBottom: '2px solid #fff'
                  }}
                >
                  <StyledTabs
                    variant='scrollable'
                    scrollButtons='auto'
                    value={headerTop}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                  >
                    <StyledTab value='thisMonth' label='This Month' />
                    <StyledTab value='lastMonth' label='Last Month' />
                    <StyledTab value='all' label='All' />
                    <StyledTab value='custom' label='Custom' />
                  </StyledTabs>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {updatedDate ? (
                      <Typography>Last updated on {dayjs(updatedDate).format('DD MMM YYYY, hh:mm A')}</Typography>
                    ) : null}
                    <IconButton
                      onClick={() => getDashboardInfo(headerTop)}
                      sx={{
                        background: 'linear-gradient(90deg,#214f89,#12bcab)',
                        borderRadius: '4px',
                        color: '#fff',
                        height: '24px',
                        width: '24px'
                      }}
                    >
                      {loadingWidger ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Cached />}
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={open}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      p: 1,
                      borderRadius: 2,
                      gap: 2,
                      backgroundColor: '#F0FFFF'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        background: '#fff',
                        borderRadius: 2
                      }}
                    >
                      <IconButton
                        onClick={() => handleStartDateChange(dayjs(startDate).subtract(1, 'month').endOf('month'))}
                      >
                        <ChevronLeft />
                      </IconButton>
                      <Typography>
                        {dayjs(startDate).format('DD/MM/YYYY')} - {dayjs(endDate).format('DD/MM/YYYY')}
                      </Typography>
                      <IconButton onClick={() => handleEndDateChange(dayjs(endDate).add(1, 'month').endOf('month'))}>
                        <ChevronRight />
                      </IconButton>
                    </Box>
                    <DatePicker
                      format='DD/MM/YYYY'
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: { maxWidth: 170, background: '#fff' }
                        }
                      }}
                      value={startDate}
                      disableFuture
                      onChange={value => handleStartDateChange(value)}
                      label='From'
                    />

                    <DatePicker
                      format='DD/MM/YYYY'
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: { maxWidth: 170, background: '#fff' }
                        }
                      }}
                      minDate={startDate}
                      disableFuture
                      onChange={value => handleEndDateChange(value)}
                      value={endDate}
                      label='To'
                    />

                    <Button onClick={handleClear} color='primary' variant='outlined'>
                      Reset
                      {/* <FilterAltOutlined /> */}
                    </Button>
                  </Box>
                </Collapse>

                <div className='header-cards' style={{ width: '100%' }}>
                  {getEntityInfo() == '5' ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <HeaderTabs
                          icon={'/assets/images/dashboard/regBloodBank.svg'}
                          stats={headerData?.bloodRequest?.increased || '0%'}
                          data={headerData?.requestCount || '0'}
                          title='Total Blood Request Count'
                          bgColor='linear-gradient(98.28deg, #D7E3F6 0.4%, #F0F3F5 100%)'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <HeaderTabs
                          icon={'/assets/images/dashboard/totalBloodReq.svg'}
                          stats={headerData?.bloodRequest?.increased || '0%'}
                          data={headerData?.inwardCount || '0'}
                          title='Total Inward Stock Count'
                          bgColor='linear-gradient(98.28deg, #D7F3EF 0.4%, #F0F3F5 100%)'
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      {getEntityInfo() == 1 ? (
                        <Grid item xs={12} sm={6} md={4} lg={getEntityInfo() == 1 ? 3 : 4}>
                          <HeaderTabs
                            icon={'/assets/images/dashboard/totalBloodReq.svg'}
                            stats={headerData?.bloodBank?.increased || '0%'}
                            data={headerData?.bloodBank?.total || '0'}
                            bgColor='linear-gradient(98.28deg, #D7F3EF 0.4%, #F0F3F5 100%)'
                            title='Registered Blood Banks'
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={6} md={4} lg={getEntityInfo() == 1 ? 3 : 4}>
                        <HeaderTabs
                          icon={'/assets/images/dashboard/totalRegDonors.svg'}
                          stats={headerData?.donors?.increased || '0%'}
                          data={headerData?.donorCount || '0'}
                          title='Total Registered Donors'
                          bgColor='linear-gradient(98.28deg, #EDD9EE 0.4%, #F0F3F5 100%)'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={getEntityInfo() == 1 ? 3 : 4}>
                        <HeaderTabs
                          icon={'/assets/images/dashboard/regBloodBank.svg'}
                          stats={headerData?.bloodRequest?.increased || '0%'}
                          data={headerData?.requestCount || '0'}
                          title='Total Blood Request Received'
                          bgColor='linear-gradient(98.28deg, #D7E3F6 0.4%, #F0F3F5 100%)'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={getEntityInfo() == 1 ? 3 : 4}>
                        <HeaderTabs
                          icon={'/assets/images/dashboard/bloodCamps.svg'}
                          stats={headerData?.bloodCamp?.increased || '0%'}
                          data={headerData?.campCount || '0'}
                          bgColor='linear-gradient(98.28deg, #EED9D9 0.4%, #F0F3F5 100%)'
                          title='Blood Camp Organized'
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </section>

              <section className='dash-card-100 flex-col-cust' style={{ minHeight: '400px' }}>
                <AvailableBlood />
              </section>

              <Grid container spacing={2}>
                {getEntityInfo() == '5' ? null : (
                  <>
                    <Grid item xs={12} md={6}>
                      <Card sx={{ height: '100%', width: '100%', p: 1 }}>
                        <TtiChart />
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card sx={{ height: '100%', width: '100%', p: 1 }}>
                        <BloodComponentWise />
                      </Card>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: '100%', width: '100%', p: 1 }}>
                    <BlodgroupWiseChart />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: '100%', width: '100%', p: 1 }}>
                    <WasteChart />
                  </Card>
                </Grid>
                {getEntityInfo() == '5' ? null : (
                  <Grid item xs={12}>
                    <Card sx={{ height: '100%', width: '100%', p: 1, minHeight: 350 }}>
                      <DonorsChart />
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Card
                sx={{ height: '100%', width: '100%', p: 2, pb: 6, display: 'flex', flexDirection: 'column', gap: 2 }}
              >
                <Box sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: '600'
                    }}
                  >
                    Pending details
                  </Typography>
                </Box>
                {getEntityInfo() == '5' ? null : (
                  <Card className='blood-section py-2'>
                    <DonorStatsChart />
                  </Card>
                )}

                <Card className=' blood-section py-2'>
                  <BloodRequistionChart />
                </Card>
                <Card sx={{ maxHeight: '400px' }} className=' blood-section py-2'>
                  <Link to={'/activityrecord'}>
                    <h3 className='page-title-dash'>Activity Feed</h3>
                  </Link>

                  <div className='activity_das overflow_act'>
                    <Timeline
                      sx={{
                        width: '100%',
                        padding: '6px 0px',
                        '& .MuiTimelineItem-root:before': {
                          flex: 0,
                          padding: '0px !important'
                        }
                      }}
                      align='left'
                    >
                      {auditData?.length ? (
                        auditData?.map((item, i) => <ActivityItem item={item} key={i} />)
                      ) : (
                        <NoDataFound mh={null} />
                      )}
                    </Timeline>
                  </div>
                </Card>
                {getEntityInfo() == '5' ? null : (
                  <Card sx={{ maxHeight: '400px' }} className='blood-section py-2'>
                    <Link to={'/certification'}>
                      <h3 className='page-title-dash'>Pending to attach certificate</h3>
                    </Link>
                    {certificateFilter === 'all' ? (
                      certificateData.BloodBank != null || certificateData.BloodCamp.length > 0 ? (
                        <marquee
                          id='myMarquee'
                          behavior='scroll'
                          direction='up'
                          // className='dash-marquee-container'
                          height={'100%'}
                          scrollamount={3}
                          onMouseOver={stop}
                          onMouseLeave={start}
                        >
                          <section>
                            {certificateFilterData?.BloodBank && (
                              <CerificateMarqueItem
                                icon={BankIcon}
                                url='/certification'
                                type='Bank'
                                title={certificateData?.BloodBank?.name}
                              />
                            )}
                            {certificateFilterData?.BloodCamp?.map((item, i) => (
                              <CerificateMarqueItem
                                icon={CampIcon}
                                url={`/certification/camp/${item.id}`}
                                type='Camp'
                                title={item.camp_name}
                              />
                            ))}
                          </section>
                        </marquee>
                      ) : (
                        <NoDataFound mh={null} />
                      )
                    ) : certificateFilter === 'bank' ? (
                      certificateData.BloodBank != null ? (
                        <marquee
                          id='myMarquee'
                          behavior='scroll'
                          direction='up'
                          // className='dash-marquee-container'
                          height={'100%'}
                          scrollamount={3}
                          onMouseOver={stop}
                          onMouseLeave={start}
                        >
                          {certificateFilterData?.BloodBank && (
                            <CerificateMarqueItem
                              icon={BankIcon}
                              url='/certification'
                              type='Bank'
                              title={certificateData?.BloodBank?.name}
                            />
                          )}
                        </marquee>
                      ) : (
                        <NoDataFound mh={null} />
                      )
                    ) : certificateData.BloodCamp.length > 0 ? (
                      <marquee
                        id='myMarquee'
                        behavior='scroll'
                        direction='up'
                        // className='dash-marquee-container'
                        height={'500px'}
                        scrollamount={3}
                        onMouseOver={stop}
                        onMouseLeave={start}
                      >
                        {certificateFilterData?.BloodCamp?.map((item, i) => (
                          <CerificateMarqueItem
                            icon={BankIcon}
                            url='/certification'
                            type='Bank'
                            title={certificateData?.BloodBank?.name}
                          />
                        ))}
                      </marquee>
                    ) : (
                      <NoDataFound mh={null} />
                    )}
                  </Card>
                )}
              </Card>
            </Grid>
          </Grid>
        </ScrollBar>
      </div>
    </div>
  )
}

export default EHRDashboard
